import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useApolloClient } from '@apollo/client'
import { LoadingScreen } from '../../screens/LoadingScreen'

export const LogoutCallbackScreen = () => {
  const navigate = useNavigate()
  const client = useApolloClient()

  useEffect(() => {
    client.clearStore().then(() => {
      navigate('/login')
    })
  }, [])

  return <LoadingScreen />
}
