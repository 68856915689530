import React from 'react'
import styled from '@emotion/styled'

type TitleItemProps = {
  active: boolean
}

const TitleItem = styled.div<TitleItemProps>`
  padding: 8px 16px;
  text-transform: uppercase;
  font-family: 'Red Hat Text', sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  background-color: ${({ active, theme }) => (active ? theme.sideMenu.activeMenuBackgroundColor : 'transparent')};
  min-height: 40px;
  line-height: 40px;
`

interface Props {
  active: boolean
  title: string
}

const MenuTitleItem = ({ active, title }: Props) => {
  return <TitleItem active={active}>{title}</TitleItem>
}

export default MenuTitleItem
