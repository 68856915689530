import { Colors } from './emotion'

const colors: Colors = {
  barBackground: 'rgba(223, 225, 235, 1)',
  barMagenta: 'rgba(248, 58, 157, 1)',
  barYellow: 'rgba(245, 191, 51, 1)',
  barBlue: 'rgba(0, 140, 255, 1)',
  blueDark: '#0D70EE',
  blueEnergetic: '#008CFF',
  blueEnergeticLight: '#96ccfd',
  coldGrey: '#969BBE',
  warm: '#F0F0FA',
  background: '#F0F0FA',
  menuBackground: '#0D70EE',
  subMenuBackground: '#008CFF',
  titles: '#008CFF',
  measurement: '#969BBE',
  active: '#008CFF',
  inactive: '#969BBE',
  menuItem: '#FFFFFF',
  border: 'rgba(150, 155, 190, 0.2)',
  alert: '#FFA100FF',
  danger: '#F840A0',
  chartTitle: '#000000',
  chartBackground: '#FFFFFF',
  metricLabel: '#000000',
  timeFrameSelectionMenuBg: 'rgba(150, 155, 190, 0.2)',
  graphTextColor: 'rgba(145, 150, 187, 1)',
  borderBar: 'rgba(192, 195, 216, 1)'
}

const theme = {
  colors,
  loader: {
    loaderColor: colors.blueEnergetic
  },
  sideMenu: {
    activeMenuBackgroundColor: 'rgba(65, 0, 171, 0.1)'
  }
}

export default theme
