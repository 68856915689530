import styled from '@emotion/styled'

export const Name = styled('div')`
  color: #000;
  font-family: 'Red Hat Display', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
`
