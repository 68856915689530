//@ts-nocheck
import Container from '../../base/Container'
import { useQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { building } from '../../../store'
import { GetBuildingLayoutQuery } from '../../../queries/building'

const FloorContainer = styled('div')`
  padding: 16px;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

const FloorTitle = styled('div')`
  color: #fff;
  font-family: 'Red Hat Text', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 162%;
  text-transform: uppercase;
`

const Section = styled(Link)`
  padding: 8px 16px 0;
  color: #fff;
  font-family: 'Red Hat Text', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 162%; /* 25.92px */
`

const BuildingSideMenu = () => {
  const { activeBuildingId } = useRecoilValue(building)
  const { data, refetch } = useQuery(GetBuildingLayoutQuery, {
    variables: {
      buildingId: activeBuildingId!
    },
    skip: !Boolean(activeBuildingId)
  })

  useEffect(() => {
    void refetch({
      buildingId: activeBuildingId
    })
  }, [activeBuildingId])

  const floors = useMemo(() => {
    if (data?.getBuildingLayout) {
      return (
        data.getBuildingLayout
          //@ts-ignore
          .filter(section => section.type === 'floor')
          // @ts-ignore
          .sort((a, b) => a.order - b.order)
          // @ts-ignore
          .map(floor => ({
            ...floor,
            // @ts-ignore
            sections: data.getBuildingLayout
              // @ts-ignore
              .filter(section => section.parentId === floor.sectionId && section.type !== ' floor')
              // @ts-ignore
              .sort((a, b) => a.order - b.order)
          }))
      )
    } else {
      return []
    }
  }, [activeBuildingId, data?.getBuildingLayout])

  //@todo: remove ts-ignores and use MenuItem components from other menus
  return (
    <Container>
      {/*//@ts-ignore*/}
      {floors.map(floor => (
        <FloorContainer key={floor.sectionId}>
          <FloorTitle>{floor.name}</FloorTitle>
          {/*//@ts-ignore*/}
          {floor.sections.map(section => (
            <Section to={`/section/${section.sectionId}`} key={section.sectionId}>
              {section.name}
            </Section>
          ))}
        </FloorContainer>
      ))}
    </Container>
  )
}

export default BuildingSideMenu
