import { gql } from '../__generated'

export const GET_BUILDING_DETAILS = gql(/* GraphQL */ `
  query GetBuilding($buildingId: ID!) {
    getBuilding(buildingId: $buildingId) {
      buildingId
      name
      preferences {
        hasGrid
        hasLayout
        hasCategories
        hasSmartCharging
      }
      address
      address1
      city
      country
      postalCode
      state
      type
    }
  }
`)

export const GetBuildingLayoutQuery = gql(/* GraphQL */ `
  query GetBuildingLayout($buildingId: ID!) {
    getBuildingLayout(buildingId: $buildingId) {
      name
      order
      parentId
      sectionId
      type
    }
  }
`)
