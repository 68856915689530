import PowerOverviewGridComponent from '../../dashboard/dashboards/PowerOverviewComponents/PowerOverviewGridComponent'
import PowerOverviewBuildingComponent from '../../dashboard/dashboards/PowerOverviewComponents/PowerOverviewBuildingComponent'
import PowerOverviewSolarComponent from '../../dashboard/dashboards/PowerOverviewComponents/PowerOverviewSolarComponent'
import PowerOverviewStorageComponent from '../../dashboard/dashboards/PowerOverviewComponents/PowerOverviewStorageComponent'
import styled from '@emotion/styled'
import { useSuspenseQuery } from '@apollo/client'
import { GetGridForPowerOverviewQuery } from '../../dashboard/queries/gridForPowerOverview'
import { useRecoilValue } from 'recoil'
import { building } from '../../store'
import { CenterRow } from '../../components/base/CenterRow'

const GraphicContainer = styled('div')`
  width: 100%;
  margin: 32px auto 0;
  padding: 0 64px;
  max-width: 1080px;
`

export const GridSummaryGraphic = () => {
  const { activeBuildingId } = useRecoilValue(building)
  const { data: gridLayoutData } = useSuspenseQuery(GetGridForPowerOverviewQuery, {
    variables: {
      buildingId: activeBuildingId
    },
    skip: !Boolean(activeBuildingId)
  })

  return (
    <GraphicContainer>
      <CenterRow>
        <PowerOverviewGridComponent main={gridLayoutData?.getBuilding?.grid?.main} />
        <PowerOverviewBuildingComponent building={gridLayoutData?.getBuilding?.grid?.consumption} />
        <PowerOverviewSolarComponent solar={gridLayoutData?.getBuilding?.grid?.solar} />
        <PowerOverviewStorageComponent storage={gridLayoutData?.getBuilding?.grid?.storage} />
      </CenterRow>
    </GraphicContainer>
  )
}
