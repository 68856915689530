import { useEffect, useMemo, useState } from 'react'
import AppLayout from '../../layouts/AppLayout'
import { useLazyQuery } from '@apollo/client'
import GridMainDistributor from '../../components/grid/GridMainDistributor'
import GridDistributionBox from '../../components/grid/GridDistributionBox'
import { useNavigate } from 'react-router-dom'
import { arrayToTree } from 'performant-array-to-tree'
import { BUILDING_GRID_EXTENDED_QUERY } from '../../building/queries/grid'
import { useRecoilValue } from 'recoil'
import { building } from '../../store'
import Centered from '../../components/layout/Centered'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import styled from '@emotion/styled'

const StyledExclamationTriangleIcon = styled(ExclamationTriangleIcon)`
  width: 75px;
  height: 75px;
  color: ${({ theme }) => theme.colors.alert};
`

export default () => {
  const navigate = useNavigate()
  const { activeBuildingId } = useRecoilValue(building)
  const [totalPower, setTotalPower] = useState({})
  const [fetchBuildingGrid, { data }] = useLazyQuery(BUILDING_GRID_EXTENDED_QUERY, {
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (activeBuildingId) {
      void fetchBuildingGrid({
        variables: {
          buildingId: activeBuildingId
        }
      })
    }
  }, [activeBuildingId])

  const grid = useMemo(() => {
    return data?.getBuilding?.grid ?? {}
  }, [data?.getBuilding?.grid])

  const tree = useMemo(() => {
    if (data?.getBuilding?.grid?.distributionBoxes) {
      const flatGrid = data.getBuilding.grid.distributionBoxes
      return arrayToTree(flatGrid, {
        id: 'distributionBoxId',
        parentId: 'parent.distributionBoxId'
      })
    } else {
      return []
    }
  }, [grid])

  const updateTotalPower = (deviceId: string, power: number) => {
    setTotalPower({
      ...totalPower,
      [deviceId]: power
    })
  }

  const totalCapacity = useMemo(() => {
    //@ts-ignore
    return grid.main?.reduce((a: number, cv) => (a += cv.maxPower), 0) ?? 0
  }, [grid.main])
  //@ts-ignore
  const total = useMemo(() => {
    //@ts-ignore
    return Object.keys(totalPower)?.reduce((a: number, key: string) => (a += totalPower[key]), 0) ?? 0
  }, [totalPower])

  function renderDistributionBox(row: any) {
    const onClick = (boxId: string) => {
      navigate(`/grid/${boxId}`)
    }

    return (
      <div className="flex flex-row justify-evenly w-full gap-x-8">
        {row.map((box: any) => (
          <div className="flex flex-col items-center mt-16">
            <GridDistributionBox deviceId={box.data?.device?.deviceId} maxPower={100} name={box.data.name} key={box.data.device?.deviceId} onClick={() => onClick(box.data.distributionBoxId)} />
            {box.data.children?.length > 0 && renderDistributionBox(box.data.children)}
          </div>
        ))}
      </div>
    )
  }

  const showGrid = tree && tree.length > 0 && grid.main && grid.main.length > 0

  return (
    <AppLayout title="Hoofdverdeler">
      {/*<div className="flex flex-row justify-evenly w-full">*/}
      {/*    <PowerDonutChart power={total} name="Totaal" capacity={totalCapacity} />*/}
      {/*</div>*/}

      {!showGrid && (
        <Centered>
          <StyledExclamationTriangleIcon />
          De meterkastindeling ontbreekt.
        </Centered>
      )}

      {showGrid && (
        <div className="flex flex-row justify-evenly w-full mt-8">
          {
            //@ts-ignore
            grid.main.map(main => (
              <GridMainDistributor deviceId={main.deviceId} maxPower={main.maxPower} key={main.deviceId} updateTotalPower={updateTotalPower} />
            ))
          }
        </div>
      )}

      {showGrid && tree && tree.length > 0 && renderDistributionBox(tree)}
    </AppLayout>
  )
}
