import { CriticalErrorScreen } from './CriticalErrorScreen'

export const ErrorOrganisationScreen = () => {
  return (
    <CriticalErrorScreen
      message={`
      We kunnen uw organisatie niet bepalen. Controleer het web adres dat u heeft gebruikt en probeer het nogmaals.
      Neem contact op met de klantenservice als het probleem blijft bestaan.
    `}
    />
  )
}
