import React, { ReactNode, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'

const StyledCard = styled.div`
  min-width: 320px;
  width: fit-content;
  background-color: #ffffff;
`

const Content = styled.div`
  padding: 24px 16px 17px;
`

const CircuitName = styled.div`
  font-size: 16px;
  font-weight: 700;
  //text-transform: uppercase;
  color: #000000;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 250px;
  overflow: hidden;
`

const GroupDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`

const CircuitLabel = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => {
    //@ts-ignore
    return theme.colors.coldGrey
  }};
`

const Bar = styled.div`
  width: 100%;
`

interface Props {
  circuitName: string
  value: number
  max?: number
  circuitLabel: string
  showBar: boolean
  children: ReactNode
}
export default ({ circuitName, circuitLabel, value, max, showBar, children }: Props) => {
  const [measurementOfMax, setMeasurementOfMax] = useState(0)

  useEffect(() => {
    if (showBar && max) {
      setMeasurementOfMax(value / max)
    }
  }, [showBar, max, value])

  return (
    <StyledCard>
      <Content>
        <CircuitName>{circuitName}</CircuitName>
        {children}
        <GroupDetails>
          <CircuitLabel>{circuitLabel}</CircuitLabel>
        </GroupDetails>
      </Content>
      {showBar && (
        <Bar>
          <svg width="100%" height="8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={`${measurementOfMax * 100}%`} height="8" fill="#008CFF" />
            <rect opacity="0.33" width={`${(1 - measurementOfMax) * 100}%`} height="8" x={`${measurementOfMax * 100}%`} fill="#008CFF" />
          </svg>
        </Bar>
      )}
    </StyledCard>
  )
}
