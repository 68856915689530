import { Bar, BarColors } from '../../charts/Bar'
import styled from '@emotion/styled'

export enum EnergyTypes {
  Export,
  Import,
  Usage,
  Solar
}

interface EnergyTableResultProps {
  value: number
  max: number
  type: EnergyTypes
}

const Cell = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  height: 32px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.warm};

  &:first-of-type {
    border-top: 1px solid ${({ theme }) => theme.colors.warm};
  }
`

const Value = styled('div')`
  width: fit-content;
  font-family: 'Red Hat Display';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  text-align: right;
  color: ${({ theme }) => theme.colors.graphTextColor};
  white-space: nowrap;
`

const typeToColor = (type: EnergyTypes) => {
  switch (type) {
    case EnergyTypes.Export:
      return BarColors.Export
    case EnergyTypes.Import:
      return BarColors.Import
    case EnergyTypes.Usage:
      return BarColors.Usage
    case EnergyTypes.Solar:
      return BarColors.Solar
  }
}

export const EnergyTableResult = ({ max, value, type }: EnergyTableResultProps) => {
  return (
    <Cell>
      <Bar color={typeToColor(type)} value={value ?? 0} max={max} />
      <Value>{value} kWh</Value>
    </Cell>
  )
}
