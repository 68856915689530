/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query GetBuildingGrid($buildingId: ID!) {\n    getBuilding(buildingId: $buildingId) {\n      buildingId\n      grid {\n        distributionBoxes {\n          distributionBoxId\n          name\n          parent {\n            distributionBoxId\n          }\n        }\n      }\n    }\n  }\n": types.GetBuildingGridDocument,
    "\n  query GetExtendedBuildingGrid($buildingId: ID!) {\n    getBuilding(buildingId: $buildingId) {\n      buildingId\n      grid {\n        main {\n          current\n          maxPower\n          deviceId\n          phases\n        }\n        distributionBoxes {\n          description\n          distributionBoxId\n          name\n          circuits {\n            circuitId\n          }\n          parent {\n            distributionBoxId\n          }\n          device {\n            deviceId\n            name\n            data {\n              key\n              value\n            }\n            type\n            source\n          }\n        }\n      }\n    }\n  }\n": types.GetExtendedBuildingGridDocument,
    "\n  query GetGridForPowerOverview($buildingId: ID!) {\n    getBuilding(buildingId: $buildingId) {\n      buildingId\n      grid {\n        main {\n          deviceId\n        }\n        solar {\n          deviceId\n        }\n        storage {\n          deviceId\n        }\n        consumption {\n          deviceId\n        }\n      }\n    }\n  }\n": types.GetGridForPowerOverviewDocument,
    "\n  query GetAvailableCategories($buildingId: ID!) {\n    getAvailableCategories(buildingId: $buildingId)\n  }\n": types.GetAvailableCategoriesDocument,
    "\n  query GetDeviceType($input: GetDeviceInput) {\n    getDevice(input: $input) {\n      type\n      deviceId\n    }\n  }\n": types.GetDeviceTypeDocument,
    "\n  query GetDevicesWithCategory($buildingId: ID!, $category: String!) {\n    getDevicesWithCategory(buildingId: $buildingId, category: $category) {\n      deviceId\n      name\n      type\n    }\n  }\n": types.GetDevicesWithCategoryDocument,
    "\n  fragment GridDistributionCircuitFragment on GridDistributionCircuit {\n    circuitId\n    name\n    description\n    label\n    device {\n      deviceId\n      type\n      data {\n        key\n        value\n      }\n      name\n      status\n      source\n    }\n  }\n": types.GridDistributionCircuitFragmentFragmentDoc,
    "\n  query GetGridDistributionBox($distributionBoxId: ID!) {\n    getGridDistributionBox(distributionBoxId: $distributionBoxId) {\n      name\n      description\n      distributionBoxId\n      parent {\n        name\n      }\n      building {\n        name\n      }\n      device {\n        deviceId\n        type\n      }\n      maxPower\n      maxCurrent\n      maxPowerPerPhase\n      maxCurrentPerPhase\n      circuits {\n        ...GridDistributionCircuitFragment\n        parent {\n          ...GridDistributionCircuitFragment\n          parent {\n            ...GridDistributionCircuitFragment\n            parent {\n              ...GridDistributionCircuitFragment\n            }\n          }\n        }\n      }\n    }\n  }\n": types.GetGridDistributionBoxDocument,
    "\n  query FetchDeltaForPeriod($input: GetDeltaForPeriodInput) {\n    getDeltaForPeriod(input: $input)\n  }\n": types.FetchDeltaForPeriodDocument,
    "\n  query GetMinMaxForPeriod($input: GetMinMaxForPeriodInput) {\n    getMinMaxForPeriod(input: $input)\n  }\n": types.GetMinMaxForPeriodDocument,
    "\n  query GetWindowedMeasurements($input: WindowedMeasurementsInput) {\n    getWindowedMeasurements(input: $input)\n  }\n": types.GetWindowedMeasurementsDocument,
    "\n  query GetDeltaForWindow($input: GetDeltaForWindowInput) {\n    getDeltaForWindow(input: $input)\n  }\n": types.GetDeltaForWindowDocument,
    "\n  query GetBuildingsForUser {\n    getMyCustomerDetails {\n      name\n      customerId\n      buildings {\n        name\n        buildingId\n        preferences {\n          hasGrid\n          hasLayout\n          hasGrid\n          hasSmartCharging\n        }\n      }\n    }\n  }\n": types.GetBuildingsForUserDocument,
    "\n  query GetBuilding($buildingId: ID!) {\n    getBuilding(buildingId: $buildingId) {\n      buildingId\n      name\n      preferences {\n        hasGrid\n        hasLayout\n        hasCategories\n        hasSmartCharging\n      }\n      address\n      address1\n      city\n      country\n      postalCode\n      state\n      type\n    }\n  }\n": types.GetBuildingDocument,
    "\n  query GetBuildingLayout($buildingId: ID!) {\n    getBuildingLayout(buildingId: $buildingId) {\n      name\n      order\n      parentId\n      sectionId\n      type\n    }\n  }\n": types.GetBuildingLayoutDocument,
    "\n  query GetOrganisationForDomain($domain: String!) {\n    getOrganisationForDomain(domain: $domain) {\n      authOrganisationId\n    }\n  }\n": types.GetOrganisationForDomainDocument,
    "\n  query GetCurrentUser {\n    me {\n      customer {\n        customerId\n      }\n      userId\n    }\n  }\n": types.GetCurrentUserDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetBuildingGrid($buildingId: ID!) {\n    getBuilding(buildingId: $buildingId) {\n      buildingId\n      grid {\n        distributionBoxes {\n          distributionBoxId\n          name\n          parent {\n            distributionBoxId\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetBuildingGrid($buildingId: ID!) {\n    getBuilding(buildingId: $buildingId) {\n      buildingId\n      grid {\n        distributionBoxes {\n          distributionBoxId\n          name\n          parent {\n            distributionBoxId\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetExtendedBuildingGrid($buildingId: ID!) {\n    getBuilding(buildingId: $buildingId) {\n      buildingId\n      grid {\n        main {\n          current\n          maxPower\n          deviceId\n          phases\n        }\n        distributionBoxes {\n          description\n          distributionBoxId\n          name\n          circuits {\n            circuitId\n          }\n          parent {\n            distributionBoxId\n          }\n          device {\n            deviceId\n            name\n            data {\n              key\n              value\n            }\n            type\n            source\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetExtendedBuildingGrid($buildingId: ID!) {\n    getBuilding(buildingId: $buildingId) {\n      buildingId\n      grid {\n        main {\n          current\n          maxPower\n          deviceId\n          phases\n        }\n        distributionBoxes {\n          description\n          distributionBoxId\n          name\n          circuits {\n            circuitId\n          }\n          parent {\n            distributionBoxId\n          }\n          device {\n            deviceId\n            name\n            data {\n              key\n              value\n            }\n            type\n            source\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetGridForPowerOverview($buildingId: ID!) {\n    getBuilding(buildingId: $buildingId) {\n      buildingId\n      grid {\n        main {\n          deviceId\n        }\n        solar {\n          deviceId\n        }\n        storage {\n          deviceId\n        }\n        consumption {\n          deviceId\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetGridForPowerOverview($buildingId: ID!) {\n    getBuilding(buildingId: $buildingId) {\n      buildingId\n      grid {\n        main {\n          deviceId\n        }\n        solar {\n          deviceId\n        }\n        storage {\n          deviceId\n        }\n        consumption {\n          deviceId\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetAvailableCategories($buildingId: ID!) {\n    getAvailableCategories(buildingId: $buildingId)\n  }\n"): (typeof documents)["\n  query GetAvailableCategories($buildingId: ID!) {\n    getAvailableCategories(buildingId: $buildingId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetDeviceType($input: GetDeviceInput) {\n    getDevice(input: $input) {\n      type\n      deviceId\n    }\n  }\n"): (typeof documents)["\n  query GetDeviceType($input: GetDeviceInput) {\n    getDevice(input: $input) {\n      type\n      deviceId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetDevicesWithCategory($buildingId: ID!, $category: String!) {\n    getDevicesWithCategory(buildingId: $buildingId, category: $category) {\n      deviceId\n      name\n      type\n    }\n  }\n"): (typeof documents)["\n  query GetDevicesWithCategory($buildingId: ID!, $category: String!) {\n    getDevicesWithCategory(buildingId: $buildingId, category: $category) {\n      deviceId\n      name\n      type\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment GridDistributionCircuitFragment on GridDistributionCircuit {\n    circuitId\n    name\n    description\n    label\n    device {\n      deviceId\n      type\n      data {\n        key\n        value\n      }\n      name\n      status\n      source\n    }\n  }\n"): (typeof documents)["\n  fragment GridDistributionCircuitFragment on GridDistributionCircuit {\n    circuitId\n    name\n    description\n    label\n    device {\n      deviceId\n      type\n      data {\n        key\n        value\n      }\n      name\n      status\n      source\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetGridDistributionBox($distributionBoxId: ID!) {\n    getGridDistributionBox(distributionBoxId: $distributionBoxId) {\n      name\n      description\n      distributionBoxId\n      parent {\n        name\n      }\n      building {\n        name\n      }\n      device {\n        deviceId\n        type\n      }\n      maxPower\n      maxCurrent\n      maxPowerPerPhase\n      maxCurrentPerPhase\n      circuits {\n        ...GridDistributionCircuitFragment\n        parent {\n          ...GridDistributionCircuitFragment\n          parent {\n            ...GridDistributionCircuitFragment\n            parent {\n              ...GridDistributionCircuitFragment\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetGridDistributionBox($distributionBoxId: ID!) {\n    getGridDistributionBox(distributionBoxId: $distributionBoxId) {\n      name\n      description\n      distributionBoxId\n      parent {\n        name\n      }\n      building {\n        name\n      }\n      device {\n        deviceId\n        type\n      }\n      maxPower\n      maxCurrent\n      maxPowerPerPhase\n      maxCurrentPerPhase\n      circuits {\n        ...GridDistributionCircuitFragment\n        parent {\n          ...GridDistributionCircuitFragment\n          parent {\n            ...GridDistributionCircuitFragment\n            parent {\n              ...GridDistributionCircuitFragment\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query FetchDeltaForPeriod($input: GetDeltaForPeriodInput) {\n    getDeltaForPeriod(input: $input)\n  }\n"): (typeof documents)["\n  query FetchDeltaForPeriod($input: GetDeltaForPeriodInput) {\n    getDeltaForPeriod(input: $input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetMinMaxForPeriod($input: GetMinMaxForPeriodInput) {\n    getMinMaxForPeriod(input: $input)\n  }\n"): (typeof documents)["\n  query GetMinMaxForPeriod($input: GetMinMaxForPeriodInput) {\n    getMinMaxForPeriod(input: $input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetWindowedMeasurements($input: WindowedMeasurementsInput) {\n    getWindowedMeasurements(input: $input)\n  }\n"): (typeof documents)["\n  query GetWindowedMeasurements($input: WindowedMeasurementsInput) {\n    getWindowedMeasurements(input: $input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetDeltaForWindow($input: GetDeltaForWindowInput) {\n    getDeltaForWindow(input: $input)\n  }\n"): (typeof documents)["\n  query GetDeltaForWindow($input: GetDeltaForWindowInput) {\n    getDeltaForWindow(input: $input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetBuildingsForUser {\n    getMyCustomerDetails {\n      name\n      customerId\n      buildings {\n        name\n        buildingId\n        preferences {\n          hasGrid\n          hasLayout\n          hasGrid\n          hasSmartCharging\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetBuildingsForUser {\n    getMyCustomerDetails {\n      name\n      customerId\n      buildings {\n        name\n        buildingId\n        preferences {\n          hasGrid\n          hasLayout\n          hasGrid\n          hasSmartCharging\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetBuilding($buildingId: ID!) {\n    getBuilding(buildingId: $buildingId) {\n      buildingId\n      name\n      preferences {\n        hasGrid\n        hasLayout\n        hasCategories\n        hasSmartCharging\n      }\n      address\n      address1\n      city\n      country\n      postalCode\n      state\n      type\n    }\n  }\n"): (typeof documents)["\n  query GetBuilding($buildingId: ID!) {\n    getBuilding(buildingId: $buildingId) {\n      buildingId\n      name\n      preferences {\n        hasGrid\n        hasLayout\n        hasCategories\n        hasSmartCharging\n      }\n      address\n      address1\n      city\n      country\n      postalCode\n      state\n      type\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetBuildingLayout($buildingId: ID!) {\n    getBuildingLayout(buildingId: $buildingId) {\n      name\n      order\n      parentId\n      sectionId\n      type\n    }\n  }\n"): (typeof documents)["\n  query GetBuildingLayout($buildingId: ID!) {\n    getBuildingLayout(buildingId: $buildingId) {\n      name\n      order\n      parentId\n      sectionId\n      type\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetOrganisationForDomain($domain: String!) {\n    getOrganisationForDomain(domain: $domain) {\n      authOrganisationId\n    }\n  }\n"): (typeof documents)["\n  query GetOrganisationForDomain($domain: String!) {\n    getOrganisationForDomain(domain: $domain) {\n      authOrganisationId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCurrentUser {\n    me {\n      customer {\n        customerId\n      }\n      userId\n    }\n  }\n"): (typeof documents)["\n  query GetCurrentUser {\n    me {\n      customer {\n        customerId\n      }\n      userId\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;