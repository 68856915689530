import styled from '@emotion/styled'
import { DuoBar } from '../../charts/DuoBar'

const Cell = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  height: 32px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.warm};

  &:first-of-type {
    border-top: 1px solid ${({ theme }) => theme.colors.warm};
  }
`

const Value = styled('div')`
  min-width: 60px;
  width: fit-content;
  font-family: 'Red Hat Display';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  text-align: left;
  color: ${({ theme }) => theme.colors.graphTextColor};
  white-space: nowrap;

  &:last-of-type {
    text-align: right;
  }
`

interface EnergyTableDuoResultProps {
  exportMax: number
  exportValue: number
  importMax: number
  importValue: number
}

export const EnergyTableDuoResult = ({ exportValue, importValue, importMax, exportMax }: EnergyTableDuoResultProps) => {
  return (
    <Cell>
      <Value>{exportValue} kWh</Value>
      <DuoBar exportValue={exportValue} importValue={importValue} exportMax={exportMax} importMax={importMax} />
      <Value>{importValue} kWh</Value>
    </Cell>
  )
}
