import { EnergyTableTimestamp } from './EnergyTableTimestamp'
import { EnergyCategoryConsumptionColumn } from '../../../energy/categories/EnergyCategoryConsumptionColumn'
import { EnergyCategorySolarColumn } from '../../../energy/categories/EnergyCategorySolarColumn'
import { ErrorBoundary } from 'react-error-boundary'
import { EnergyCategoryColumn } from '../../../energy/categories/EnergyCategoryColumn'
import { useGetBuildingEnergyConsumptionData } from './useGetBuildingEnergyConsumptionData'
import styled from '@emotion/styled'

const TimestampContainer = styled('div')`
  background-color: rgba(223, 225, 235, 1);
  min-width: 150px;
  max-width: 150px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 126px 27px 33px;
`

const Wrapper = styled('div')`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  box-sizing: content-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
`

const ConsumptionContainer = styled('div')`
  height: 100%;
  padding: 0 16px 0 8px;
  width: 260px;
  box-shadow: 0 0 25px 0 rgba(191, 194, 214, 0.68);
`

const ResultsContainer = styled('div')`
  width: 100%;
  height: 100%;
  overflow-x: auto;
  display: grid;
  grid-auto-flow: column;
  align-self: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 0 16px 0 8px;
`

interface EnergyPerCategoryTableProps {
  categories: Array<string>
}

export const EnergyPerCategoryTable = ({ categories }: EnergyPerCategoryTableProps) => {
  const { consumption, timeStamps } = useGetBuildingEnergyConsumptionData()

  return (
    <>
      <TimestampContainer>{timeStamps && timeStamps.map(timestamp => <EnergyTableTimestamp timestamp={timestamp} key={`time-${timestamp}`} />)}</TimestampContainer>
      <Wrapper>
        <ConsumptionContainer>{consumption && <EnergyCategoryConsumptionColumn total={consumption.total} max={consumption.max} values={consumption.values} />}</ConsumptionContainer>
        <ResultsContainer>
          <EnergyCategorySolarColumn />
          {categories.map(category => (
            <ErrorBoundary fallback={<></>}>
              <EnergyCategoryColumn maxForBarChart={consumption.max} category={category} key={`category-column-${category}`} />
            </ErrorBoundary>
          ))}
        </ResultsContainer>
      </Wrapper>
    </>
  )
}
