import { useQuery } from '@apollo/client'
import { GET_WINDOWED_MEASUREMENTS_QUERY } from '../../../energy/queries/metrics'
import { getTimeframe, TimeframeVariant } from '../../../utils/getTimeframe'
import { useMemo } from 'react'
import Big from 'big.js'
import { format, isSameYear, parseISO } from 'date-fns'
import { EnergyPerCategoryOverview } from '../../../components/Energy/EnergyTable/EnergyPerCategoryOverview'
import { EnergyTableColumn } from '../../../components/Energy/EnergyTable/EnergyTableColumn'
import { EnergyTypes } from '../../../components/Energy/EnergyTable/EnergyTableResult'
import { timeframe, Timeframe, TimeframeWindow } from '../../../store'
import { useRecoilValue } from 'recoil'

interface PowerOverviewEnergyTableProps {
  gridDeviceIds?: string[]
  solarDeviceIds?: string[]
  consumptionDeviceIds?: string[]
}

const dateToTimestamp = (date: Date, timeFrame: Timeframe) => {
  const sameYear = isSameYear(date, new Date())

  switch (timeFrame.window) {
    case TimeframeWindow.H24:
      return format(date, 'HH:mm')
    case TimeframeWindow.WEEK:
    case TimeframeWindow.MONTH:
      return format(date, 'EEEEEE dd')
    case TimeframeWindow.YEAR:
      return format(date, 'MMMM yy')
    case TimeframeWindow.CUSTOM:
      return sameYear ? format(date, 'dd-MM-yy HH:mm') : format(date, 'dd MM HH:mm')
  }
}

export const energyTableGetTotal = (measurements: any) => {
  // @ts-ignore
  return measurements.reduce((sum, currentValue) => {
    const value = new Big(currentValue._value ?? 0)
    return sum.plus(value)
  }, new Big(0))
}

type Values = {
  [time: string]: Big
}

export const energyTableGetValues = (measurements: any, selectedTimeframe: Timeframe) => {
  let values: Values = {}

  //@ts-ignore
  measurements.forEach(measurement => {
    const date = parseISO(measurement._time)
    const timestamp = dateToTimestamp(date, selectedTimeframe)
    if (timestamp && values[timestamp]) {
      values[timestamp] = values[timestamp].plus(new Big(measurement._value ?? 0))
    } else if (timestamp) {
      values[timestamp] = new Big(measurement._value ?? 0)
    }
  })

  return (
    Object.entries(values).map(([key, value]) => {
      return {
        timestamp: key,
        value: value.round(0, Big.roundHalfUp).toNumber()
      }
    }) ?? []
  )
}

export const PowerOverviewEnergyTable = ({ gridDeviceIds, solarDeviceIds, consumptionDeviceIds }: PowerOverviewEnergyTableProps) => {
  return <> </>
}
