import { Timeframe } from '../store'
import Big from 'big.js'
import { parseISO } from 'date-fns'
import { dateToTimestamp } from './dateToTimestamp'

export interface WindowedMeasurementValue {
  deviceid: string
  measure: string
  result: string
  table: number
  _field: string
  _measurement: string
  _start: string
  _stop: string
  _time: string
  _value: number
}

export type Measurement = {
  timestamp: string
  value: number
}

export type TimeValue = {
  [time: string]: Big
}

export const influxDataToMeasurements = (influxData: Array<WindowedMeasurementValue>, selectedTimeframe: Timeframe): Array<Measurement> => {
  let values: TimeValue = {}

  //@ts-ignore
  influxData.forEach(measurement => {
    const date = parseISO(measurement._time)
    const timestamp = dateToTimestamp(date, selectedTimeframe)
    if (timestamp && values[timestamp]) {
      values[timestamp] = values[timestamp].plus(new Big(measurement._value ?? 0))
    } else if (timestamp) {
      values[timestamp] = new Big(measurement._value ?? 0)
    }
  })

  return (
    Object.entries(values).map(([key, value]) => {
      return {
        timestamp: key,
        value: value.round(0, Big.roundHalfUp).toNumber()
      }
    }) ?? []
  )
}

export const getMeasurementsTotal = (measurements: Array<Measurement>) => {
  return measurements.reduce((sum, currentValue) => {
    const valueAsBig = new Big(currentValue.value)
    return sum.plus(valueAsBig)
  }, new Big(0))
}

export const getTimestampsFromMeasurements = (measurements: Array<Measurement>) => {
  return measurements.map(measurement => measurement.timestamp)
}
