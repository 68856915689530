import { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import useAppContext from '../hooks/useAppContext'
import { LoadingScreen } from '../screens/LoadingScreen'
import { useRecoilValue } from 'recoil'
import { authClient } from '../store'

interface RequireAuthProps {
  children: JSX.Element
}

const RequireAuth = ({ children }: RequireAuthProps) => {
  let location = useLocation()
  const auth = useRecoilValue(authClient)
  const [authorized, setAuthorized] = useState<boolean>()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    auth.userManager.querySessionStatus().then(status => {
      console.log({ status })
    })

    auth.userManager
      .getUser()
      .then(user => {
        if (user.expired) {
          setAuthorized(false)
        } else if (!user.expired && user.access_token) {
          setAuthorized(true)
        } else {
          setAuthorized(false)
        }
        setLoading(false)
      })
      .catch(() => {
        setAuthorized(false)
        setLoading(false)
      })
  }, [auth])

  if (loading) return <LoadingScreen />

  if (!authorized) {
    return <Navigate to={'/login'} replace state={{ from: location }} />
  }

  return children
}

export default RequireAuth
