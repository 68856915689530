import React from 'react'

// @ts-ignore
export default props => {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2384_5256)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.9999 7.77371L6.56915 2.71777L5.2117 3.8859L8.325 6.77527H-0.00012207V8.65496H8.325L5.2117 11.8668L6.56915 13.2778L11.9999 7.77371Z"
          fill="#9196BB"
        />
      </g>
      <defs>
        <clipPath id="clip0_2384_5256">
          <rect width="12" height="12" fill="white" transform="translate(-0.00012207 2)" />
        </clipPath>
      </defs>
    </svg>
  )
}
