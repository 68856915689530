import React, { ReactElement, useMemo } from 'react'
import GridCircuitMinMax from '../../components/grid/GridCircuitMinMax'
import EnergyLineChart from '../../components/Energy/EnergyLineChart'
import EnergyContainer from '../containers/EnergyContainer'
import { isThreePhaseDevice } from '../../utils/isThreePhaseDevice'
import GridMaxDonutChart, { DISPLAY_VALUE, SIZE } from '../../components/grid/GridMaxDonutChart'

interface Props {
  distributionBox?: any
}

export default ({ distributionBox }: Props) => {
  const mainMeterId = distributionBox?.device?.deviceId
  const isThreePhase = isThreePhaseDevice(distributionBox?.device?.type)

  //@ts-ignore
  const devicesLineChart = useMemo(() => {
    //@ts-ignore
    return (
      distributionBox?.circuits?.map(circuit => ({
        deviceId: circuit?.device?.deviceId,
        name: circuit?.label
      })) ?? []
    )
  }, [distributionBox?.circuits])

  const totals = useMemo(() => {
    const totalCards: ReactElement[] = []
    if (mainMeterId) {
      totalCards.push(<GridMaxDonutChart deviceId={mainMeterId} measurement={'activePower'} label="Piek" unit="kW" capacity={distributionBox.maxPower} size={SIZE.large} divideKW={true} />)
      totalCards.push(
        <GridMaxDonutChart
          deviceId={mainMeterId}
          measurement={'activePower'}
          label="Minimum"
          unit="kW"
          capacity={distributionBox.maxPower}
          size={SIZE.large}
          divideKW={true}
          displayValue={DISPLAY_VALUE.MIN}
        />
      )

      if (isThreePhase) {
        totalCards.push(
          <GridMaxDonutChart
            deviceId={mainMeterId}
            measurement={'activePowerL1'}
            label="Piek L1"
            unit="kW"
            capacity={distributionBox.maxPowerPerPhase}
            size={SIZE.small}
            divideKW={true}
            displayValue={DISPLAY_VALUE.MIN}
          />
        )
        totalCards.push(
          <GridMaxDonutChart
            deviceId={mainMeterId}
            measurement={'activePowerL2'}
            label="Piek L2"
            unit="kW"
            capacity={distributionBox.maxPowerPerPhase}
            size={SIZE.small}
            divideKW={true}
            displayValue={DISPLAY_VALUE.MIN}
          />
        )
        totalCards.push(
          <GridMaxDonutChart
            deviceId={mainMeterId}
            measurement={'activePowerL3'}
            label="Piek L3"
            unit="kW"
            capacity={distributionBox.maxPowerPerPhase}
            size={SIZE.small}
            divideKW={true}
            displayValue={DISPLAY_VALUE.MIN}
          />
        )
      }
    }
    return totalCards
  }, [mainMeterId])

  const charts = useMemo(() => {
    return [<EnergyLineChart title={'Actueel vermogen'} devices={devicesLineChart} measurement={'activePower'} unit={'kW'} />]
  }, [devicesLineChart])

  const deviceCards = useMemo(() => {
    //@ts-ignore
    return distributionBox.circuits?.map(circuit => <GridCircuitMinMax circuit={circuit} measurement={'activePower'} unit={'kW'} />)
  }, [distributionBox?.circuits])

  return <EnergyContainer circuitsTitle={'Groepen'} charts={charts} deviceCards={deviceCards} totals={totals} />
}
