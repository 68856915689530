//@ts-nocheck
import React, { ReactNode } from 'react'
import styled from '@emotion/styled'

interface CardProps {
  children?: ReactNode
  className?: string
}

const StyledCard = styled.div`
  box-shadow: 0 0 32px rgba(0, 0, 100, 0.08);
  padding: 24px;
`

export const MeasurementTitle = styled.h3`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.inactive};
  font-weight: 700;
  line-height: 110%;
  text-transform: uppercase;
  opacity: 0.66;
  font-style: normal;
  white-space: nowrap;
`

export const MeasurementIntegral = styled.span<{ active?: boolean }>`
  font-weight: 900;
  font-size: 36px;
  line-height: 110%;
  color: ${({ active, theme }) => (active ? theme.colors.active : theme.colors.inactive)};
`

export const MeasurementFraction = styled.span<{ active?: boolean }>`
  font-weight: 900;
  font-size: 24px;
  line-height: 110%;
  color: ${({ active, theme }) => (active ? theme.colors.active : theme.colors.inactive)};
`

export const MeasurementUnit = styled.span<{ active?: boolean }>`
  font-weight: 900;
  font-size: 24px;
  line-height: 110%;
  color: ${({ active, theme }) => (active ? theme.colors.active : theme.colors.inactive)};
  margin-left: 8px;
`

const Card = ({ children, className, ...props }: CardProps) => {
  return (
    <StyledCard className={`bg-white shadow xs:mx-auto w-fit h-fit ${className}`} {...props}>
      {children}
    </StyledCard>
  )
}

export default Card
