export const energyDeviceTypes = [
  'AlfenEveMeterModel',
  'AnordMardixTrueMeterSinglePhaseModel',
  'AnordMardixTrueMeterThreePhaseModel',
  'DutchSmartMeterModel',
  'EastronSdm120Model',
  'EastronSdm630MctMlModel',
  'EastronSdm630MctMlSinglePhaseModel',
  'ThreePhaseDutchSmartMeterModel',
  'VirtualElectricityMeterThreePhaseModel'
]
