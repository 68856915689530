//@ts-nocheck
import React from 'react'
import styled from '@emotion/styled'
import { useReactiveVar } from '@apollo/client'
import { activeTopBarMenu } from './TopBar'

const Container = styled.div<{ active: boolean }>`
  font-family: Red Hat Display;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 32px;
  padding-bottom: 8px;
  border-bottom: ${({ active, theme }) => (active ? `4px solid ${theme.colors.titles}` : '')};
  color: ${({ active, theme }) => (active ? `${theme.colors.titles}` : '#000000')};
  cursor: pointer;

  :first-child {
    margin-left: 0;
  }
`

interface Props {
  id: string
  title: string
  to: string
}
export default ({ id, title, to }: Props) => {
  const activeMenu = useReactiveVar(activeTopBarMenu)
  return (
    <Container active={activeMenu === id} onClick={() => activeTopBarMenu(id)}>
      {title}
    </Container>
  )
}
