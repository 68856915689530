import styled from '@emotion/styled'
import { ReactNode } from 'react'

const StyledCard = styled.div`
  min-width: 285px;
  width: fit-content;
  background-color: #ffffff;
`

const Content = styled.div`
  padding: 24px 16px 17px;
`

const Name = styled.div`
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #000000;
`

const Measurements = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const MeasurementContainer = styled.div`
  margin-top: 8px;
  margin-left: 32px;

  :first-of-type {
    margin-left: 0;
  }
`

export const MeasurementLabel = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: #9196bb;
`

export const Measurement = styled.div`
  margin: 8px 0;
  font-size: 48px;
  font-weight: 900;

  color: ${({ theme }) => {
    //@ts-ignore
    return theme.colors.coldGrey
  }};
`

export const MeasurementFraction = styled.span`
  font-size: 24px;
`

interface Props {
  children: ReactNode
  name: string
}

export default ({ children, name }: Props) => {
  return (
    <StyledCard>
      <Content>
        <Name>{name}</Name>
        <Measurements>{children}</Measurements>
      </Content>
    </StyledCard>
  )
}
