import { gql } from '../__generated'

export const GET_CURRENT_USER_QUERY = gql(/* GraphQL */ `
  query GetCurrentUser {
    me {
      customer {
        customerId
      }
      userId
    }
  }
`)
