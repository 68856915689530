import { atom } from 'recoil'
import { endOfDay, startOfDay } from 'date-fns'

export enum TimeframeWindow {
  H24 = '24',
  WEEK = 'week',
  MONTH = 'maand',
  YEAR = 'jaar',
  CUSTOM = 'custom'
}

export type Timeframe = {
  window: TimeframeWindow | string
  start: Date
  end?: Date
}

const defaultTimeframe = {
  window: TimeframeWindow.H24,
  start: startOfDay(new Date()),
  end: endOfDay(new Date())
}

export const timeframe = atom({
  key: 'timeframe',
  default: defaultTimeframe
})
