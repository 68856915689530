import React from 'react'
import { MeasurementFraction, MeasurementIntegral, MeasurementTitle, MeasurementUnit } from '../../base/Card'
import DeviceCard from '../../base/DeviceCard'
import { Device } from '../Device'
import Big from 'big.js'

interface Props {
  device: Device
}
export default ({ device }: Props) => {
  if (!device) return null

  // @ts-ignore
  const activePower = new Big(device.data?.find(d => d.key === 'activePower')?.value || 0).round(2)
  const isKW = activePower.gte(1000) || activePower.lte(-1000)
  const power = isKW ? activePower.div(1000).round(2) : activePower.round(2)
  // @ts-ignore
  const voltage = new Big(device.data?.find(d => d.key === 'voltageL1')?.value || 0).round(2)
  // @ts-ignore
  const current = new Big(device.data?.find(d => d.key === 'current')?.value || 0).round(2)

  //@ts-ignore
  const activePowerL1 = new Big(device.data?.find(d => d.key === 'activePowerL1')?.value || 0)
  const isKWL1 = activePowerL1.gte(1000) || activePowerL1.lte(-1000)
  const powerL1 = isKWL1 ? activePowerL1.div(1000).round(2) : activePowerL1.round(2)

  //@ts-ignore
  const activePowerL2 = new Big(device.data?.find(d => d.key === 'activePowerL2')?.value || 0)
  const isKWL2 = activePowerL2.gte(1000) || activePowerL2.lte(-1000)
  const powerL2 = isKWL2 ? activePowerL2.div(1000).round(2) : activePowerL2.round(2)

  //@ts-ignore
  const activePowerL3 = new Big(device.data?.find(d => d.key === 'activePowerL3')?.value || 0)
  const isKWL3 = activePowerL3.gte(1000) || activePowerL3.lte(-1000)
  const powerL3 = isKWL3 ? activePowerL3.div(1000).round(2) : activePowerL3.round(2)

  return (
    <DeviceCard title={device.name} key={device.deviceId}>
      <div className={'flex flex-row mt-2 mb-6 justify-between space-x-12'} data-id={device.deviceId}>
        <div className={'flex flex-col w-1/3'}>
          <MeasurementTitle>Actief vermogen</MeasurementTitle>
          <div className="mt-2">
            <MeasurementIntegral>{Math.floor(power.toNumber())}</MeasurementIntegral>
            <MeasurementFraction>,{power.toString().split('.')[1] || 0}</MeasurementFraction>
            <MeasurementUnit>{isKW ? 'kW' : 'W'}</MeasurementUnit>
          </div>
        </div>

        <div className={'flex flex-col w-1/3'}>
          <MeasurementTitle>Voltage</MeasurementTitle>
          <div className="mt-2">
            <MeasurementIntegral>{Math.floor(voltage.toNumber())}</MeasurementIntegral>
            <MeasurementFraction>,{voltage.toString().split('.')[1] || 0}</MeasurementFraction>
            <MeasurementUnit>V</MeasurementUnit>
          </div>
        </div>

        <div className={'flex flex-col w-1/3'}>
          <MeasurementTitle>Stroom</MeasurementTitle>
          <div className="mt-2">
            <MeasurementIntegral>{Math.floor(current.toNumber())}</MeasurementIntegral>
            <MeasurementFraction>,{current.toString().split('.')[1] || 0}</MeasurementFraction>
            <MeasurementUnit>A</MeasurementUnit>
          </div>
        </div>
      </div>

      <div className={'flex flex-row mb-6 pt-2 justify-between border-top space-x-12'}>
        <div className={'flex flex-col w-1/3'}>
          <MeasurementTitle>L1 vermogen</MeasurementTitle>
          <div className="mt-2">
            <MeasurementIntegral>{Math.floor(powerL1.toNumber())}</MeasurementIntegral>
            <MeasurementFraction>,{powerL1.toString().split('.')[1] || 0}</MeasurementFraction>
            <MeasurementUnit>{isKWL1 ? 'kW' : 'W'}</MeasurementUnit>
          </div>
        </div>

        <div className={'flex flex-col w-1/3'}>
          <MeasurementTitle>L2 vermogen</MeasurementTitle>
          <div className="mt-2">
            <MeasurementIntegral>{Math.floor(powerL2.toNumber())}</MeasurementIntegral>
            <MeasurementFraction>,{powerL2.toString().split('.')[1] || 0}</MeasurementFraction>
            <MeasurementUnit>{isKWL2 ? 'kW' : 'W'}</MeasurementUnit>
          </div>
        </div>

        <div className={'flex flex-col w-1/3'}>
          <MeasurementTitle>L3 vermogen</MeasurementTitle>
          <div className="mt-2">
            <MeasurementIntegral>{Math.floor(powerL3.toNumber())}</MeasurementIntegral>
            <MeasurementFraction>,{powerL3.toString().split('.')[1] || 0}</MeasurementFraction>
            <MeasurementUnit>{isKWL3 ? 'kW' : 'W'}</MeasurementUnit>
          </div>
        </div>
      </div>
    </DeviceCard>
  )
}
