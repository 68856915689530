import { Suspense } from 'react'
import AppLayout from '../../layouts/AppLayout'
import PowerOverviewDashboard from '../../dashboard/dashboards/PowerOverviewDashboard'
import Loader from '../../components/base/Loader'

const DashboardScreen = () => {
  return (
    <AppLayout title="Verbruik">
      <Suspense fallback={<Loader />}>
        <PowerOverviewDashboard />
      </Suspense>
    </AppLayout>
  )
}

export default DashboardScreen
