import { useEffect, useMemo } from 'react'
import { getTimeframe } from '../../utils/getTimeframe'
import { useLazyQuery } from '@apollo/client'
import { FETCH_DELTA_FOR_PERIOD_QUERY } from '../queries/metrics'
import TotalCard from '../../components/Energy/TotalCard'
import Big from 'big.js'
import { useRecoilValue } from 'recoil'
import { timeframe } from '../../store'

interface EnergyMeasurementTotalCardProps {
  measurement: string
  title: string
  unit: string
  deviceId: string
}

const EnergyMeasurementTotalCard = ({ deviceId, measurement, title, unit }: EnergyMeasurementTotalCardProps) => {
  const selectedTimeframe = useRecoilValue(timeframe)
  const [fetchData, { data, loading }] = useLazyQuery(FETCH_DELTA_FOR_PERIOD_QUERY)

  const { start, end } = useMemo(() => {
    return getTimeframe(selectedTimeframe)
  }, [selectedTimeframe])

  useEffect(() => {
    if (deviceId) {
      fetchData({
        variables: {
          input: {
            devices: [deviceId],
            end,
            measurement,
            start
          }
        }
      })
    }
  }, [deviceId, measurement, start, end])

  const value = useMemo(() => {
    return Big(data?.getDeltaForPeriod?.[0].delta ?? 0)
      .round(2)
      .toNumber()
  }, [data?.getDeltaForPeriod?.[0]?.delta])

  if (loading) return null

  return <TotalCard title={title} unit={unit} value={value} />
}

export default EnergyMeasurementTotalCard
