import React, { useMemo } from 'react'
import GridCircuitMinMax from '../../components/grid/GridCircuitMinMax'
import EnergyContainer from '../containers/EnergyContainer'
import MultiMeasurementsLineChart from '../../components/Energy/MultiMeasurementsLineChart'
import { isThreePhaseDevice } from '../../utils/isThreePhaseDevice'
import { singlePhaseCurrentMeasurements, threePhaseCurrentMeasurements } from '../constants/currentMeasurements'
import GridMaxDonutChart, { SIZE } from '../../components/grid/GridMaxDonutChart'

interface Props {
  distributionBox?: any
}

export default ({ distributionBox }: Props) => {
  const mainMeterId = distributionBox?.device?.deviceId
  const isThreePhase = isThreePhaseDevice(distributionBox?.device?.type)

  const currentMeasurements = isThreePhase ? threePhaseCurrentMeasurements : singlePhaseCurrentMeasurements

  const totals = useMemo(() => {
    if (mainMeterId) {
      return [
        <GridMaxDonutChart deviceId={mainMeterId} measurement={'currentL1'} label="L1" unit="A" capacity={distributionBox.maxCurrentPerPhase} size={SIZE.large} />,

        <GridMaxDonutChart deviceId={mainMeterId} measurement={'currentL2'} label="L2" unit="A" capacity={distributionBox.maxCurrentPerPhase} size={SIZE.large} />,

        <GridMaxDonutChart deviceId={mainMeterId} measurement={'currentL3'} label="L3" unit="A" capacity={distributionBox.maxCurrentPerPhase} size={SIZE.large} />,

        <GridMaxDonutChart
          deviceId={mainMeterId}
          measurement={'apparentPower'}
          label="Schijnbaar vermogen"
          unit="kVA"
          capacity={distributionBox.maxCurrentPerPhase * 0.75 * 1000}
          size={SIZE.large}
          divideKW={true}
        />
      ]
    } else {
      return []
    }
  }, [mainMeterId])

  const charts = useMemo(() => {
    if (mainMeterId) {
      return [<MultiMeasurementsLineChart title={'Stroomsterkte'} deviceId={mainMeterId} measurements={currentMeasurements} unit={'A'} stackingEnabled={true} />]
    } else {
      return []
    }
  }, [mainMeterId])

  const deviceCards = useMemo(() => {
    //@ts-ignore
    return distributionBox?.circuits?.map(circuit => <GridCircuitMinMax circuit={circuit} measurement={'apparentPower'} unit={'VA'} />)
  }, [])

  return <EnergyContainer circuitsTitle={'Groepen'} charts={charts} deviceCards={[deviceCards]} totals={totals} />
}
