import React from 'react'

//@ts-ignore
export default props => {
  const strokeOpacity = props.active ? '1.0' : '0.5'

  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.0004 24.8984C44.0004 27.6032 41.8068 29.7968 39.102 29.7968C36.3972 29.7968 34.2048 27.6032 34.2048 24.8984C34.2048 22.1936 36.3972 20 39.102 20C41.8068 20 44.0004 22.1936 44.0004 24.8984Z"
        stroke="white"
        strokeOpacity={strokeOpacity}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.7956 24.8984C29.7956 27.6032 27.6032 29.7968 24.8972 29.7968C22.1936 29.7968 20 27.6032 20 24.8984C20 22.1936 22.1936 20 24.8972 20C27.6032 20 29.7956 22.1936 29.7956 24.8984Z"
        stroke="white"
        strokeOpacity={strokeOpacity}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.0004 39.0156C44.0004 41.7204 41.8068 43.9128 39.102 43.9128C36.3972 43.9128 34.2048 41.7204 34.2048 39.0156C34.2048 36.3108 36.3972 34.1172 39.102 34.1172C41.8068 34.1172 44.0004 36.3108 44.0004 39.0156Z"
        stroke="white"
        strokeOpacity={strokeOpacity}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.7956 39.0156C29.7956 41.7204 27.6032 43.9128 24.8972 43.9128C22.1936 43.9128 20 41.7204 20 39.0156C20 36.3108 22.1936 34.1172 24.8972 34.1172C27.6032 34.1172 29.7956 36.3108 29.7956 39.0156Z"
        stroke="white"
        strokeOpacity={strokeOpacity}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
