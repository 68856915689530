import { Suspense } from 'react'
import AppLayout from '../../layouts/AppLayout'
import { EnergyPerCategoryOverview } from '../../components/Energy/EnergyTable/EnergyPerCategoryOverview'
import TimeframeSelectionMenu from '../../components/TimeframeSelectionMenu/TimeframeSelectionMenu'
import styled from '@emotion/styled'
import { GridSummaryGraphic } from '../components/GridSummaryGraphic'
import HorizontalLoader from '../../components/base/HorizontalLoader'
import Centered from '../../components/layout/Centered'

const Container = styled('div')`
  width: 100%;
  height: fit-content;
  min-height: calc(100% - 10px);
`

const TimeFrameContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const DetailContainer = styled('div')`
  width: 100%;
  height: fit-content;
  margin: 50px auto 0;
  display: flex;
  align-items: center;
  max-width: 75vw;
`

export default () => {
  return (
    <AppLayout title={'Categoriën'}>
      <Container>
        <TimeFrameContainer>
          <TimeframeSelectionMenu />
        </TimeFrameContainer>

        <Suspense
          fallback={
            <Centered>
              <HorizontalLoader />
            </Centered>
          }>
          <GridSummaryGraphic />

          <DetailContainer>
            <EnergyPerCategoryOverview />
          </DetailContainer>
        </Suspense>
      </Container>
    </AppLayout>
  )
}
