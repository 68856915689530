export const threePhasePowerFactorMeasurements = [
  {
    key: 'powerFactorL1',
    label: 'L1'
  },
  {
    key: 'powerFactorL2',
    label: 'L2'
  },
  {
    key: 'powerFactorL3',
    label: 'L3'
  }
]

export const singlePhasePowerFactorMeasurements = [
  {
    key: 'powerFactor',
    label: 'L'
  }
]
