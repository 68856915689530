import styled from '@emotion/styled'
import { useMemo } from 'react'
import { useTheme } from '@emotion/react'

export enum BarColors {
  Usage = 'BLUE',
  Import = 'MAGENTA',
  Export = 'YELLOW',
  Solar = 'YELLOW'
}

interface DuoBarProps {
  // color: BarColors
  exportValue: number
  importValue: number
  exportMax: number
  importMax: number
}

interface ValueBarProps {
  width: number
  color: string
}

const Container = styled('div')`
  background-color: ${({ theme }) => theme.colors.barBackground};
  width: 100%;
  height: 16px;
  display: flex;
  flex-direction: row;
`

const ExportBar = styled('div')<ValueBarProps>`
  height: 16px;
  width: ${({ width }) => `calc(${width}% /2)`};
  background-color: ${({ color }) => color};
  margin-left: ${({ width }) => `calc(50% - (${width}% /2))`};
`

const ImportBar = styled('div')<ValueBarProps>`
  height: 16px;
  width: ${({ width }) => `calc(${width}% /2)`};
  background-color: ${({ color }) => color};
`

export const DuoBar = ({ importMax, exportMax, exportValue, importValue, ...props }: DuoBarProps) => {
  const getBarColor = useGetBarColor()

  const importValueWidth = useMemo(() => {
    return Math.round((importValue / importMax) * 100)
  }, [importMax, importValue])

  const exportValueWidth = useMemo(() => {
    return Math.round((exportValue / exportMax) * 100)
  }, [exportMax, exportValue])

  return (
    <Container {...props}>
      <ExportBar width={exportValueWidth} color={getBarColor(BarColors.Export)} />
      <ImportBar width={importValueWidth} color={getBarColor(BarColors.Import)} />
    </Container>
  )
}

const useGetBarColor = () => {
  const theme = useTheme()

  return (color: BarColors) => {
    switch (color) {
      case BarColors.Usage:
        return theme.colors.barBlue
      case BarColors.Import:
        return theme.colors.barMagenta
      case BarColors.Export:
        return theme.colors.barYellow
      default:
        return theme.colors.barBlue
    }
  }
}
