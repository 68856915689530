import { useApolloClient, useQuery, useReactiveVar } from '@apollo/client'
import { getTimeframe } from '../../utils/getTimeframe'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import HighchartsReact from 'highcharts-react-official'
import { GET_WINDOWED_MEASUREMENTS_QUERY } from '../../energy/queries/metrics'
import * as Highcharts from 'highcharts'
import Centered from '../layout/Centered'
import { CircleLoader } from 'react-spinners'
import styled from '@emotion/styled'
import { parseISO } from 'date-fns'
import Big from 'big.js'
import * as Moment from 'moment/moment'
import mTZ from 'moment-timezone'
import { useRecoilValue } from 'recoil'
import { timeframe } from '../../store'

declare global {
  interface Window {
    moment: any
  }
}
window.moment = Moment
mTZ()

const StyledCard = styled.div`
  box-shadow: 0 0 32px rgba(0, 0, 100, 0.08);
  padding: 24px;
  width: 100%;
`

const Title = styled.div`
  font-family: Red Hat Display;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 16px;
`

type Measurement = {
  label: string
  key: string
}

type RawDataResult = {
  key: string
  data: any[]
}

interface MultiMeasurementsLineChartProps {
  title: string
  deviceId: string
  unit: string
  stackingEnabled?: boolean
  measurements: Measurement[]
}

const MultiMeasurementsLineChart = ({ deviceId, measurements, title, unit, stackingEnabled }: MultiMeasurementsLineChartProps) => {
  const apiClient = useApolloClient()
  const selectedTimeframe = useRecoilValue(timeframe)
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<RawDataResult[]>([])

  const { start, end, window } = useMemo(() => getTimeframe(selectedTimeframe), [selectedTimeframe])

  useEffect(() => {
    const fetch = async () => {
      const queries = measurements.map(measurement => {
        return apiClient
          .query({
            query: GET_WINDOWED_MEASUREMENTS_QUERY,
            variables: {
              input: {
                deviceId: [deviceId],
                start,
                end,
                measurement: measurement.key,
                window,
                fn: 'mean'
              }
            }
          })
          .then(res => {
            const data = res?.data?.getWindowedMeasurements ?? []
            return { key: measurement.key, data }
          })
      })

      const result = await Promise.all(queries)
      return result.flat()
    }

    if (deviceId) {
      fetch().then(result => {
        setData(result)
        setLoading(false)
      })
    }
  }, [deviceId, start, end, window])

  const chartData = useMemo(() => {
    if (data?.length > 0) {
      //@ts-ignore
      const series = []
      data.forEach(entry => {
        series.push({
          name: measurements.find(measurement => measurement.key === entry.key)?.label ?? '',
          data: entry.data.filter(measurement => Boolean(measurement._value)).map(row => [parseISO(row._time).getTime(), Big(row._value).round(3).toNumber()])
        })
      })
      //@ts-ignore
      return series
    } else {
      return []
    }
  }, [data])

  const chartOptions: Highcharts.Options = {
    title: undefined,
    chart: {
      type: 'line'
    },
    xAxis: {
      type: 'datetime'
    },
    yAxis: {
      title: {
        text: unit
      }
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false
        },
        stacking: stackingEnabled ? 'normal' : undefined
      },
      series: {
        step: 'center'
      }
    },
    credits: {
      enabled: false
    },
    time: {
      timezone: 'Europe/Amsterdam'
    },
    //@ts-ignore
    series: chartData
  }

  return (
    <StyledCard className="bg-white shadow xs:mx-auto w-full">
      <Title>{title}</Title>
      {loading && (
        <Centered>
          <CircleLoader color="#0D70EE" loading={loading} />
        </Centered>
      )}

      {!loading && chartData.length > 0 && <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartComponentRef} />}

      {!loading && chartData.length === 0 && <Centered>Er is geen data om weer te geven</Centered>}
    </StyledCard>
  )
}

export default MultiMeasurementsLineChart
