import { recoilPersist } from 'recoil-persist'
import { atom } from 'recoil'

const { persistAtom } = recoilPersist({
  key: 'loqio-building',
  storage: localStorage,
  converter: JSON
})

type Building = {
  activeBuildingId?: string
  activeBuildingName?: string
}

const defaultBuildingValues: Building = {
  activeBuildingId: undefined,
  activeBuildingName: undefined
}

export const building = atom<Building>({
  key: 'building',
  default: defaultBuildingValues,
  effects_UNSTABLE: [persistAtom]
})
