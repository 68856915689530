import { CriticalErrorScreen } from './CriticalErrorScreen'

export const AccountNotReadyErrorScreen = () => {
  return (
    <CriticalErrorScreen
      message={`
        Uw account is nog niet klaar voor gebruik. Zodra uw account
        klaar is voor gebruik ontvangt u van ons bericht. Neem contact op met de klantenservice als uw account
        al klaar voor gebruik zou zijn.
      `}
    />
  )
}
