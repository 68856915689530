import { useMemo } from 'react'
import EnergyLineChart from '../../components/Energy/EnergyLineChart'
import EnergyPowerCard from '../../components/Energy/EnergyPowerCard'
import EnergyContainer from '../containers/EnergyContainer'
import { EnergyDevice } from '../types/devices'

interface Props {
  devices: EnergyDevice[]
}
export default ({ devices }: Props) => {
  const charts = useMemo(() => [<EnergyLineChart title={'Actueel vermogen'} devices={devices} measurement={'activePower'} unit={'kW'} divideMeasurementBy={1000} />], [devices])

  const deviceCards = useMemo(() => devices.map(device => <EnergyPowerCard device={device} />), [devices])

  return <EnergyContainer charts={charts} deviceCards={deviceCards} circuitsTitle={'Apparaten en groepen'} />
}
