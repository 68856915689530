import { ReactNode, useCallback, useMemo, useState } from 'react'
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client'
import { CachePersistor, SessionStorageWrapper } from 'apollo3-cache-persist'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { useRecoilValue } from 'recoil'
import { authClient } from './store'
import { UnauthorizedErrorScreen } from './screens/errors/UnauthorizedErrorScreen'

interface ApolloProps {
  children: ReactNode
}

const cache = new InMemoryCache()

const cachePersistor = new CachePersistor({
  cache,
  storage: new SessionStorageWrapper(window.sessionStorage),
  trigger: 'write'
})

//@todo: URL from env variables. Setup CI/CD in Gitlab
const prodHttpLink = createHttpLink({
  // uri: 'https://api.loqio.app',
  uri: 'https://production--loqio-energy-management.apollographos.net/graphql',
  headers: {
    'Loqio-Subscription-Key': '42714a203296419e8dddc86d451b5577'
  }
})

const testHttpLink = createHttpLink({
  uri: 'https://testing--loqio-energy-management.apollographos.net/graphql',
  headers: {
    'Loqio-Subscription-Key': '35504a291af7488c89b6a40af4da1602'
  }
})

const Apollo = ({ children }: ApolloProps) => {
  const auth = useRecoilValue(authClient)
  const [authorized, setAuthorized] = useState(true)
  const httpLink = window.location.hostname === 'localhost' ? testHttpLink : prodHttpLink

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors && graphQLErrors.length > 0) {
      console.error(graphQLErrors)
      graphQLErrors.forEach(error => {
        if (error.extensions?.code === 'AuthenticationError') {
          // auth?.userManager?.removeUser()
          // window.location.reload()
        } else if (error.extensions?.code === 'AuthorizationError') {
          // setAuthorized(false)
        }
      })
    }
  })

  const authLink = setContext(async (operation, { headers }) => {
    const user = await auth.userManager.getUser()
    if (user && user.access_token) {
      return {
        headers: {
          ...headers,
          'Loqio-Subscription-Key': '42714a203296419e8dddc86d451b5577',
          // 'Loqio-Subscription-Key': '35504a291af7488c89b6a40af4da1602',
          authorization: `Bearer ${user.access_token}`
        }
      }
    } else {
      return headers
    }
  })

  const client = useMemo(() => {
    return new ApolloClient({
      name: 'Dashboard',
      link: authLink.concat(errorLink).concat(prodHttpLink),
      cache
    })
  }, [auth])

  const clearCache = useCallback(async () => {
    if (!cachePersistor) {
      return
    }
    await cachePersistor.purge()
  }, [cachePersistor])

  //@todo: Implement new to create loading screen, same as in auth
  if (!client) {
    return <h2>Initializing app...</h2>
  }

  client.onClearStore(async () => {
    await clearCache()
  })

  if (!authorized) return <UnauthorizedErrorScreen />

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default Apollo
