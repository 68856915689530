import styled from '@emotion/styled'
import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { building } from '../../../store'
import { useSuspenseQuery } from '@apollo/client'
import { GetDeviceCategories } from '../../../devices/queries/categories'
import { EnergyPerCategoryTable } from './EnergyPerCategoryTable'

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
`

const Row = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 100, 0.08);
  background-color: #ffffff;
`

const Head = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 72px;
  padding-left: 150px;
  width: 100%;
`

const HeadLeft = styled('div')`
  background: linear-gradient(100deg, rgba(248, 58, 157, 0.1) 1.04%, rgba(255, 255, 255, 0) 59.46%);
  width: 50%;
  height: 100%;
  border-radius: 16px 0 0 0;
`

const HeadRight = styled('div')`
  background: linear-gradient(88deg, rgba(255, 255, 255, 0) 61.18%, rgba(245, 191, 51, 0.2) 100%);
  width: 50%;
  height: 100%;
  border-radius: 0 16px 0 0;
`

export const EnergyPerCategoryOverview = () => {
  const { activeBuildingId } = useRecoilValue(building)

  const { data } = useSuspenseQuery(GetDeviceCategories, {
    variables: {
      buildingId: activeBuildingId
    },
    skip: !Boolean(activeBuildingId)
  })

  const categories: Array<string> = useMemo(() => {
    if (data?.getAvailableCategories && data.getAvailableCategories.length > 0) {
      return data?.getAvailableCategories?.toSorted() ?? []
    } else {
      return []
    }
  }, [data?.getAvailableCategories])

  return (
    <Container>
      <Head>
        <HeadLeft />
        <HeadRight />
      </Head>
      <Row>
        <EnergyPerCategoryTable categories={categories} />
      </Row>
    </Container>
  )
}
