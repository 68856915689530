import { SVGProps } from 'react'

interface GridIconProps extends SVGProps<SVGSVGElement> {
  active?: boolean
}

export default ({ active, ...props }: GridIconProps) => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2801_9965)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48 38.6238L38.9488 30.1973L36.6864 32.1441L41.8752 36.9598H28V40.0926H41.8752L36.6864 45.4457L38.9488 47.7973L48 38.6238Z"
          fill="white"
          fillOpacity={active ? 1 : 0.5}
        />
        <g clipPath="url(#clip1_2801_9965)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 25.3703L25.0512 33.7969L27.3136 31.85L22.1248 27.0344H36V23.9016H22.1248L27.3136 18.5484L25.0512 16.1969L16 25.3703Z"
            fill="white"
            fillOpacity={active ? 1 : 0.5}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2801_9965">
          <rect width="32" height="32" fill="white" transform="translate(16 16)" />
        </clipPath>
        <clipPath id="clip1_2801_9965">
          <rect width="20" height="20" fill="white" transform="translate(16 15)" />
        </clipPath>
      </defs>
    </svg>
  )
}
