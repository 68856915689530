import React from 'react'

// @ts-ignore
export default props => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clip-path="url(#clip0_2407_5377)">
        <path d="M32 5.57736L20.3599 0.5H11.6401L0 5.57736V9.94447H10.4559L4.45263 30.3987H0.0650009V32.5001H31.9351V30.3987H27.5474L21.5442 9.94447H32.0001V5.57736H32ZM12.91 2.60132H19.0901V7.84314H12.91V2.60132ZM12.6459 9.94447H19.3542L20.372 13.4122L16 18.1744L11.6281 13.4122L12.6459 9.94447ZM2.10132 7.84314V6.95337L10.8086 3.15523V7.84314H2.10132ZM10.937 15.7667L14.5737 19.7279L7.51902 27.4124L10.937 15.7667ZM7.63011 30.3987L16 21.2816L24.37 30.3987H7.63011ZM24.481 27.4124L17.4263 19.728L21.063 15.7667L24.481 27.4124ZM29.8987 7.84314H21.1914V3.15523L29.8987 6.95337V7.84314H29.8987Z" />
      </g>
      <defs>
        <clipPath id="clip0_2407_5377">
          <rect width="32" height="32" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
