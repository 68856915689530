import { useLocation } from 'react-router-dom'
import { SideMenus } from '../components/SideMenu/SideMenu'
import { useMemo } from 'react'

export const pathToMenu = (path: string) => {
  switch (true) {
    case path.startsWith('/grid'):
      return SideMenus.GRID
    case path.startsWith('/section'):
    case path.startsWith('/building'):
      return SideMenus.BUILDING
    case path.startsWith('/ev-charging'):
      return SideMenus.CHARGING
    case path.startsWith('/categories'):
      return SideMenus.CATEGORIES
    default:
      return SideMenus.DASHBOARDS
  }
}

export const useRouteToActiveMenu = () => {
  const location = useLocation()

  return useMemo(() => {
    return pathToMenu(location.pathname)
  }, [location.pathname])
}
