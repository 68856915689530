import { useQuery, useReactiveVar } from '@apollo/client'
import { GetMinMaxForPeriodQuery } from '../../energy/queries/metrics'
import { EnergyDevice } from '../../energy/types/devices'
import { CircleLoader } from 'react-spinners'
import EnergyDeviceCard, { Measurement, MeasurementContainer, MeasurementFraction, MeasurementLabel } from '../base/EnergyDeviceCard'
import { useMemo } from 'react'
import Big from 'big.js'
import { getTimeframe } from '../../utils/getTimeframe'
import styled from '@emotion/styled'
import { useRecoilValue } from 'recoil'
import { timeframe } from '../../store'

const PhaseRow = styled.div`
  display: flex;
  flex-direction: row;
`

const Phases = styled.div`
  display: flex;
  flex-direction: column;
`

const PhaseLabel = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #9196bb;
  display: flex;
  justify-content: center;
  align-items: center;
`

interface Props {
  device: EnergyDevice
}

const EnergyDeviceSinglePhaseCurrentCard = ({ device }: Props) => {
  const selectedTimeframe = useRecoilValue(timeframe)
  const { start, end, window } = getTimeframe(selectedTimeframe)
  const { data, loading } = useQuery(GetMinMaxForPeriodQuery, {
    variables: {
      input: {
        deviceId: device.deviceId,
        start,
        end,
        measurement: 'currentL1'
      }
    },
    skip: !device?.deviceId
  })

  const { minIntegral, maxIntegral, minFraction, maxFraction } = useMemo(() => {
    const min = Big(data?.getMinMaxForPeriod?.min?.value ?? 0)
    const max = Big(data?.getMinMaxForPeriod?.max?.value ?? 0)

    const minIntegral = min.round(0, Big.roundDown)
    const minFraction = min.minus(minIntegral).round(2).toString().split('.')[1]

    const maxIntegral = max.round(0, Big.roundDown)
    const maxFraction = max.minus(maxIntegral).round(2).toString().split('.')[1]

    return {
      minIntegral: minIntegral.toString(),
      minFraction,
      maxIntegral: maxIntegral.toString(),
      maxFraction
    }
  }, [data?.getMinMaxForPeriod])

  if (!device?.deviceId) return null
  if (!data?.getMinMaxForPeriod && !loading) return null

  return (
    <EnergyDeviceCard name={device.name}>
      {loading && <CircleLoader color="#0D70EE" loading={loading} />}

      <Phases>
        {!loading && (
          <PhaseRow>
            <PhaseLabel>L1</PhaseLabel>
            <MeasurementContainer>
              <MeasurementLabel>Min.</MeasurementLabel>
              <Measurement>
                {minIntegral}
                <MeasurementFraction> {minFraction && `,${minFraction}`} A</MeasurementFraction>
              </Measurement>
            </MeasurementContainer>

            <MeasurementContainer>
              <MeasurementLabel>Max.</MeasurementLabel>
              <Measurement>
                {maxIntegral}
                <MeasurementFraction> {maxFraction && `,${maxFraction}`} A</MeasurementFraction>
              </Measurement>
            </MeasurementContainer>
          </PhaseRow>
        )}

        {!loading && (
          <PhaseRow>
            <PhaseLabel>L2</PhaseLabel>
            <MeasurementContainer>
              <MeasurementLabel>Min.</MeasurementLabel>
              <Measurement>
                {minIntegral}
                <MeasurementFraction> {minFraction && `,${minFraction}`} A</MeasurementFraction>
              </Measurement>
            </MeasurementContainer>

            <MeasurementContainer>
              <MeasurementLabel>Max.</MeasurementLabel>
              <Measurement>
                {maxIntegral}
                <MeasurementFraction> {maxFraction && `,${maxFraction}`} A</MeasurementFraction>
              </Measurement>
            </MeasurementContainer>
          </PhaseRow>
        )}

        {!loading && (
          <PhaseRow>
            <PhaseLabel>L3</PhaseLabel>
            <MeasurementContainer>
              <MeasurementLabel>Min.</MeasurementLabel>
              <Measurement>
                {minIntegral}
                <MeasurementFraction> {minFraction && `,${minFraction}`} A</MeasurementFraction>
              </Measurement>
            </MeasurementContainer>

            <MeasurementContainer>
              <MeasurementLabel>Max.</MeasurementLabel>
              <Measurement>
                {maxIntegral}
                <MeasurementFraction> {maxFraction && `,${maxFraction}`} A</MeasurementFraction>
              </Measurement>
            </MeasurementContainer>
          </PhaseRow>
        )}
      </Phases>
    </EnergyDeviceCard>
  )
}

export default EnergyDeviceSinglePhaseCurrentCard
