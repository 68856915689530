const threePhaseDevices = [
  'AlfenEveMeterModel',
  'AnordMardixTrueMeterThreePhaseModel',
  'DutchSmartMeterModel',
  'EastronSdm630MctMlModel',
  'ThreePhaseDutchSmartMeterModel',
  'VirtualElectricityMeterThreePhaseModel'
]

export const isThreePhaseDevice = (type: string) => {
  return threePhaseDevices.includes(type)
}
