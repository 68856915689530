import { useQuery, useSuspenseQuery } from '@apollo/client'
import { DEVICES_IN_CATEGORY_QUERY } from '../queries/category'
import { useMemo } from 'react'
import { energyDeviceTypes } from '../constants/energyDeviceTypes'
import { GET_WINDOWED_MEASUREMENTS_QUERY } from '../queries/metrics'
import { getTimeframe, TimeframeVariant } from '../../utils/getTimeframe'
import Big from 'big.js'
import { useRecoilValue } from 'recoil'
import { building, timeframe } from '../../store'
import { getMeasurementsTotal, influxDataToMeasurements } from '../../utils/influxDataToMeasurements'
import { EnergyTableColumn } from '../../components/Energy/EnergyTable/EnergyTableColumn'
import { EnergyTypes } from '../../components/Energy/EnergyTable/EnergyTableResult'

interface EnergyCategoryColumnProps {
  category: string
  maxForBarChart: number
}

export const EnergyCategoryColumn = ({ category, maxForBarChart }: EnergyCategoryColumnProps) => {
  const { activeBuildingId } = useRecoilValue(building)
  const selectedTimeframe = useRecoilValue(timeframe)
  const { start, end, window } = getTimeframe({ ...selectedTimeframe, variant: TimeframeVariant.CONDENSED })

  const { data } = useSuspenseQuery(DEVICES_IN_CATEGORY_QUERY, {
    variables: {
      buildingId: activeBuildingId!,
      category: category
    },
    skip: !activeBuildingId || !category,
    fetchPolicy: 'no-cache'
  })

  const devices = useMemo(() => {
    if (data?.getDevicesWithCategory && data?.getDevicesWithCategory.length > 0) {
      return data.getDevicesWithCategory.filter(device => energyDeviceTypes.includes(device.type)).map(device => device.deviceId)
    } else {
      return []
    }
  }, [data?.getDevicesWithCategory])

  const { data: energyImported } = useSuspenseQuery(GET_WINDOWED_MEASUREMENTS_QUERY, {
    variables: {
      input: {
        deviceId: devices,
        start,
        end,
        measurement: 'energyImported',
        window,
        fn: 'spread'
      }
    },
    fetchPolicy: 'no-cache',
    skip: !Boolean(devices && devices.length > 0)
  })

  const imported = useMemo(() => {
    if (!energyImported?.getWindowedMeasurements || energyImported.getWindowedMeasurements.length < 1)
      return {
        total: 0,
        type: EnergyTypes.Usage,
        values: []
      }

    const values = influxDataToMeasurements(energyImported.getWindowedMeasurements, selectedTimeframe)
    const total = getMeasurementsTotal(values)

    return {
      total: total.round(0, Big.roundHalfUp).toNumber(),
      type: EnergyTypes.Usage,
      values
    }
  }, [energyImported?.getWindowedMeasurements, selectedTimeframe])

  return <EnergyTableColumn title={category} imported={imported} maxForBarChart={maxForBarChart} link={`/categories/${category}`} />
}
