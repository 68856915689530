import { gql } from '../../__generated'

export const FETCH_DELTA_FOR_PERIOD_QUERY = gql(/* GraphQL */ `
  query FetchDeltaForPeriod($input: GetDeltaForPeriodInput) {
    getDeltaForPeriod(input: $input)
  }
`)

export const GetMinMaxForPeriodQuery = gql(/* GraphQL */ `
  query GetMinMaxForPeriod($input: GetMinMaxForPeriodInput) {
    getMinMaxForPeriod(input: $input)
  }
`)

export const GET_WINDOWED_MEASUREMENTS_QUERY = gql(/* GraphQL */ `
  query GetWindowedMeasurements($input: WindowedMeasurementsInput) {
    getWindowedMeasurements(input: $input)
  }
`)

export const GET_DELTA_FOR_WINDOW_QUERY = gql(/* GraphQL */ `
  query GetDeltaForWindow($input: GetDeltaForWindowInput) {
    getDeltaForWindow(input: $input)
  }
`)
