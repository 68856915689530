import styled from '@emotion/styled'

export const Circle = styled('div')`
  width: 160px;
  height: 160px;
  border-radius: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 100, 0.08);
`

export const CircleInner = styled('div')`
  box-sizing: border-box;
  width: 160px;
  height: 160px;
  border-radius: 80px;
  border: 10px solid #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
`
