import { EnergyTypes } from './EnergyTableResult'
import { EnergyTableSingleCategoryColumn } from './EnergyTableSingleCategoryColumn'
import { EnergyTableDuoCategoryColumn } from './EnergyTableDuoCategoryColumn'

export type EnergyTableColumnData = {
  timestamp: string
  value: number
}

export type EnergyTableColumnCategory = {
  type: EnergyTypes
  total: number
  values: EnergyTableColumnData[]
}

export interface EnergyTableColumn {
  title: string
  link?: string
  maxForBarChart: number
  imported?: EnergyTableColumnCategory
  exported?: EnergyTableColumnCategory
}

export const EnergyTableColumn = ({ title, link, imported, exported, maxForBarChart }: EnergyTableColumn) => {
  if (imported && !exported) {
    return <EnergyTableSingleCategoryColumn title={title} total={imported.total} values={imported.values} type={imported.type} link={link} resultMax={maxForBarChart} />
  }

  if (exported && !imported) {
    return <EnergyTableSingleCategoryColumn title={title} total={exported.total} values={exported.values} type={exported.type} link={link} resultMax={maxForBarChart} />
  }

  if (exported && imported) {
    return <EnergyTableDuoCategoryColumn title={title} imported={imported} exported={exported} link={link} />
  }

  return <></>
}
