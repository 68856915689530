import { useMemo } from 'react'
import EnergyBarChart from '../../components/Energy/EnergyBarChart'
import EnergyUsageDeviceCard from '../../components/Energy/EnergyUsageDeviceCard'
import EnergyContainer from '../containers/EnergyContainer'
import { EnergyDevice } from '../types/devices'

interface Props {
  devices: EnergyDevice[]
}
export default ({ devices }: Props) => {
  //@todo: add total cards

  const charts = useMemo(
    () => [
      <EnergyBarChart title="Actieve energie - import" devices={devices} measurement="energyImported" divideMeasurementBy={1} />,
      <EnergyBarChart title="Actieve energie - export" devices={devices} measurement="energyExported" isExport={true} divideMeasurementBy={1} />
    ],
    [devices]
  )

  const deviceCards = useMemo(() => devices.map(device => <EnergyUsageDeviceCard device={device} />), [devices])

  return <EnergyContainer charts={charts} deviceCards={deviceCards} circuitsTitle={'Apparaten en groepen'} />
}
