import Container from '../../base/Container'
import MenuTitleItem from '../MenuTitleItem'
import MenuItem from '../MenuItem'

const DashboardsSideMenu = () => {
  return (
    <Container>
      <MenuTitleItem title={'Dashboards'} active={false} />
      <MenuItem title="Verbruik" to="/" active />
    </Container>
  )
}

export default DashboardsSideMenu
