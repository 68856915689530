import { Timeframe, TimeframeWindow } from '../store'
import { format, isSameYear } from 'date-fns'

export const dateToTimestamp = (date: Date, timeFrame: Timeframe) => {
  const sameYear = isSameYear(date, new Date())

  switch (timeFrame.window) {
    case TimeframeWindow.H24:
      return format(date, 'HH:mm')
    case TimeframeWindow.WEEK:
    case TimeframeWindow.MONTH:
      return format(date, 'EEEEEE dd')
    case TimeframeWindow.YEAR:
      return format(date, 'MMMM yy')
    case TimeframeWindow.CUSTOM:
      return sameYear ? format(date, 'dd-MM-yy HH:mm') : format(date, 'dd MM HH:mm')
  }
}
