import { ReactNode, useEffect, useState } from 'react'
import { createZitadelAuth, ZitadelConfig } from '@zitadel/react'
import { useLazyQuery } from '@apollo/client'
import { GET_AUTH_ORGANISATION_ID_FOR_DOMAIN } from '../queries/customer'
import { LoadingScreen } from '../screens/LoadingScreen'
import { ErrorOrganisationScreen } from '../screens/errors/ErrorOrganisationScreen'
import { useRecoilState } from 'recoil'
import { authClient } from '../store'

interface InitProps {
  children: ReactNode
}

const productionZitadelConfig = {
  authority: 'https://idp.loqio.app',
  client_id: '272704270736883879@loqio_energy_management',
  automaticSilentRenew: true,
  monitorSession: true,
  monitorAnonymousSession: true
}

const testingZitadelConfig = {
  authority: 'https://idp.loqio.dev',
  client_id: '273348028302163992@loqio_energy_management',
  automaticSilentRenew: true,
  monitorSession: true,
  monitorAnonymousSession: true
}

const Init = ({ children }: InitProps) => {
  const [_auth, setAuthClient] = useRecoilState(authClient)
  const [organisationFetchError, setOrganisationFetchError] = useState(false)
  const [zitadelClientInit, setZitadelClientInit] = useState(false)
  const location = window.location
  const [fetchOrganisation] = useLazyQuery(GET_AUTH_ORGANISATION_ID_FOR_DOMAIN, {
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    setOrganisationFetchError(false)
    fetchOrganisation({
      variables: {
        domain: location.hostname
      }
    })
      .then(response => {
        const { data } = response
        if (!data || !data?.getOrganisationForDomain?.authOrganisationId) {
          setOrganisationFetchError(true)
        } else {
          const orgId = data.getOrganisationForDomain.authOrganisationId
          //@todo: set authority, client_id via env variables

          const redirect = location.port === '3000' ? `${location.protocol}//${location.hostname}:3000/auth/callback` : `${location.protocol}//${location.hostname}/auth/callback`
          const logoutRedirect = location.port === '3000' ? `${location.protocol}//${location.hostname}:3000/auth/logout` : `${location.protocol}//${location.hostname}/auth/logout`

          // const defaultConfig = location.port === '3000' ? testingZitadelConfig : productionZitadelConfig

          const zitadelConfig: ZitadelConfig = {
            ...productionZitadelConfig,
            redirect_uri: redirect,
            scope: `openid urn:zitadel:iam:org:id:${orgId} urn:zitadel:iam:user:metadata`,
            post_logout_redirect_uri: logoutRedirect
          }

          const client = createZitadelAuth(zitadelConfig)
          setAuthClient(client)
          setZitadelClientInit(true)
        }
      })
      .catch(() => {
        setOrganisationFetchError(true)
      })
  }, [])

  if (organisationFetchError) return <ErrorOrganisationScreen />
  if (!zitadelClientInit) return <LoadingScreen />
  return <>{children}</>
}

export default Init
