import { intlFormat } from 'date-fns'
import React, { useMemo, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import styled from '@emotion/styled'
import { DateRangeSelectionModal } from './base/DateRangeSelectionModal'
import 'react-day-picker/dist/style.css'
import { useRecoilValue } from 'recoil'
import { timeframe } from '../store'

const Date = styled.div`
  color: rgba(100, 105, 146, 1);
  text-transform: capitalize;
  font-family: 'Red Hat Display';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  padding-top: 6px;
  padding-right: 16px;
  cursor: pointer;
`

type FormatOptions = {
  weekday?: 'narrow' | 'short' | 'long'
  era?: 'narrow' | 'short' | 'long'
  year?: 'numeric' | '2-digit'
  month?: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long'
  day?: 'numeric' | '2-digit'
  hour?: 'numeric' | '2-digit'
  minute?: 'numeric' | '2-digit'
  second?: 'numeric' | '2-digit'
}

const ActiveDate = () => {
  const activeTimeframe = useRecoilValue(timeframe)
  const [showDateRangeSelection, setShowDateRangeSelection] = useState(false)

  const toggleDateRangeModal = () => {
    setShowDateRangeSelection(!showDateRangeSelection)
  }

  const formatOptions = useMemo<FormatOptions>(() => {
    switch (activeTimeframe.window) {
      case 'maand':
        return {
          month: 'long',
          year: 'numeric'
        }
      case 'week':
        return {
          day: 'numeric',
          month: 'long',
          year: 'numeric'
        }
      case 'jaar':
        return {
          year: 'numeric'
        }
      case 'custom':
        return {
          day: 'numeric',
          month: 'short',
          year: 'numeric'
        }
      default:
        return {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
          year: 'numeric'
        }
    }
  }, [activeTimeframe.window])

  return (
    <>
      <Date onClick={toggleDateRangeModal} role="button">
        {intlFormat(activeTimeframe.start, formatOptions, { locale: 'nl-NL' })}
        {activeTimeframe.window === 'custom' && activeTimeframe.end && ' - '}
        {activeTimeframe.window === 'custom' && activeTimeframe.end && intlFormat(activeTimeframe.end, formatOptions, { locale: 'nl-NL' })}
      </Date>
      <DateRangeSelectionModal open={showDateRangeSelection} close={toggleDateRangeModal} />
    </>
  )
}

export default ActiveDate
