import React, { useEffect, useMemo, useRef } from 'react'
import { gql, useLazyQuery, useReactiveVar } from '@apollo/client'
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import Centered from '../layout/Centered'
import { CircleLoader } from 'react-spinners'
import { transformChartData } from '../../utils/transformChartData'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { getTimeframe } from '../../utils/getTimeframe'
import { GET_WINDOWED_MEASUREMENTS_QUERY } from '../../energy/queries/metrics'
import * as Moment from 'moment/moment'
import mTZ from 'moment-timezone'
import { useRecoilValue } from 'recoil'
import { timeframe } from '../../store'

declare global {
  interface Window {
    moment: any
  }
}
window.moment = Moment
mTZ()

type Device = {
  name: string
  deviceId: string
}

interface Props {
  title: string
  devices: Device[]
  measurement: string
  unit: string
  divideMeasurementBy: number
}

const StyledCard = styled.div`
  box-shadow: 0 0 32px rgba(0, 0, 100, 0.08);
  padding: 24px;
  width: 100%;
`

const Title = styled.div`
  font-family: Red Hat Display;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`

export default ({ title, devices, measurement, unit, divideMeasurementBy }: Props) => {
  const selectedTimeframe = useRecoilValue(timeframe)
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const [fetchLineChartData, { data, loading }] = useLazyQuery(GET_WINDOWED_MEASUREMENTS_QUERY)

  const deviceIds = useMemo(() => {
    return devices.map(device => device.deviceId)
  }, [devices])

  const { start, end, window } = useMemo(() => getTimeframe(selectedTimeframe), [selectedTimeframe])

  //lineChartData?.getWindowedMeasurements
  useEffect(() => {
    fetchLineChartData({
      variables: {
        input: {
          deviceId: deviceIds,
          start,
          end,
          measurement,
          window,
          fn: 'max'
        }
      }
    })
  }, [devices, start, end, window])

  const energyData = useMemo(() => {
    return data?.getWindowedMeasurements ?? []
  }, [data?.getWindowedMeasurements])

  const chartData = useMemo(() => {
    return transformChartData({ data: energyData, devices, type: 'line', divideBy: divideMeasurementBy })
  }, [energyData, devices])

  const chartOptions: Highcharts.Options = {
    title: undefined,
    chart: {
      type: 'line'
    },
    xAxis: {
      type: 'datetime'
    },
    yAxis: {
      title: {
        text: unit
      }
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false
        },
        stacking: 'normal'
      },
      series: {
        step: 'center'
      }
    },
    credits: {
      enabled: false
    },
    time: {
      timezone: 'Europe/Amsterdam'
    },
    //@ts-ignore
    series: chartData
  }

  return (
    <StyledCard className="bg-white shadow xs:mx-auto w-full">
      <Title>{title}</Title>
      {loading && (
        <Centered>
          <CircleLoader color="#0D70EE" loading={loading} />
        </Centered>
      )}

      {!loading && chartData.length > 0 && <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartComponentRef} />}

      {!loading && chartData.length === 0 && <Centered>Er is geen data om weer te geven</Centered>}
    </StyledCard>
  )
}
