import React, { useMemo } from 'react'
import GridDistributorTotal from '../../components/grid/GridDistributorTotal'
import GridCircuit from '../../components/grid/GridCircuit'
import EnergyBarChart from '../../components/Energy/EnergyBarChart'
import EnergyContainer from '../containers/EnergyContainer'

interface Props {
  distributionBox?: any
}

type Device = {
  name: string
  deviceId: string
}

export default ({ distributionBox }: Props) => {
  const circuitsBarChart: Device[] = useMemo(() => {
    if (distributionBox?.circuits?.length > 0) {
      // @ts-ignore
      return distributionBox.circuits?.map(circuit => ({
        deviceId: circuit?.device?.deviceId,
        name: circuit?.label
      }))
    } else {
      return []
    }
  }, [distributionBox?.circuits])

  const total = useMemo(() => {
    return [<GridDistributorTotal deviceId={distributionBox?.device?.deviceId} />]
  }, [distributionBox?.device?.deviceId])

  const charts = useMemo(() => {
    return [
      <EnergyBarChart title="Actieve energie - import" devices={circuitsBarChart} measurement="energyImported" />,
      <EnergyBarChart title="Actieve energie - export" devices={circuitsBarChart} measurement="energyExported" isExport={true} />
    ]
  }, [circuitsBarChart])

  const deviceCards = useMemo(() => {
    //@ts-ignore
    return distributionBox.circuits?.map(circuit => <GridCircuit circuit={circuit} measurement={'energyImported'} />)
  }, [distributionBox?.circuits])

  return <EnergyContainer circuitsTitle={'Groepen'} charts={charts} deviceCards={deviceCards} totals={total} />
}
