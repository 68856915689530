import { gql } from '../../__generated'

export const DEVICES_IN_CATEGORY_QUERY = gql(/* GraphQL */ `
  query GetDevicesWithCategory($buildingId: ID!, $category: String!) {
    getDevicesWithCategory(buildingId: $buildingId, category: $category) {
      deviceId
      name
      type
    }
  }
`)
