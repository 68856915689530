import React, { ReactNode } from 'react'
import styled from '@emotion/styled'

const CircuitsSubTitle = styled.div`
  font-family: Red Hat Display;
  font-size: 20px;
  font-weight: 800;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-top: 48px;
`

interface Props {
  children: ReactNode
}
export default ({ children }: Props) => {
  return <CircuitsSubTitle>{children}</CircuitsSubTitle>
}
