//@ts-nocheck
import React, { useEffect } from 'react'
import { gql, useLazyQuery, useReactiveVar } from '@apollo/client'
import { SelectedTimeframeForReporting } from '../../TimeframeProvider'
import GridCircuitCardBase from './GridCircuitCardBase'
import styled from '@emotion/styled'
import { getTimeframe } from '../../utils/getTimeframe'
import { useRecoilValue } from 'recoil'
import { timeframe } from '../../store'

const GET_MEASUREMENT = gql`
  query GetPowerMinMaxMeasurement($input: GetMinMaxForPeriodInput) {
    getMinMaxForPeriod(input: $input)
  }
`

const MeasurementsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
`

const Measurement = styled.div`
  font-family: Red Hat Display;
  font-size: 24px;
  font-weight: 900;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme }) => theme.colors.coldGrey};
`

//@ts-ignore
export default ({ circuit, measurement, unit }) => {
  if (!circuit) return null
  const selectedTimeframe = useRecoilValue(timeframe)
  const [fetchMeasurement, { data }] = useLazyQuery(GET_MEASUREMENT, {
    fetchPolicy: 'no-cache'
  })

  const { circuitId, device, name, label } = circuit

  useEffect(() => {
    if (device?.deviceId) {
      const { start, end } = getTimeframe(selectedTimeframe)
      fetchMeasurement({
        variables: {
          input: {
            deviceId: device.deviceId,
            start,
            end,
            measurement
          }
        }
      })
    }
  }, [device?.deviceId, selectedTimeframe])

  let min = 0
  let minUnit = unit
  let max = 0
  let maxUnit = unit

  if (unit === 'kW' || unit === 'kWh') {
    if (data?.getMinMaxForPeriod?.min?.value >= 1000) {
      min = data?.getMinMaxForPeriod?.min?.value / 1000
    } else {
      min = data?.getMinMaxForPeriod?.min?.value
      minUnit = unit === 'kW' ? 'W' : 'Wh'
    }

    if (data?.getMinMaxForPeriod?.max?.value >= 1000) {
      max = data?.getMinMaxForPeriod?.max?.value / 1000
    } else {
      max = data?.getMinMaxForPeriod?.max?.value
      maxUnit = unit === 'kW' ? 'W' : 'Wh'
    }
  } else {
    min = data?.getMinMaxForPeriod?.min?.value
    max = data?.getMinMaxForPeriod?.max?.value
  }

  return (
    <GridCircuitCardBase circuitName={name} circuitLabel={label} showBar={false} value={0}>
      <MeasurementsRow>
        <Measurement>
          {Intl.NumberFormat().format(min)} {minUnit}
        </Measurement>

        <Measurement>
          {Intl.NumberFormat().format(max)} {maxUnit}
        </Measurement>
      </MeasurementsRow>
    </GridCircuitCardBase>
  )
}
