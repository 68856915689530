import styled from '@emotion/styled'
import { EnergyTableResult, EnergyTypes } from './EnergyTableResult'
import React, { useEffect, useMemo } from 'react'
import { EnergyTableColumnCategory, EnergyTableColumnData } from './EnergyTableColumn'
import { DuoBar } from '../../charts/DuoBar'
import { EnergyTableDuoResult } from './EnergyTableDuoResult'
import { times } from 'lodash'
import { Link, useNavigate } from 'react-router-dom'

const Column = styled('div')<{ isClickable: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 350px;

  padding: 24px 16px 16px 16px;
  border-top: 5px solid #ffffff;

  cursor: pointer;

  &:hover {
    box-shadow: ${({ isClickable }) => (isClickable ? '0px 0px 25px 1px rgba(191,194,214,0.68)' : undefined)};
    border-top: 5px solid ${({ theme, isClickable }) => (isClickable ? theme.colors.blueEnergetic : '#FFFFFF')};
  }
`

const Header = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  padding: 8px 0;
`

const Title = styled('div')`
  font-family: 'Red Hat Display';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
`

const Results = styled('div')`
  width: 100%;
`

const Totals = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`

const TotalBlock = styled('div')`
  display: flex;
  flex-direction: column;
`

const TotalTitle = styled('div')`
  font-family: 'Red Hat Display';
  font-size: 12px;
  font-style: normal;
  //font-weight: 700;
  line-height: 110%;
  color: ${({ theme }) => theme.colors.graphTextColor};
`

const Total = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 8px;

  &:last-of-type {
    justify-content: flex-end;
  }
`

const Value = styled('span')`
  font-family: 'Red Hat Display';
  font-size: 36px;
  font-style: normal;
  font-weight: 900;
  line-height: 110%;
  color: ${({ theme }) => theme.colors.graphTextColor};
`

const Unit = styled('span')`
  color: ${({ theme }) => theme.colors.graphTextColor};
  font-family: 'Red Hat Display';
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 110%;
`

interface EnergyTableSingleCategoryColumnProps {
  title: string
  link?: string
  imported: EnergyTableColumnCategory
  exported: EnergyTableColumnCategory
}

type MergedValues = {
  [time: string]: {
    imported: number
    exported: number
  }
}

export const EnergyTableDuoCategoryColumn = ({ title, link, imported, exported }: EnergyTableSingleCategoryColumnProps) => {
  const navigate = useNavigate()
  const { mergedValues, timestamps } = useMemo(() => {
    const values: MergedValues = {}
    imported.values.forEach(({ timestamp, value }) => {
      values[timestamp] = {
        ...values[timestamp],
        imported: value
      }
    })
    exported.values.forEach(({ timestamp, value }) => {
      values[timestamp] = {
        ...values[timestamp],
        exported: value
      }
    })
    return {
      mergedValues: Object.values(values) ?? [],
      timestamps: Object.keys(values) ?? []
    }
  }, [imported, exported])

  const navigateToLink = () => {
    if (link) navigate(link)
  }

  return (
    <Column isClickable={Boolean(link)} onClick={navigateToLink}>
      <Header>
        <Title>{title}</Title>

        <Totals>
          <TotalBlock>
            <TotalTitle>Export</TotalTitle>
            <Total>
              <Value>{exported.max}</Value>
              <Unit>kWh</Unit>
            </Total>
          </TotalBlock>
          <TotalBlock>
            <TotalTitle>Import</TotalTitle>
            <Total>
              <Value>{imported.max}</Value>
              <Unit>kWh</Unit>
            </Total>
          </TotalBlock>
        </Totals>
      </Header>
      <Results>
        {mergedValues.map((value, index) => (
          <EnergyTableDuoResult exportValue={value.exported} importValue={value.imported} importMax={imported.max} exportMax={exported.max} key={`duo-result-${timestamps[index]}`} />
        ))}
      </Results>
    </Column>
  )
}
