import React from 'react'
import styled from '@emotion/styled'
import GridSideMenu from './SideMenus/GridSideMenu'
import BuildingSideMenu from './SideMenus/BuildingSideMenu'
import CategoriesSideMenu from './SideMenus/CategoriesSideMenu'
import DashboardsSideMenu from './SideMenus/DashboardsSideMenu'
import { useRouteToActiveMenu } from '../../hooks/useRouteToActiveMenu'
import { SideMenus } from './SideMenu'

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.subMenuBackground};
  min-width: 256px;
`

export type SubMenuItem = {
  title: string
  to: string
  key: string
}

const SideSubMenu = () => {
  const activeMenu = useRouteToActiveMenu()

  return (
    <Container className="flex grow h-full flex-col py-5">
      {activeMenu === SideMenus.DASHBOARDS && <DashboardsSideMenu key={'dashboard-side-menu'} />}
      {activeMenu === SideMenus.BUILDING && <BuildingSideMenu key={'building-side-menu'} />}
      {activeMenu === SideMenus.GRID && <GridSideMenu key={'grid-side-menu'} />}
      {activeMenu === SideMenus.CATEGORIES && <CategoriesSideMenu key={'categories-side-menu'} />}
    </Container>
  )
}

export default SideSubMenu
