import React from 'react'

// @ts-ignore
export default props => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2384_5262)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M-0.00012207 6.22238L5.43061 11.2783L6.78805 10.1102L3.67476 7.22082L11.9999 7.22082V5.34113L3.67476 5.34113L6.78805 2.12926L5.43061 0.718321L-0.00012207 6.22238Z"
          fill="#9196BB"
        />
      </g>
      <defs>
        <clipPath id="clip0_2384_5262">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
