import { useSuspenseQuery } from '@apollo/client'
import { GetGridForPowerOverviewQuery } from '../../../dashboard/queries/gridForPowerOverview'
import { useMemo } from 'react'
import { GET_WINDOWED_MEASUREMENTS_QUERY } from '../../../energy/queries/metrics'
import { getMeasurementsTotal, influxDataToMeasurements } from '../../../utils/influxDataToMeasurements'
import Big from 'big.js'
import { useRecoilValue } from 'recoil'
import { building, timeframe } from '../../../store'
import { getTimeframe, TimeframeVariant } from '../../../utils/getTimeframe'

export const useGetBuildingEnergyConsumptionData = () => {
  const { activeBuildingId } = useRecoilValue(building)
  const selectedTimeframe = useRecoilValue(timeframe)
  const { start, end, window } = getTimeframe({ ...selectedTimeframe, variant: TimeframeVariant.CONDENSED })

  const { data: gridLayoutData, refetch: refetchGridLayout } = useSuspenseQuery(GetGridForPowerOverviewQuery, {
    variables: {
      buildingId: activeBuildingId
    },
    skip: !Boolean(activeBuildingId)
  })

  const consumptionDevices = useMemo(() => {
    if (gridLayoutData?.getBuilding?.grid?.consumption && gridLayoutData.getBuilding.grid.consumption.length > 0) {
      //@ts-ignore
      return gridLayoutData.getBuilding.grid.consumption.map(entry => entry.deviceId)
    }
    return []
  }, [gridLayoutData])

  const { data: consumptionMeasurements } = useSuspenseQuery(GET_WINDOWED_MEASUREMENTS_QUERY, {
    variables: {
      input: {
        deviceId: consumptionDevices,
        start,
        end,
        measurement: 'energyImported',
        window
      }
    },
    fetchPolicy: 'no-cache',
    skip: !Boolean(consumptionDevices && consumptionDevices.length > 0)
  })

  const consumption = useMemo(() => {
    if (!consumptionMeasurements?.getWindowedMeasurements || consumptionMeasurements.getWindowedMeasurements.length < 1)
      return {
        total: 0,
        max: 0,
        values: []
      }

    const values = influxDataToMeasurements(consumptionMeasurements.getWindowedMeasurements, selectedTimeframe)
    const allValues = values.map(value => value.value)
    const max = Math.max(...allValues)
    const total = getMeasurementsTotal(values)

    return {
      max,
      total: total.round(0, Big.roundHalfUp).toNumber(),
      values
    }
  }, [consumptionMeasurements?.getWindowedMeasurements, selectedTimeframe])

  const timeStamps = useMemo(() => {
    if (!consumption?.values || consumption.values.length < 1) return []
    const timestamps = consumption.values.map(value => value.timestamp)
    return timestamps.filter((value, index, array) => array.indexOf(value) === index)
  }, [consumption])

  return { consumption, timeStamps }
}
