import styled from '@emotion/styled'
import { GetBuildingGridQuery } from '../../../building/queries/grid'
import { useQuery } from '@apollo/client'
import MenuItem from '../MenuItem'
import { useEffect, useMemo } from 'react'
import { arrayToTree } from 'performant-array-to-tree'
import { Link, useLocation } from 'react-router-dom'
import Container from '../../base/Container'
import { useRecoilValue } from 'recoil'
import { building } from '../../../store'

const DistributionBoxContainer = styled('div')`
  border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
`

const BoxTitle = styled('div')<{ active?: boolean }>`
  font-family: 'Red Hat Text', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 162%; /* 22.68px */
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.menuItem};
  width: 100%;
  padding: 8px 16px;
  background-color: ${({ active, theme }) => (active ? theme.sideMenu.activeMenuBackgroundColor : 'transparent')};
`

const GridSideMenu = () => {
  const location = useLocation()
  const { activeBuildingId } = useRecoilValue(building)
  const { data: gridOfBuilding, refetch } = useQuery(GetBuildingGridQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      buildingId: activeBuildingId!
    },
    skip: !Boolean(activeBuildingId)
  })

  useEffect(() => {
    void refetch({ buildingId: activeBuildingId })
  }, [activeBuildingId])

  const distributionBoxes = useMemo(() => {
    return gridOfBuilding?.getBuilding?.grid?.distributionBoxes ?? []
  }, [gridOfBuilding?.getBuilding?.grid?.distributionBoxes])

  const menuLayout = arrayToTree(distributionBoxes, {
    id: 'distributionBoxId',
    parentId: 'parent.distributionBoxId'
  })

  return (
    <Container>
      {/*//@ts-ignore*/}
      {menuLayout.map(box => (
        <DistributionBoxContainer>
          <BoxTitle active={location.pathname === `/grid/${box.data.distributionBoxId}`}>
            <Link to={`/grid/${box.data.distributionBoxId}`}>{box.data.name}</Link>
          </BoxTitle>
          {/*//@ts-ignore*/}
          {box.children?.map(child => (
            <MenuItem title={child.data.name} to={`/grid/${child.data.distributionBoxId}`} key={child.data.distributionBoxId} active={location.pathname === `/grid/${child.data.distributionBoxId}`} />
          ))}
        </DistributionBoxContainer>
      ))}
    </Container>
  )
}

export default GridSideMenu
