import React from 'react'

// @ts-ignore
export default props => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clip-path="url(#clip0_2407_10070)">
        <path d="M28.4446 14.1771L17.4609 0.86343C17.1926 0.537911 16.7499 0.416757 16.3532 0.558548C16.3026 0.576521 3.8157 7.98556 3.8157 7.98556C3.51348 8.16596 3.32776 8.49215 3.32776 8.84429V31.5014C3.32776 32.0526 3.7751 32.5 4.32628 32.5H16.69H27.6737C28.2249 32.5 28.6723 32.0526 28.6723 31.5014V14.8121C28.6723 14.5798 28.5917 14.3561 28.4446 14.1771ZM26.6752 30.5029H17.6885V4.27771L26.6752 15.1709V30.5029Z" />
      </g>
      <defs>
        <clipPath id="clip0_2407_10070">
          <rect width="32" height="32" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
