import Centered from '../components/layout/Centered'
import { HashLoader } from 'react-spinners'
import { useTheme } from '@emotion/react'

const FullScreenLoading = () => {
  const theme = useTheme()

  return (
    <Centered>
      <HashLoader size={75} color={theme?.loader?.loaderColor ?? '#008CFF'} />
    </Centered>
  )
}

export default FullScreenLoading
