import { SVGProps } from 'react'

interface CategoriesIconProps extends SVGProps<SVGSVGElement> {
  active?: boolean
}

export default ({ active, ...props }: CategoriesIconProps) => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.4948 29C20.0338 29 19.6325 29.3152 19.5233 29.7631L16 44.2098H43.5052C43.9662 44.2098 44.3675 43.8946 44.4767 43.4467L47.6983 30.2369C47.8519 29.6072 47.375 29 46.7268 29H20.4948Z"
        fill="white"
        fillOpacity={active ? 1 : 0.5}
      />
      <path
        d="M44.2906 27.0764V25.5098C44.2906 24.9576 43.8429 24.5098 43.2906 24.5098H27.9226C27.5822 24.5098 27.2651 24.3366 27.0812 24.0502L24.8829 20.6267C24.699 20.3402 24.3819 20.167 24.0415 20.167H17C16.4477 20.167 16 20.6147 16 21.167V37.3098L18.4947 27.0764H44.2906Z"
        fill="white"
        fillOpacity={active ? 1 : 0.5}
      />
    </svg>
  )
}
