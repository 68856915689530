import { useEffect, useMemo } from 'react'
import { getTimeframe } from '../../utils/getTimeframe'
import { useLazyQuery } from '@apollo/client'
import { FETCH_DELTA_FOR_PERIOD_QUERY } from '../queries/metrics'
import TotalCard from '../../components/Energy/TotalCard'
import Big from 'big.js'
import { useRecoilValue } from 'recoil'
import { timeframe } from '../../store'

interface EnergyMeasurementSumCardProps {
  measurement: string
  title: string
  unit: string
  devices: string[]
}

const EnergyMeasurementSumCard = ({ devices, measurement, title, unit }: EnergyMeasurementSumCardProps) => {
  const selectedTimeframe = useRecoilValue(timeframe)
  const [fetchData, { data, loading }] = useLazyQuery(FETCH_DELTA_FOR_PERIOD_QUERY)

  const { start, end } = useMemo(() => {
    return getTimeframe(selectedTimeframe)
  }, [selectedTimeframe])

  useEffect(() => {
    if (devices && devices.length > 0) {
      void fetchData({
        variables: {
          input: {
            devices,
            end,
            measurement,
            start
          }
        }
      })
    }
  }, [devices, measurement, start, end])

  const value = useMemo(() => {
    if (data?.getDeltaForPeriod && data?.getDeltaForPeriod.length > 0) {
      const initialValue = Big(0)
      const total = data.getDeltaForPeriod.reduce(
        //@ts-ignore
        (acc: Big, cv) => {
          const delta = Big(cv?.delta ?? 0)
          return acc.plus(delta)
        },
        initialValue
      )
      return total.round(3).toNumber()
    } else {
      return 0
    }
  }, [data?.getDeltaForPeriod])

  if (loading) return null

  return <TotalCard title={title} unit={unit} value={value} />
}

export default EnergyMeasurementSumCard
