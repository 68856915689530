import Color from 'color'

export const generateColorRange = (startColor: string, steps: number) => {
  const baseColor = Color(startColor)
  let lighten = 0
  const lightenStep = 1 / (steps + 1)
  const colors = []

  for (let i = 0; i < steps; i++) {
    colors.push(baseColor.lighten(lighten).hexa())
    lighten += lightenStep
  }

  return colors
}
