import React from 'react'
import AppLayout from '../../layouts/AppLayout'
import EVChargerCard from '../../components/EVCharging/EVChargerCard'
import { ApolloClient, InMemoryCache, useQuery } from '@apollo/client'
import { CircleLoader } from 'react-spinners'
// import { ChargeStationsQuery } from './query'
import { useRecoilValue } from 'recoil'
import { user } from '../../store'

const chargingClient = new ApolloClient({
  uri: 'https://charging-api.loqio.app/graphql',
  cache: new InMemoryCache()
})

export default () => {
  const { customerId } = useRecoilValue(user)
  // const { data, loading } = useQuery(ChargeStationsQuery, {
  //   client: chargingClient,
  //   variables: {
  //     customerId
  //   },
  //   pollInterval: 5000,
  //   fetchPolicy: 'no-cache'
  // })

  const data = {}
  const loading = false

  // @ts-ignore
  const stations = data?.getChargingStationsForCustomer || []

  return (
    <AppLayout title="Smart Charging">
      {loading && (
        <div className="flex h-screen w-full justify-center items-center">
          <CircleLoader color="#0D70EE" loading={loading} />
        </div>
      )}
      {/*//@ts-ignore*/}
      {!loading && stations.map(station => station.sockets.map(socket => <EVChargerCard station={station} socket={socket} chargingClient={chargingClient} />))}
    </AppLayout>
  )
}
