import { useEffect, useMemo, useState } from 'react'
import { getTimeframe } from '../../utils/getTimeframe'
import { useApolloClient } from '@apollo/client'
import { FETCH_DELTA_FOR_PERIOD_QUERY } from '../queries/metrics'
import TotalCard from '../../components/Energy/TotalCard'
import Big from 'big.js'
import { useRecoilValue } from 'recoil'
import { timeframe } from '../../store'

interface EnergyMeasurementDeltaCardProps {
  measurements: string[]
  title: string
  unit: string
  deviceId: string
}

const EnergyMeasurementDeltaCard = ({ deviceId, measurements, title, unit }: EnergyMeasurementDeltaCardProps) => {
  const selectedTimeframe = useRecoilValue(timeframe)
  const [total, setTotal] = useState<number>(0)
  const apiClient = useApolloClient()

  const { start, end } = useMemo(() => {
    return getTimeframe(selectedTimeframe)
  }, [selectedTimeframe])

  useEffect(() => {
    const fetch = async () => {
      const queries = []
      for (const measurement of measurements) {
        queries.push(
          apiClient
            .query({
              query: FETCH_DELTA_FOR_PERIOD_QUERY,
              variables: {
                input: {
                  devices: [deviceId],
                  end,
                  measurement,
                  start
                }
              }
            })
            .then(({ data }) => {
              return data?.getDeltaForPeriod?.[0]?.delta ?? 0
            })
        )
      }

      return await Promise.all(queries)
    }

    if (deviceId && measurements.length === 2) {
      fetch().then(results => {
        const first = Big(results[0] ?? 0)
        const second = Big(results[1] ?? 0)
        const net = first.minus(second).round(3).toNumber()
        setTotal(net)
      })
    }
  }, [deviceId, measurements, start, end])

  return <TotalCard title={title} unit={unit} value={total} />
}

export default EnergyMeasurementDeltaCard
