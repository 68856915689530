import { atom } from 'recoil'
import { createZitadelAuth, ZitadelAuth, ZitadelConfig } from '@zitadel/react'

type User = {
  customerId?: string
  userId?: string
}

const defaultUserValues: User = {
  customerId: undefined,
  userId: undefined
}

export const user = atom<User>({
  key: 'user',
  default: defaultUserValues
})

//@todo: set authority, client_id via env variables via organisation
const defaultProductionZitadelConfig: ZitadelConfig = {
  authority: 'https://idp.loqio.app',
  client_id: '273348028302163992@loqio_energy_management',
  redirect_uri: `https://dashboard.loqio.app/auth/callback`,
  scope: `openid urn:zitadel:iam:user:metadata`
}

const defaultTestingZitadelConfig = {
  authority: 'https://idp.loqio.dev',
  client_id: '273348028302163992@loqio_energy_management',
  redirect_uri: `https://dashboard.loqio.dev/auth/callback`,
  scope: `openid urn:zitadel:iam:user:metadata`
}

const defaultZitadelClient = createZitadelAuth(defaultProductionZitadelConfig)

export const authClient = atom<ZitadelAuth>({
  key: 'authClient',
  default: defaultZitadelClient,
  dangerouslyAllowMutability: true
})
