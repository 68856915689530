import { ReactNode } from 'react'
import styled from '@emotion/styled'

const StyledContainer = styled('div')`
  height: 100%;
  width: 100%;
`

interface ContainerProps {
  children: ReactNode
}

const Container = ({ children, ...props }: ContainerProps) => {
  return <StyledContainer {...props}>{children}</StyledContainer>
}

export default Container
