import React from 'react'

// @ts-ignore
export default props => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clip-path="url(#clip0_2407_9263)">
        <path d="M18.4344 10.6895V13.9344H23.9468L23.0274 10.6895H18.4344Z" />
        <path d="M18.4603 18.2295H24.891L23.9702 14.9863H18.4603V18.2295Z" />
        <path d="M23.839 8.64844H3.80939C3.22873 8.64844 2.75526 9.03064 2.75526 9.4975L0 23.7533C0 24.222 0.47197 24.603 1.05413 24.603H2.64958H4.03156H23.7195H25.0081H27.328C27.9099 24.603 28.3825 24.2217 28.3825 23.7533L24.891 9.4975C24.891 9.03064 24.4194 8.64844 23.839 8.64844ZM1.26429 23.5834L4.01985 9.66804H23.6273L27.1188 23.5834H1.26429Z" />
        <path d="M9.92218 10.6895H4.96259L4.04297 13.9344H9.92218V10.6895Z" />
        <path d="M17.1431 10.6895H11.2651V13.9332H17.1431V10.6895Z" />
        <path d="M3.14856 18.2295H9.94709V14.9863H4.06818L3.14856 18.2295Z" />
        <path d="M17.1666 14.9863H11.287V18.2298H17.1666V14.9863Z" />
        <path d="M2.41449 22.5613H9.9471V19.3164H3.33291L2.41449 22.5613Z" />
        <path d="M17.1666 19.3164H11.287V22.5613H17.1666V19.3164Z" />
        <path d="M18.4603 19.3164V22.5613H25.8094L24.891 19.3164H18.4603Z" />
        <path d="M15.2577 25.3574H12.9336V29.975H10.2029V31.5471H18.0586V29.975H15.2577V25.3574Z" />
        <path d="M24.4863 7.96336C25.0658 7.96336 25.5383 8.34406 25.5383 8.81273L26.5204 12.8215C27.48 12.0234 28.1056 10.8348 28.1056 9.48916C28.1056 7.08698 26.1589 5.13965 23.7546 5.13965C21.8917 5.13965 20.3136 6.31807 19.696 7.96456H24.486V7.96336H24.4863Z" />
        <path d="M31.9354 9.39048L29.5477 8.74887C29.5177 8.74017 29.4837 8.74677 29.4591 8.76689C29.4336 8.7858 29.4201 8.81523 29.4201 8.84765V10.1315C29.4201 10.1627 29.4336 10.1936 29.4591 10.2125C29.4837 10.2326 29.5177 10.2377 29.5477 10.229L31.9354 9.58743C32.0216 9.56311 32.0216 9.4145 31.9354 9.39048Z" />
        <path d="M23.1124 4.0341H24.3977C24.4296 4.0341 24.4593 4.01939 24.4782 3.99417C24.4968 3.96925 24.5037 3.93712 24.4968 3.9068L23.8534 1.51933C23.8309 1.43106 23.682 1.43106 23.6582 1.51933L23.016 3.9068C23.007 3.93712 23.0139 3.96985 23.0338 3.99417C23.0521 4.01939 23.0827 4.0341 23.1124 4.0341Z" />
        <path d="M28.066 6.08577C28.0885 6.10799 28.1195 6.1188 28.1513 6.11459C28.181 6.11159 28.2095 6.09268 28.226 6.06476L29.4588 3.92288C29.5041 3.84362 29.3994 3.73853 29.3192 3.78477L27.1788 5.01904C27.1521 5.03345 27.1329 5.06227 27.1287 5.09259C27.1245 5.12382 27.1356 5.15564 27.1575 5.17786L28.066 6.08577Z" />
        <path d="M27.7331 12.9126C27.7177 12.8865 27.6898 12.8676 27.6601 12.8634C27.6286 12.8586 27.5976 12.8697 27.5751 12.8916L26.742 13.7248L26.8167 14.0349L28.8286 15.1932C28.9079 15.2383 29.0136 15.1332 28.9682 15.0545L27.7331 12.9126Z" />
        <path d="M18.8692 6.11692C18.898 6.11902 18.9296 6.10731 18.9512 6.08509L19.8594 5.17748C19.8807 5.15526 19.8933 5.12464 19.8891 5.09431C19.8891 5.06519 19.8705 5.03997 19.8459 5.02586L17.8652 3.95282C17.7935 3.91379 17.6863 4.01797 17.7265 4.09153L18.8002 6.07188C18.8158 6.0992 18.8395 6.11391 18.8692 6.11692Z" />
      </g>
      <defs>
        <clipPath id="clip0_2407_9263">
          <rect width="32" height="32" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
