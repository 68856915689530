export const threePhaseHarmonicDistortionMeasurements = [
  {
    key: 'totalHarmonicCurrentDistortionL1',
    label: 'L1'
  },
  {
    key: 'totalHarmonicCurrentDistortionL2',
    label: 'L2'
  },
  {
    key: 'totalHarmonicCurrentDistortionL3',
    label: 'L3'
  }
]

export const singlePhaseHarmonicDistortionMeasurements = [
  {
    key: 'totalHarmonicCurrentDistortion',
    label: 'L'
  }
]
