import styled from '@emotion/styled'

const Source = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  & p {
    font-family: 'Red Hat Display', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    color: #000;
  }
`

export default Source
