import useAppContext from '../../hooks/useAppContext'
import { LoadingScreen } from '../../screens/LoadingScreen'
import { useRecoilValue } from 'recoil'
import { authClient } from '../../store'

const LoginScreen = () => {
  const auth = useRecoilValue(authClient)

  void auth.authorize()

  return <LoadingScreen />
}

export default LoginScreen
