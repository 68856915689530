import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { useQuery, useSuspenseQuery } from '@apollo/client'
import { FETCH_DELTA_FOR_PERIOD_QUERY } from '../../../energy/queries/metrics'
import { getTimeframe } from '../../../utils/getTimeframe'
import Big from 'big.js'
import GridConnectionIcon from '../../../icons/GridConnectionIcon'
import RightArrowIcon from '../../../icons/RightArrowIcon'
import LeftArrowIcon from '../../../icons/LeftArrowIcon'
import Source from './SourceComponent'
import { Circle, CircleInner } from './CircleComponent'
import { Stat, StatIcon, StatMetric, StatsContainer } from './StatsComponents'
import { Name } from './NameComponent'
import { useRecoilValue } from 'recoil'
import { timeframe } from '../../../store'

const NetworkCircleInner = styled(CircleInner)`
  border-color: ${({ theme }) => theme.colors.barMagenta};
  gap: 8px;
`

interface PowerOverviewGridComponentProps {
  main: any
}

const PowerOverviewGridComponent = ({ main }: PowerOverviewGridComponentProps) => {
  const selectedTimeframe = useRecoilValue(timeframe)

  const devices = useMemo(() => {
    // @ts-ignore
    return main?.map(device => device.deviceId) ?? []
  }, [main])

  const { start, end, window, tickValues } = useMemo(() => {
    return getTimeframe(selectedTimeframe)
  }, [selectedTimeframe])

  const { data: importData } = useSuspenseQuery(FETCH_DELTA_FOR_PERIOD_QUERY, {
    variables: {
      input: {
        devices: devices,
        end,
        start,
        measurement: 'energyImported'
      }
    },
    skip: !Boolean(main?.length > 0)
  })

  const { data: exportData } = useSuspenseQuery(FETCH_DELTA_FOR_PERIOD_QUERY, {
    variables: {
      input: {
        devices: devices,
        end,
        start,
        measurement: 'energyExported'
      }
    },
    skip: !Boolean(main?.length > 0)
  })

  const totalImport: Big = useMemo(() => {
    // @ts-ignore
    return (
      importData?.getDeltaForPeriod?.reduce((acc, cv) => {
        return acc.plus(Big(cv.delta))
      }, Big(0)) ?? Big(0)
    )
  }, [importData?.getDeltaForPeriod])

  const totalExport: Big = useMemo(() => {
    // @ts-ignore
    return (
      exportData?.getDeltaForPeriod?.reduce((acc, cv) => {
        return acc.plus(Big(cv.delta))
      }, Big(0)) ?? Big(0)
    )
  }, [exportData?.getDeltaForPeriod])

  return (
    <Source>
      <Circle>
        <NetworkCircleInner>
          <Name>Netwerk</Name>
          <GridConnectionIcon fill="#9196BB" />
          <StatsContainer>
            <Stat>
              <StatIcon>
                <RightArrowIcon />
              </StatIcon>
              <StatMetric>{totalImport.round(1).toNumber()} kWh</StatMetric>
            </Stat>
            <Stat>
              <StatIcon>
                <LeftArrowIcon />
              </StatIcon>
              <StatMetric>{totalExport.round(1).toNumber()} kWh</StatMetric>
            </Stat>
          </StatsContainer>
        </NetworkCircleInner>
      </Circle>
    </Source>
  )
}

export default PowerOverviewGridComponent
