import styled from '@emotion/styled'
import { HashLoader } from 'react-spinners'
import { useTheme } from '@emotion/react'

const Wrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`

export const LoadingScreen = () => {
  const theme = useTheme()

  return (
    <Wrapper>
      <HashLoader size={75} color={theme?.loader?.loaderColor ?? '#008CFF'} />
    </Wrapper>
  )
}
