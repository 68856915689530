import { getMeasurementsTotal, getTimestampsFromMeasurements, influxDataToMeasurements } from '../../utils/influxDataToMeasurements'
import { EnergyTableSingleCategoryColumn } from '../../components/Energy/EnergyTable/EnergyTableSingleCategoryColumn'
import { EnergyTypes } from '../../components/Energy/EnergyTable/EnergyTableResult'
import { useRecoilValue } from 'recoil'
import { building, timeframe } from '../../store'
import { getTimeframe, TimeframeVariant } from '../../utils/getTimeframe'
import { useQuery, useSuspenseQuery } from '@apollo/client'
import { GetGridForPowerOverviewQuery } from '../../dashboard/queries/gridForPowerOverview'
import { useEffect, useMemo } from 'react'
import { GET_WINDOWED_MEASUREMENTS_QUERY } from '../queries/metrics'
import Big from 'big.js'
import { EnergyTableColumnData } from '../../components/Energy/EnergyTable/EnergyTableColumn'

interface EnergyCategoryConsumptionColumnProps {
  total: number
  max: number
  values: EnergyTableColumnData[]
}

export const EnergyCategoryConsumptionColumn = ({ total, values, max }: EnergyCategoryConsumptionColumnProps) => {
  return <EnergyTableSingleCategoryColumn title={'Verbruik'} type={EnergyTypes.Usage} total={total} resultMax={max} values={values} />
}
