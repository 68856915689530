import React, { useEffect } from 'react'
import TopBarSubMenuItem from './TopBarSubMenuItem'
import { makeVar, useReactiveVar } from '@apollo/client'
import styled from '@emotion/styled'

const TopRow = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
`

const Title = styled('div')`
  color: #008cff;
  font-family: 'Red Hat Display', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
  padding-bottom: 5px;
`

const Logo = styled('img')`
  height: 40px;
`

export type TopBarMenuItem = {
  id: string
  title: string
  to: string
}

interface TopBarProps {
  title?: string
}

export const activeTopBarMenu = makeVar<string | null>(null)
export const topBarMenuItems = makeVar<TopBarMenuItem[]>([])

const TopBar = ({ title }: TopBarProps) => {
  const items = useReactiveVar(topBarMenuItems)
  const activeMenu = useReactiveVar(activeTopBarMenu)

  useEffect(() => {
    if (!activeMenu) {
      activeTopBarMenu(items[0]?.id)
    }
  }, [items])

  return (
    <div className="flex flex-1 flex-col bg-white h-20 max-h-20 shadow-top-bar px-16" style={{ minHeight: '80px' }}>
      <TopRow>
        <div>
          <Title>{title}</Title>

          {items && items.length > 0 && (
            <div className="flex flex-row">
              {items.map(item => (
                <TopBarSubMenuItem to={item.to} id={item.id} title={item.title} key={item.id} />
              ))}
            </div>
          )}
        </div>
        <Logo src="/logo.svg" />
      </TopRow>
    </div>
  )
}

export default TopBar
