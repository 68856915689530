//@ts-nocheck
import React, { useEffect, useMemo } from 'react'
import AppLayout from '../../layouts/AppLayout'
import { activeTopBarMenu, topBarMenuItems } from '../../components/TopBar/TopBar'
import { useParams } from 'react-router-dom'
import { useQuery, useReactiveVar } from '@apollo/client'
import Energy from './EnergyCategoryConsumption'
import EnergyPower from './EnergyCategoryPowerScreen'
import EnergyCurrent from './EnergyCategoryCurrentScreen'
import { DEVICES_IN_CATEGORY_QUERY } from '../queries/category'
import Centered from '../../components/layout/Centered'
import Loader from '../../components/base/Loader'
import { energyDeviceTypes } from '../constants/energyDeviceTypes'
import EnergyCategorieOverviewScreen from './EnergyCategoryOverviewScreen'
import { isThreePhaseDevice } from '../../utils/isThreePhaseDevice'
import useBuildingContext from '../../building/hooks/useBuildingContext'
import { useRecoilValue } from 'recoil'
import { building } from '../../store'

export default () => {
  const activeMenu = useReactiveVar(activeTopBarMenu)
  const { category } = useParams()
  const { activeBuildingId } = useRecoilValue(building)

  const { data, loading } = useQuery(DEVICES_IN_CATEGORY_QUERY, {
    variables: {
      buildingId: activeBuildingId,
      category: category
    },
    skip: !activeBuildingId || !category,
    fetchPolicy: 'no-cache'
  })

  const devices = useMemo(() => {
    if (data?.getDevicesWithCategory?.length > 0) {
      return data.getDevicesWithCategory
        .filter(device => energyDeviceTypes.includes(device.type))
        .map(device => ({
          deviceId: device.deviceId,
          name: device.name,
          type: device.type,
          isThreePhase: isThreePhaseDevice(device.type)
        }))
    } else {
      return []
    }
  }, [data?.getDevicesWithCategory])

  useEffect(() => {
    topBarMenuItems([
      {
        id: 'energie',
        title: 'Energie',
        to: ''
      },
      {
        id: 'vermogen',
        title: 'Vermogen',
        to: ''
      },
      {
        id: 'belasting',
        title: 'Belasting',
        to: ''
      }
    ])

    return () => {
      topBarMenuItems([])
    }
  })

  return (
    <AppLayout title={category}>
      {loading && (
        <Centered>
          <Loader />
        </Centered>
      )}
      {!loading && activeMenu === 'energie' && <Energy devices={devices} />}
      {!loading && activeMenu === 'vermogen' && <EnergyPower devices={devices} />}
      {!loading && activeMenu === 'belasting' && <EnergyCurrent devices={devices} />}
    </AppLayout>
  )
}
