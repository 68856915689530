import { useMemo } from 'react'
import Big from 'big.js'
import { energyTableGetTotal, energyTableGetValues } from '../PowerOverviewEnergyTable'
import { useQuery, useSuspenseQuery } from '@apollo/client'
import { GET_WINDOWED_MEASUREMENTS_QUERY } from '../../../../energy/queries/metrics'
import { useRecoilValue } from 'recoil'
import { building, timeframe } from '../../../../store'
import { getTimeframe, TimeframeVariant } from '../../../../utils/getTimeframe'
import { GetGridForPowerOverviewQuery } from '../../../queries/gridForPowerOverview'
import { EnergyTableSingleCategoryColumn } from '../../../../components/Energy/EnergyTable/EnergyTableSingleCategoryColumn'
import { EnergyTypes } from '../../../../components/Energy/EnergyTable/EnergyTableResult'

interface PowerOverviewImportColumnProps {
  maxForBarChart: number
}

export const PowerOverviewSolarColumn = ({ maxForBarChart }: PowerOverviewImportColumnProps) => {
  const { activeBuildingId } = useRecoilValue(building)
  const selectedTimeframe = useRecoilValue(timeframe)
  const { start, end, window } = getTimeframe({ ...selectedTimeframe, variant: TimeframeVariant.CONDENSED })

  const { data: gridLayoutData } = useSuspenseQuery(GetGridForPowerOverviewQuery, {
    variables: {
      buildingId: activeBuildingId
    },
    skip: !Boolean(activeBuildingId)
  })

  const { data: solarExport } = useSuspenseQuery(GET_WINDOWED_MEASUREMENTS_QUERY, {
    variables: {
      input: {
        deviceId: gridLayoutData?.getBuilding?.grid?.solar?.map(entry => entry.deviceId) ?? [],
        start,
        end,
        measurement: 'energyExported',
        window
      }
    },
    fetchPolicy: 'no-cache',
    skip: !Boolean(gridLayoutData?.getBuilding?.grid?.solar && gridLayoutData?.getBuilding?.grid?.solar?.length > 0)
  })

  const solarExported = useMemo(() => {
    if (!solarExport?.getWindowedMeasurements || solarExport.getWindowedMeasurements.length < 1)
      return {
        total: 0,
        values: []
      }

    const total = energyTableGetTotal(solarExport.getWindowedMeasurements)
    const values = energyTableGetValues(solarExport.getWindowedMeasurements, selectedTimeframe)

    return {
      total: total.round(0, Big.roundHalfUp).toNumber(),
      values
    }
  }, [solarExport?.getWindowedMeasurements, selectedTimeframe])

  return <EnergyTableSingleCategoryColumn title={'Opwek'} type={EnergyTypes.Solar} total={solarExported.total} resultMax={maxForBarChart} values={solarExported.values} />
}
