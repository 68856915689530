import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import GridCircuitCardBase from './GridCircuitCardBase'

const Measurement = styled.div`
  margin: 8px 0;
  font-size: 48px;
  font-weight: 900;

  color: ${({ theme }) => {
    //@ts-ignore
    return theme.colors.coldGrey
  }};
`

const MeasurementFraction = styled.span`
  font-size: 24px;
`

interface Props {
  circuitName: string
  value: number
  unit: string
  max?: number
  circuitLabel: string
  showBar?: boolean
  circuitId?: string
}
export default ({ circuitId, circuitName, circuitLabel, value, unit, max, showBar = true }: Props) => {
  const integral = Math.floor(value)
  const fraction = Math.round((value - integral) * 100) / 100
  const decimal = fraction.toString().split('.')[1]

  return (
    <GridCircuitCardBase circuitLabel={circuitLabel} circuitName={circuitName} value={value} max={max} showBar={showBar}>
      <Measurement>
        {integral}
        <MeasurementFraction>
          {decimal && <>,{decimal}</>} {unit}
        </MeasurementFraction>
      </Measurement>
    </GridCircuitCardBase>
  )
}
