import React from 'react'
import DeviceCard from '../../base/DeviceCard'
import { MeasurementFraction, MeasurementIntegral, MeasurementTitle, MeasurementUnit } from '../../base/Card'
import { Device } from '../Device'
import Big from 'big.js'

interface Props {
  device: Device
}

export default ({ device }: Props) => {
  if (!device) return null

  // @ts-ignore
  const activePower = new Big(device.data?.find(d => d.key === 'activePower')?.value || 0)
  const power = activePower.gte(1000) ? activePower.div(1000).round(2) : activePower.round(2)
  // @ts-ignore
  const voltage = new Big(device.data?.find(d => d.key === 'voltage')?.value || 0).round(2)
  // @ts-ignore
  const current = new Big(device.data?.find(d => d.key === 'current')?.value || 0).round(2)

  return (
    <DeviceCard title={device.name}>
      <div className={'flex flex-row mt-2 mb-6 justify-between space-x-12'}>
        <div className={'flex flex-col w-1/3'}>
          <MeasurementTitle>Actief vermogen</MeasurementTitle>
          <div className="mt-2">
            <MeasurementIntegral>{Math.floor(power.toNumber())}</MeasurementIntegral>
            <MeasurementFraction>,{power.toString().split('.')[1] || 0}</MeasurementFraction>
            <MeasurementUnit>{activePower.gte(1000) ? 'kW' : 'W'}</MeasurementUnit>
          </div>
        </div>

        <div className={'flex flex-col w-1/3'}>
          <MeasurementTitle>Voltage</MeasurementTitle>
          <div className="mt-2">
            <MeasurementIntegral>{Math.floor(voltage.toNumber())}</MeasurementIntegral>
            <MeasurementFraction>,{voltage.toString().split('.')[1] || 0}</MeasurementFraction>
            <MeasurementUnit>V</MeasurementUnit>
          </div>
        </div>

        <div className={'flex flex-col w-1/3'}>
          <MeasurementTitle>Stroom</MeasurementTitle>
          <div className="mt-2">
            <MeasurementIntegral>{Math.floor(current.toNumber())}</MeasurementIntegral>
            <MeasurementFraction>,{current.toString().split('.')[1] || 0}</MeasurementFraction>
            <MeasurementUnit>A</MeasurementUnit>
          </div>
        </div>
      </div>
    </DeviceCard>
  )
}
