import styled from '@emotion/styled'

const Timestamp = styled('div')`
  display: flex;
  height: 32px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  font-family: 'Red Hat Display';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderBar};

  &:first-of-type {
    border-top: 1px solid ${({ theme }) => theme.colors.borderBar};
  }
`

interface EnergyTableTimestampProps {
  timestamp: string
}

export const EnergyTableTimestamp = ({ timestamp }: EnergyTableTimestampProps) => {
  return <Timestamp>{timestamp}</Timestamp>
}
