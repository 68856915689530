import React from 'react'

// @ts-ignore
export default props => {
  const strokeOpacity = props.active ? '1.0' : '0.5'

  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M43.5 42.4997V21.4997H38V16.8125L21.5 19.6573V42.4997H17V44.4997H22.4313L38 46.6471V23.4997H41.5V44.4997H47V42.4997H43.5ZM36 44.3523L23.5 42.6282V21.3421L36 19.1872V44.3523Z"
        fill="white"
        fillOpacity={strokeOpacity}
      />
      <path
        d="M21.375 42.3747H17H16.875V42.4997V44.4997V44.6247H17H22.4227L37.9829 46.7709L38.125 46.7905V46.6471V23.6247H41.375V44.4997V44.6247H41.5H47H47.125V44.4997V42.4997V42.3747H47H43.625V21.4997V21.3747H43.5H38.125V16.8125V16.6641L37.9788 16.6893L21.4788 19.5341L21.375 19.552V19.6573V42.3747ZM35.875 19.3356V44.2089L23.625 42.5192V21.4474L35.875 19.3356Z"
        stroke="white"
        strokeOpacity={strokeOpacity}
        strokeWidth="0.25"
      />
      <path d="M34 30.5H32V34.5H34V30.5Z" fill="white" fillOpacity={strokeOpacity} />
      <path d="M34.125 30.5V30.375H34H32H31.875V30.5V34.5V34.625H32H34H34.125V34.5V30.5Z" stroke="white" strokeOpacity={strokeOpacity} strokeWidth="0.25" />
    </svg>
  )
}
