import { gql } from '../../__generated'

export const SET_CHARGE_STRATEGY_MUTATION = gql(/* GraphQL */ `
  mutation ($chargeStationId: ID!, $socketId: ID!, $strategy: ChargeStrategy) {
    setChargeStrategy(input: { chargeStationId: $chargeStationId, socketId: $socketId, strategy: $strategy })
  }
`)

export const SET_CHARGE_TARGET = gql(/* GraphQL */ `
  mutation ($chargeStationId: ID!, $socketId: ID!, $energyTarget: Int!, $departureTime: String) {
    setChargeTarget(input: { chargeStationId: $chargeStationId, socketId: $socketId, energyTarget: $energyTarget, departureTime: $departureTime })
  }
`)
