//@ts-nocheck
import React from 'react'
import styled from '@emotion/styled'

const StyledH4 = styled.h4`
  color: ${({ theme }) => theme.colors.titles};
  padding: 0;
  margin: 0;
  font-size: 20px;
  line-height: 22px;
  font-weight: 700;
`

interface Props {
  children: React.ReactNode
}

const H4 = ({ children, ...props }: Props) => {
  return <StyledH4 {...props}>{children}</StyledH4>
}

export default H4
