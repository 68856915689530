import React, { ReactNode, useEffect, useMemo } from 'react'
import { gql, useLazyQuery, useReactiveVar } from '@apollo/client'
import PowerDonutChart from '../charts/PowerDonutChart'
import styled from '@emotion/styled'
import { getTimeframe } from '../../utils/getTimeframe'
import { useRecoilValue } from 'recoil'
import { timeframe } from '../../store'

const GET_POWER_MEASUREMENT = gql`
  query GetPowerMinMaxMeasurement($input: GetMinMaxForPeriodInput) {
    getMinMaxForPeriod(input: $input)
  }
`

export enum SIZE {
  small = 'small',
  large = 'large'
}

export enum DISPLAY_VALUE {
  MIN = 'min',
  MAX = 'max'
}

interface Props {
  deviceId: string
  measurement: string
  label: string | ReactNode
  unit: string
  capacity: number
  size: SIZE
  poll?: boolean
  divideKW?: boolean
  displayValue?: DISPLAY_VALUE
}

const StyledDonutChart = styled(PowerDonutChart)<{ size: SIZE }>`
  width: ${({ size }) => (size === SIZE.small ? '160px' : '250px')};
  height: ${({ size }) => (size === SIZE.small ? '160px' : '250px')};
`

export default ({ deviceId, measurement, label, unit, capacity, size, poll, divideKW, displayValue = DISPLAY_VALUE.MAX }: Props) => {
  const selectedTimeframe = useRecoilValue(timeframe)
  const [fetchData, { data }] = useLazyQuery(GET_POWER_MEASUREMENT, {
    pollInterval: poll ? 5000 : undefined,
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    const { start, end, window } = getTimeframe(selectedTimeframe)
    fetchData({
      variables: {
        input: {
          start,
          end,
          deviceId,
          measurement
        }
      }
    })
  }, [deviceId, measurement, selectedTimeframe])

  const value = useMemo(() => {
    if (displayValue === DISPLAY_VALUE.MAX) {
      return data?.getMinMaxForPeriod?.max?.value
    } else {
      return data?.getMinMaxForPeriod?.min?.value
    }
  }, [data?.getMinMaxForPeriod?.max?.value, data?.getMinMaxForPeriod?.min?.value])

  return <StyledDonutChart name={label} unit={unit} divideKW={divideKW} capacity={capacity} power={value} size={size} />
}
