import useAppContext from '../../hooks/useAppContext'
import { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { authClient, user } from '../../store'
import { useLazyQuery } from '@apollo/client'
import { GET_CURRENT_USER_QUERY } from '../../queries/user'
import { Navigate } from 'react-router-dom'
import { LoadingScreen } from '../../screens/LoadingScreen'

export const LoginCallbackScreen = () => {
  const auth = useRecoilValue(authClient)
  const [loqioUser, setLoqioUser] = useRecoilState(user)
  const [fetchUser] = useLazyQuery(GET_CURRENT_USER_QUERY)
  const [loginSuccess, setLoginSuccess] = useState(false)
  const [loginError, setLoginError] = useState(false)

  useEffect(() => {
    auth.userManager
      .signinCallback()
      .then(user => {
        if (user) {
          fetchUser()
            .then(userDetails => {
              setLoqioUser({
                ...loqioUser,
                userId: userDetails?.data?.me?.userId,
                customerId: userDetails?.data?.me?.customer?.customerId
              })
              setLoginSuccess(true)
            })
            .catch(() => {
              setLoginError(true)
            })
        } else {
          //Logout, show error
          setLoginError(true)
        }
      })
      .catch(() => {
        //@todo: add error messages
        setLoginError(true)
      })
  }, [auth])

  if (loginSuccess) return <Navigate to={'/'} replace />
  if (loginError) return <Navigate to={'/login'} replace />

  return <LoadingScreen />
}
