import React, { useMemo } from 'react'
import AppLayout from '../../layouts/AppLayout'
import { skipToken, useSuspenseQuery } from '@apollo/client'
import { Navigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { building } from '../../store'
import { GetBuildingLayoutQuery } from '../../queries/building'
import Centered from '../../components/layout/Centered'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import styled from '@emotion/styled'

const StyledExclamationTriangleIcon = styled(ExclamationTriangleIcon)`
  width: 75px;
  height: 75px;
  color: ${({ theme }) => theme.colors.alert};
`

const BuildingScreen = () => {
  const { activeBuildingId } = useRecoilValue(building)
  const { data } = useSuspenseQuery(
    GetBuildingLayoutQuery,
    activeBuildingId
      ? {
          variables: {
            buildingId: activeBuildingId
          }
        }
      : skipToken
  )

  const sections = useMemo(() => {
    return data?.getBuildingLayout ?? []
  }, [data?.getBuildingLayout])

  return (
    <AppLayout>
      {sections && sections.length > 0 && <Navigate to={`/section/${sections[0].sectionId}`} />}
      {sections.length === 0 && (
        <Centered>
          <StyledExclamationTriangleIcon />
          De gebouwindeling ontbreekt.
        </Centered>
      )}
    </AppLayout>
  )
}

export default BuildingScreen
