import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useApolloClient, useLazyQuery } from '@apollo/client'
import Big from 'big.js'
import { GET_WINDOWED_MEASUREMENTS_QUERY } from '../../energy/queries/metrics'
import { EnergyDevice } from '../../energy/types/devices'
import { getTimeframe } from '../../utils/getTimeframe'
import { useRecoilValue } from 'recoil'
import { timeframe } from '../../store'

const StyledCard = styled.div<{ isClickable: boolean }>`
  min-width: 320px;
  width: fit-content;
  background-color: #ffffff;
  border-top: 5px solid #ffffff;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};

  &:hover {
    box-shadow: ${({ isClickable }) => (isClickable ? '0px 0px 25px 1px rgba(191,194,214,0.68)' : undefined)};
    border-top: 5px solid ${({ theme, isClickable }) => (isClickable ? theme.colors.blueEnergetic : '#FFFFFF')};
  }
`

const Content = styled.div`
  padding: 24px 16px 17px;
`

const Name = styled.div`
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #000000;
`

const Measurements = styled.div`
  display: flex;
  flex-direction: row;
`

const MeasurementContainer = styled.div`
  margin-top: 8px;
  margin-left: 32px;

  :first-of-type {
    margin-left: 0;
  }
`

const MeaurementLabel = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: #9196bb;
`

const Measurement = styled.div`
  margin: 8px 0;
  font-size: 40px;
  font-weight: 900;

  color: ${({ theme }) => {
    //@ts-ignore
    return theme.colors.coldGrey
  }};
`

const MeasurementFraction = styled.span`
  font-size: 24px;
`

interface Props {
  device: EnergyDevice
  onClick?: VoidFunction
}

export default ({ device, onClick }: Props) => {
  const selectedTimeframe = useRecoilValue(timeframe)
  const client = useApolloClient()
  const [totalExported, setTotalExported] = useState(Big(0))
  const [fetchMeasurements, { data }] = useLazyQuery(GET_WINDOWED_MEASUREMENTS_QUERY, {
    fetchPolicy: 'no-cache'
  })

  if (!device) return null

  useEffect(() => {
    const { start, end, window } = getTimeframe(selectedTimeframe)
    fetchMeasurements({
      variables: {
        input: {
          deviceId: [device.deviceId],
          start,
          end,
          measurement: 'energyImported',
          window
        }
      }
    })

    client
      .query({
        query: GET_WINDOWED_MEASUREMENTS_QUERY,
        variables: {
          input: {
            deviceId: [device.deviceId],
            start,
            end,
            measurement: 'energyExported',
            window
          }
        }
      })
      .then(response => {
        if (response?.data?.getWindowedMeasurements && response.data?.getWindowedMeasurements.length > 0) {
          //@ts-ignore
          const exported =
            response.data.getWindowedMeasurements?.reduce((acc: { plus: (arg0: any) => any }, cv: { value: any; _value: any }) => {
              if (cv.value) return acc.plus(cv._value)
              return acc
            }, Big(0)) || Big(0)

          setTotalExported(exported)
        }
      })
  }, [device.deviceId, selectedTimeframe])

  //@todo: Type getWindowedMeasurements for all components
  //@ts-ignore
  const totalImported =
    data?.getWindowedMeasurements?.reduce((acc: { plus: (arg0: any) => any }, cv: { _value: any }) => {
      if (cv._value) return acc.plus(cv._value)
      return acc
    }, new Big(0)) || new Big(0)
  const importIntegral = totalImported.round(0, Big.roundDown)
  const importDecimal = totalImported.minus(importIntegral).round(2).toString().split('.')[1]

  const exportIntegral = totalExported.round(0, Big.roundDown)
  const exportDecimal = totalExported.minus(exportIntegral).round(2).toString().split('.')[1]

  return (
    <StyledCard onClick={onClick} isClickable={Boolean(onClick)}>
      <Content>
        <Name>{device.name}</Name>
        <Measurements>
          <MeasurementContainer>
            <MeaurementLabel>Import</MeaurementLabel>
            <Measurement>
              {importIntegral.toNumber()}
              <MeasurementFraction>{importDecimal && `,${importDecimal}`} kWh</MeasurementFraction>
            </Measurement>
          </MeasurementContainer>

          {totalExported.abs().toNumber() > 0.1 && (
            <MeasurementContainer>
              <MeaurementLabel>Export</MeaurementLabel>
              <Measurement>
                {exportIntegral.toNumber()}
                <MeasurementFraction>{exportDecimal && `,${exportDecimal} `} kWh</MeasurementFraction>
              </Measurement>
            </MeasurementContainer>
          )}
        </Measurements>
      </Content>
    </StyledCard>
  )
}
