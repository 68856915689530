import React from 'react'
import styled from '@emotion/styled'
import { endOfDay, endOfMonth, endOfWeek, endOfYear, startOfDay, startOfMonth, startOfWeek, startOfYear } from 'date-fns'
import { useRecoilState } from 'recoil'
import { timeframe, TimeframeWindow } from '../../store'

const StyledButton = styled.button<{ active: boolean }>`
  padding: 5px 16px;
  border-radius: 16px;
  height: 32px;

  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: ${({ active }) => (active ? 700 : 500)};
  font-size: 14px;

  color: ${({ active }) => (active ? '#FFFFFF' : '#000000')};
  background-color: ${({ active, theme }) => (active ? theme.colors.blueEnergetic : 'transparent')};
`

interface Props {
  label: string
  window: TimeframeWindow
}

const startDate = (date: Date, window: TimeframeWindow) => {
  switch (window) {
    case TimeframeWindow.H24:
      return startOfDay(date)
    case TimeframeWindow.WEEK:
      return startOfWeek(date, { weekStartsOn: 1 })
    case TimeframeWindow.MONTH:
      return startOfMonth(date)
    case TimeframeWindow.YEAR:
      return startOfYear(date)
    case TimeframeWindow.CUSTOM:
      return startOfDay(date)
  }
}

const endDate = (date: Date, window: TimeframeWindow) => {
  switch (window) {
    case TimeframeWindow.H24:
      return endOfDay(date)
    case TimeframeWindow.WEEK:
      return endOfWeek(date, { weekStartsOn: 1 })
    case TimeframeWindow.MONTH:
      return endOfMonth(date)
    case TimeframeWindow.YEAR:
      return endOfYear(date)
    case TimeframeWindow.CUSTOM:
      return endOfDay(date)
  }
}

export default ({ label, window, ...props }: Props) => {
  const [activeFrame, setActiveFrame] = useRecoilState(timeframe)
  const isActive = activeFrame.window === window

  const clickHandler = () => {
    if (window === TimeframeWindow.H24) {
      const start = startDate(new Date(), window)
      const end = endDate(start, window)
      setActiveFrame({
        window,
        start,
        end
      })
    } else {
      const start = startDate(activeFrame.start, window)
      const end = endDate(start, window)
      setActiveFrame({
        window,
        start,
        end
      })
    }
  }

  return (
    <StyledButton active={isActive} onClick={clickHandler} {...props}>
      {label}
    </StyledButton>
  )
}
