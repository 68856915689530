//@ts-nocheck
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { DayPicker } from 'react-day-picker'
import { CalendarDaysIcon } from '@heroicons/react/20/solid'
import { endOfDay, startOfDay } from 'date-fns'
import { useSetRecoilState } from 'recoil'
import { timeframe } from '../../store'

interface DateRangeSelectionModalProps {
  open: boolean
  close: VoidFunction
}

interface DateRange {
  from: Date
  to: Date
}

export const DateRangeSelectionModal = ({ open, close }) => {
  const setActiveTimeFrame = useSetRecoilState(timeframe)
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange | null>(null)

  const onCancel = () => {
    setSelectedDateRange(null)
    close()
  }

  const onSave = () => {
    if (selectedDateRange) {
      setActiveTimeFrame({
        start: startOfDay(selectedDateRange.from),
        end: selectedDateRange.to ? endOfDay(selectedDateRange.to) : endOfDay(selectedDateRange.from),
        window: 'custom'
      })
    }
    setSelectedDateRange(null)
    close()
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                    <CalendarDaysIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Datumbereik selecteren
                    </Dialog.Title>
                    <div className="mt-2">
                      <DayPicker mode="range" selected={selectedDateRange} onSelect={setSelectedDateRange} />
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ring-gray-300  ring-1 ring-inset sm:ml-3 sm:w-auto"
                    onClick={onSave}>
                    Selecteren
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:mt-0 sm:w-auto"
                    onClick={onCancel}>
                    Annuleren
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
