import Container from '../../base/Container'
import { skipToken, useSuspenseQuery } from '@apollo/client'
import { GetDeviceCategories } from '../../../devices/queries/categories'
import { useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import MenuItem from '../MenuItem'
import MenuTitleItem from '../MenuTitleItem'
import { useRecoilValue } from 'recoil'
import { building } from '../../../store'
import { Link, useLocation } from 'react-router-dom'

const Categories = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  //padding: 16px;
`

const CategoriesSideMenu = () => {
  const location = useLocation()
  const { activeBuildingId } = useRecoilValue(building)
  const { data, refetch } = useSuspenseQuery(
    GetDeviceCategories,
    Boolean(activeBuildingId)
      ? {
          variables: {
            buildingId: activeBuildingId!
          }
        }
      : skipToken
  )

  useEffect(() => {
    void refetch({
      buildingId: activeBuildingId
    })
  }, [activeBuildingId])

  const categories: string[] = useMemo(() => {
    return data?.getAvailableCategories ?? []
  }, [data?.getAvailableCategories])

  return (
    <Container>
      <Link to={'/categories'}>
        <MenuTitleItem active={location.pathname === '/categories'} title={'Categoriën'} />
      </Link>
      <Categories>
        {categories.map(category => (
          <MenuItem to={`/categories/${category}`} key={category} title={category} active={location.pathname === `/categories/${category}`} />
        ))}
      </Categories>
    </Container>
  )
}

export default CategoriesSideMenu
