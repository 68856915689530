import React, { useEffect, useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { CircleLoader } from 'react-spinners'
import PowerDonutChart from '../charts/PowerDonutChart'

interface Props {
  deviceId: string
  maxPower: number
  updateTotalPower: (deviceId: string, power: number) => void
}

const GET_DEVICE_QUERY = gql`
  query GetDevice($input: GetDeviceInput) {
    getDevice(input: $input) {
      name
      data {
        key
        value
      }
    }
  }
`
export default ({ deviceId, maxPower, updateTotalPower }: Props) => {
  const [activePower, setActivePower] = useState(0)
  const [fetchDevice, { data, loading }] = useLazyQuery(GET_DEVICE_QUERY, {})

  if (!deviceId || !maxPower) return null
  useEffect(() => {
    fetchDevice({
      variables: {
        input: {
          deviceId
        }
      }
    })
  }, [deviceId])

  useEffect(() => {
    //@ts-ignore
    const power = data?.getDevice?.data?.find(d => d.key === 'activePower')?.value || 0
    setActivePower(power)
    updateTotalPower(deviceId, power)
  }, [data?.getDevice])
  return (
    <>
      {(loading || !data) && <CircleLoader />}
      {!loading && data?.getDevice && <PowerDonutChart power={activePower} name={data.getDevice.name} capacity={maxPower} />}
    </>
  )
}
