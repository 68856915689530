import { useEffect } from 'react'
import { useRecoilValue, useResetRecoilState } from 'recoil'
import { authClient, user } from '../../store'
import { LoadingScreen } from '../../screens/LoadingScreen'

const LogoutScreen = () => {
  const resetUser = useResetRecoilState(user)
  const auth = useRecoilValue(authClient)
  const resetAuthClient = useResetRecoilState(authClient)

  useEffect(() => {
    resetUser()
    void auth.signout()
    resetAuthClient()
  }, [])

  return <LoadingScreen />
}

export default LogoutScreen
