import { Fragment, useMemo } from 'react'
import styled from '@emotion/styled'
import { Popover, Transition } from '@headlessui/react'
import UserIcon from '../../icons/UserIcon'
import CategoriesIcon from '../../icons/CategoriesIcon'
import GridIcon from '../../icons/GridIcon'
import DashboardIcon from '../../icons/DashboardIcon'
import { NavLink } from 'react-router-dom'
import SectionsIcon from '../../icons/SectionsIcon'
import { useSuspenseQuery, skipToken } from '@apollo/client'
import { GET_BUILDING_DETAILS } from '../../queries/building'
import { useRecoilValue } from 'recoil'
import { building } from '../../store'
import { useRouteToActiveMenu } from '../../hooks/useRouteToActiveMenu'
import { SideMenus } from './SideMenu'

const IconBar = styled('div')`
  background-color: ${({ theme }) => theme.colors.menuBackground};
`

const Icons = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`

const IconBarButton = styled('button')`
  display: block;
  height: 64px;
  width: 64px;
`

const UserIconBtn = styled(Popover.Button)`
  width: 32px;
  height: 32px;
  padding: 5px;
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.warm};
  border: 2px solid ${({ theme }) => theme.colors.warm};
  border-radius: 50%;
  opacity: 0.5;
`

const SideIconBar = () => {
  const activeBuilding = useRecoilValue(building)
  const activeMenu = useRouteToActiveMenu()
  const { data } = useSuspenseQuery(
    GET_BUILDING_DETAILS,
    activeBuilding?.activeBuildingId
      ? {
          variables: {
            buildingId: activeBuilding.activeBuildingId
          }
        }
      : skipToken
  )

  //@todo: rename preferences to subscriptionSettings

  const preferences = useMemo(() => {
    return data?.getBuilding?.preferences ?? {}
  }, [data])

  return (
    <IconBar className="flex flex-col justify-start items-center w-16 max-w-16 py-7 h-full">
      <Icons>
        <NavLink to={'/'} key={'dashboard-icon-btn'}>
          <IconBarButton>
            <DashboardIcon active={activeMenu === SideMenus.DASHBOARDS} />
          </IconBarButton>
        </NavLink>

        {preferences.hasLayout && (
          <NavLink to={'/building'} key="building-icon-btn">
            <IconBarButton>
              <SectionsIcon active={activeMenu === SideMenus.BUILDING} />
            </IconBarButton>
          </NavLink>
        )}

        {/*<Link to={'/ev-charging'} onClick={() => changeMenu('ev-charging')} key='ev-charging-icon-btn'>*/}
        {/*    <ChargingIcon fill={activeMenu === 'ev-charging' ? activeFill : inactiveFill }  />*/}
        {/*</Link>*/}

        {preferences.hasCategories && (
          <NavLink to={'/categories'} key="categories-icon-btn">
            <IconBarButton>
              <CategoriesIcon active={activeMenu === SideMenus.CATEGORIES} />
            </IconBarButton>
          </NavLink>
        )}

        {preferences.hasGrid && (
          <NavLink to={'/grid'} key="grid-icon-btn">
            <IconBarButton>
              <GridIcon active={activeMenu === SideMenus.GRID} />
            </IconBarButton>
          </NavLink>
        )}
      </Icons>
      <div className={'flex flex-col justify-end items-center'}>
        <Popover className="relative">
          {({ open }) => (
            <>
              <UserIconBtn>
                <UserIcon />
              </UserIconBtn>

              {/*// @ts-ignore*/}
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 -translate-y-14"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1">
                <Popover.Panel className="absolute z-10 translate-x-8 -translate-y-14">
                  <div className="overflow-hidden mx-2 ">
                    <div className="bg-white p-4">
                      <ul className="list-none">
                        {/*<li><a href='/account'>Account</a></li>*/}
                        {/*<li>Profiel</li>*/}
                        <li>
                          <a href="/logout">Afmelden</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    </IconBar>
  )
}

export default SideIconBar
