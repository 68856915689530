import React from 'react'
import SinglePhaseElectricityMeterCard from './SinglePhaseElectricityMeter/SinglePhaseElectricityMeterCard'
import ThreePhaseElectricityMeter from './ThreePhaseElectricityMeter/ThreePhaseElectricityMeter'
import DSMRCard from './DSMR/DSMRCard'
import Co2SensorCard from './Co2Sensor/Co2SensorCard'

//@todo: type device
export type Device = {
  [key: string]: any
}
interface Props {
  device: Device
}

export default ({ device }: Props) => {
  switch (device.type) {
    case 'DutchSmartMeterModel':
    case 'ThreePhaseDutchSmartMeterModel':
      return <></>
    // <DSMRCard />
    case 'EastronSdm630MctMlSinglePhaseModel':
    case 'EastronSdm120Model':
      return <SinglePhaseElectricityMeterCard device={device} />
    case 'EastronSdm630MctMlModel':
    case 'AlfenEveMeterModel':
      return <ThreePhaseElectricityMeter device={device} />
    case 'ElsysERSCO2':
      return <Co2SensorCard device={device} />
    default:
      return null
  }
}
