import React, { useEffect } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { CircleLoader } from 'react-spinners'
import PowerDonutChart from '../charts/PowerDonutChart'
import styled from '@emotion/styled'

interface Props {
  deviceId: string
  maxPower: number
  name: string
  onClick?: () => void
}

const GET_DEVICE_QUERY = gql`
  query GetDevice($input: GetDeviceInput) {
    getDevice(input: $input) {
      name
      data {
        key
        value
      }
    }
  }
`

const HklPowerDonutChart = styled(PowerDonutChart)`
  width: 160px;
  height: 160px;
  cursor: pointer;
`

export default ({ deviceId, maxPower, name, onClick }: Props) => {
  const [fetchDevice, { data, loading }] = useLazyQuery(GET_DEVICE_QUERY, {
    pollInterval: 5000
  })

  if (!deviceId || !maxPower) return null
  useEffect(() => {
    fetchDevice({
      variables: {
        input: {
          deviceId
        }
      }
    })
  }, [deviceId])

  //@ts-ignore
  const activePower = data?.getDevice?.data?.find(d => d.key === 'activePower')?.value || 0
  return (
    <>
      {(loading || !data) && <CircleLoader />}
      {!loading && data?.getDevice && <HklPowerDonutChart power={activePower} name={name} capacity={maxPower} onClick={onClick} />}
    </>
  )
}
