import React, { useEffect, useState } from 'react'
import { gql, useLazyQuery, useReactiveVar } from '@apollo/client'
import { CircleLoader } from 'react-spinners'
import PowerDonutChart from '../charts/PowerDonutChart'
import { getTimeframe } from '../../utils/getTimeframe'
import { GET_WINDOWED_MEASUREMENTS_QUERY } from '../../energy/queries/metrics'
import { useRecoilValue } from 'recoil'
import { timeframe } from '../../store'

interface Props {
  deviceId: string
}

export default ({ deviceId }: Props) => {
  const selectedTimeframe = useRecoilValue(timeframe)
  const [fetchTotalImported, { data: importMeasurementData, loading, error }] = useLazyQuery(GET_WINDOWED_MEASUREMENTS_QUERY, {
    fetchPolicy: 'no-cache'
  })

  const maxPower = 1

  if (!deviceId || !maxPower) return null

  useEffect(() => {
    if (deviceId) {
      const { start, end, window } = getTimeframe(selectedTimeframe)
      fetchTotalImported({
        variables: {
          input: {
            deviceId: [deviceId],
            start,
            end,
            measurement: 'energyImported',
            window
          }
        }
      })
    }
  }, [selectedTimeframe, deviceId])

  //@ts-ignore
  const totalImported = Math.floor(importMeasurementData?.getWindowedMeasurements?.reduce((acc, cv) => (acc += cv._value), 0) || 0)

  return (
    <>
      {(loading || !importMeasurementData) && <CircleLoader />}
      {!loading && <PowerDonutChart power={totalImported} name={'Totaal'} capacity={0} divideKW={false} unit={'kWh'} />}
    </>
  )
}
