import React, { ReactElement, useMemo } from 'react'
import EnergyBarChart from '../../components/Energy/EnergyBarChart'
import EnergyContainer from '../containers/EnergyContainer'
import EnergyMeasurementTotalCard from '../components/EnergyMeasurementTotalCard'
import EnergyMeasurementDeltaCard from '../components/EnergyMeasurementDeltaCard'
import EnergyMeasurementSumCard from '../components/EnergyMeasurementSumCard'
import EnergyUsageDeviceCard from '../../components/Energy/EnergyUsageDeviceCard'
import { useNavigate, useParams } from 'react-router-dom'

interface Props {
  distributionBox?: any
}

export default ({ distributionBox }: Props) => {
  const params = useParams()
  const navigate = useNavigate()
  const mainMeterId = distributionBox?.device?.deviceId

  //@ts-ignore
  const circuits = useMemo(() => {
    //@ts-ignore
    return (
      distributionBox.circuits?.map(circuit => ({
        deviceId: circuit?.device?.deviceId,
        type: circuit?.device?.type,
        name: circuit?.name
      })) || []
    )
  }, [distributionBox?.circuits])

  const totals = useMemo(() => {
    const totals: ReactElement[] = []

    // Verbruik via delta op alle devices
    if (mainMeterId) {
      totals.push(
        <EnergyMeasurementSumCard
          measurement={'energyImported'}
          title={'Verbruik'}
          unit={'kWh'}
          //@ts-ignore
          devices={circuits.map(circuit => circuit.deviceId)}
        />
      )
      totals.push(<EnergyMeasurementTotalCard measurement={'energyImported'} title={'Import'} unit={'kWh'} deviceId={mainMeterId} />)
      totals.push(<EnergyMeasurementTotalCard measurement={'energyExported'} title={'Export'} unit={'kWh'} deviceId={mainMeterId} />)
      totals.push(<EnergyMeasurementDeltaCard measurements={['energyImported', 'energyExported']} title={'Netto'} unit={'kWh'} deviceId={mainMeterId} />)
    }
    return totals
  }, [mainMeterId])

  const charts = useMemo(() => {
    return [
      <EnergyBarChart title="Actieve energie - import" devices={circuits} measurement="energyImported" />,
      <EnergyBarChart title="Actieve energie - export" devices={circuits} measurement="energyExported" isExport={true} />
    ]
  }, [circuits])

  const onClickDevice = (id: string) => {
    navigate(`/grid/${params.boxId}/${id}`)
  }

  const deviceCards = useMemo(
    () =>
      //@ts-ignore
      circuits?.map(circuit => <EnergyUsageDeviceCard device={circuit} onClick={() => onClickDevice(circuit.deviceId)} />),
    [circuits]
  )

  return <EnergyContainer circuitsTitle={'Groepen'} charts={charts} deviceCards={deviceCards} totals={totals} />
}
