//@ts-nocheck
import React from 'react'
import DeviceCard from '../../base/DeviceCard'
import { MeasurementFraction, MeasurementIntegral, MeasurementTitle, MeasurementUnit } from '../../base/Card'
import styled from '@emotion/styled'
import { Device } from '../Device'
import Big from 'big.js'

const CO2_ALERT_LEVELS = {
  warning: 800,
  danger: 1200
}

interface Props {
  device: Device
}

const StyledCo2Value = styled(MeasurementIntegral)<{ value: number }>`
  color: ${({ value, theme }) => (value >= CO2_ALERT_LEVELS.danger ? theme.colors.danger : value >= CO2_ALERT_LEVELS.warning ? theme.colors.alert : theme.colors.measurement)};
`

const StledCo2Unit = styled(MeasurementUnit)<{ value: number }>`
  color: ${({ value, theme }) => (value >= CO2_ALERT_LEVELS.danger ? theme.colors.danger : value >= CO2_ALERT_LEVELS.warning ? theme.colors.alert : theme.colors.measurement)};
`

export default ({ device }: Props) => {
  //@todo: icon bij zoeken
  if (!device) return null

  const temperature = new Big(device.data?.find(d => d.key === 'temperature')?.value || 0).round(1)
  const co2 = new Big(device.data?.find(d => d.key === 'co2')?.value || 0).round(1)
  const humidity = new Big(device.data?.find(d => d.key === 'humidity')?.value || 0).round(1)

  return (
    <DeviceCard title={device.name}>
      <div className={'flex flex-row mt-2 mb-6 justify-between space-x-12 w-fit'}>
        <div className={'flex flex-col w-1/3'}>
          <MeasurementTitle>Temperatuur</MeasurementTitle>
          <div className="mt-2">
            <MeasurementIntegral>{Math.floor(temperature.toNumber())}</MeasurementIntegral>
            <MeasurementFraction>,{temperature.toString().split('.')[1] || 0}</MeasurementFraction>
            <MeasurementUnit>&deg;C</MeasurementUnit>
          </div>
        </div>

        <div className={'flex flex-col w-1/3'}>
          <MeasurementTitle>CO2</MeasurementTitle>
          <div className="mt-2">
            <StyledCo2Value value={co2.toNumber()}>{co2.toNumber()}</StyledCo2Value>
            <StledCo2Unit value={co2.toNumber()}>ppm</StledCo2Unit>
          </div>
        </div>

        <div className={'flex flex-col w-1/3'}>
          <MeasurementTitle>Vochtigheid</MeasurementTitle>
          <div className="mt-2">
            <MeasurementIntegral>{Math.floor(humidity.toNumber())}</MeasurementIntegral>
            <MeasurementFraction>,{humidity.toString().split('.')[1] || 0}</MeasurementFraction>
            <MeasurementUnit>%</MeasurementUnit>
          </div>
        </div>
      </div>
    </DeviceCard>
  )
}
