import React, { ReactNode } from 'react'
import Card from './Card'
import styled from '@emotion/styled'

interface DeviceCardProps {
  children?: ReactNode
  icon?: ReactNode
  title: string
}

const Header = styled.div`
  border-bottom: 1px solid rgba(150, 155, 190, 0.2);
  padding-bottom: 16px;
`

const CardTitle = styled.div`
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  letter-spacing: -0.02em;
  color: #000000;
  margin-left: 8px;
  width: fit-content;
`

const DeviceCard = ({ children, icon, title }: DeviceCardProps) => {
  return (
    <Card>
      <Header className={'flex flex-row justify-start items-center'}>
        {icon && icon}
        <CardTitle>{title}</CardTitle>
      </Header>
      {children}
    </Card>
  )
}

export default DeviceCard
