import React, { useEffect } from 'react'
import GridCircuitCard from './GridCircuitCard'
import { useLazyQuery } from '@apollo/client'
import { GET_WINDOWED_MEASUREMENTS_QUERY } from '../../energy/queries/metrics'
import { getTimeframe } from '../../utils/getTimeframe'
import { useRecoilValue } from 'recoil'
import { timeframe } from '../../store'

//@ts-ignore
export default ({ circuit, measurement }) => {
  const selectedTimeframe = useRecoilValue(timeframe)
  const [fetchMeasurements, { data }] = useLazyQuery(GET_WINDOWED_MEASUREMENTS_QUERY, {
    fetchPolicy: 'no-cache'
  })

  const { circuitId, device, name, label } = circuit

  useEffect(() => {
    if (device?.deviceId) {
      const { start, end, window } = getTimeframe(selectedTimeframe)
      void fetchMeasurements({
        variables: {
          input: {
            deviceId: [device.deviceId],
            start,
            end,
            measurement,
            window
          }
        }
      })
    }
  }, [device?.deviceId, selectedTimeframe])

  if (!circuit) return null

  //@ts-ignore
  const totalImported = data?.getWindowedMeasurements?.reduce((acc, cv) => (acc += cv._value), 0) || 0

  return <GridCircuitCard circuitName={name} value={totalImported} unit={'kWh'} showBar={false} circuitLabel={label} circuitId={circuitId} />
}
