import { useGetBuildingEnergyConsumptionData } from '../../../components/Energy/EnergyTable/useGetBuildingEnergyConsumptionData'
import styled from '@emotion/styled'
import { EnergyTableTimestamp } from '../../../components/Energy/EnergyTable/EnergyTableTimestamp'
import { PowerOverviewConsumptionColumn } from './columns/PowerOverviewConsumptionColumn'
import { PowerOverviewImportColumn } from './columns/PowerOverviewImportColumn'
import { PowerOverviewExportColumn } from './columns/PowerOverviewExportColumn'
import { PowerOverviewSolarColumn } from './columns/PowerOverviewSolarColumn'
import { EnergyColumnOverviewContainer } from '../../../components/layout/EnergyColumnOverviewContainer'

const TimestampContainer = styled('div')`
  background-color: rgba(223, 225, 235, 1);
  min-width: 150px;
  max-width: 150px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 126px 27px 33px;
`

const Wrapper = styled('div')`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  box-sizing: content-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
`

const ResultsContainer = styled('div')`
  width: 100%;
  height: 100%;
  overflow-x: auto;
  display: grid;
  grid-auto-flow: column;
  align-self: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 0 16px 0 8px;
`

export const PowerOverviewEnergyDetails = () => {
  const { consumption, timeStamps } = useGetBuildingEnergyConsumptionData()

  return (
    <EnergyColumnOverviewContainer>
      <TimestampContainer>{timeStamps && timeStamps.map(timestamp => <EnergyTableTimestamp timestamp={timestamp} key={`time-${timestamp}`} />)}</TimestampContainer>
      <Wrapper>
        <ResultsContainer>
          <PowerOverviewImportColumn maxForBarChart={consumption?.max ?? 0} />
          <PowerOverviewExportColumn maxForBarChart={consumption?.max ?? 0} />
          <PowerOverviewConsumptionColumn max={consumption?.max ?? 0} values={consumption.values} total={consumption.total} />
          <PowerOverviewSolarColumn maxForBarChart={consumption?.max ?? 0} />
        </ResultsContainer>
      </Wrapper>
    </EnergyColumnOverviewContainer>
  )
}
