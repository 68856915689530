//@ts-nocheck
//@todo: generate types from queries
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import AppLayout from '../../layouts/AppLayout'
import { gql, useLazyQuery } from '@apollo/client'
import { CircleLoader } from 'react-spinners'
import Device from '../../components/devices/Device'

const GET_SECTION_QUERY = gql`
  query GetSection($sectionId: ID!) {
    getSection(sectionId: $sectionId) {
      name
      order
      parentId
      sectionId
      type
      devices {
        deviceId
        name
        type
        data {
          key
          value
        }
        status
        updated
      }
    }
  }
`
export default () => {
  const { sectionId } = useParams()
  const [fetchSection, { data, loading }] = useLazyQuery(GET_SECTION_QUERY, {
    pollInterval: 5000
  })
  const [section, setSection] = useState(null)

  useEffect(() => {
    if (sectionId)
      fetchSection({
        variables: {
          sectionId
        }
      })
  }, [sectionId])

  useEffect(() => {
    if (data?.getSection) setSection(data.getSection)
  }, [data])

  return (
    <AppLayout title={section?.name || ''}>
      {loading && (
        <div className="w-full h-full flex justify-center items-center">
          <CircleLoader color="#0D70EE" loading={loading} />
        </div>
      )}
      {!loading && section && section.devices?.map(device => <Device device={device} key={device?.deviceId} />)}
    </AppLayout>
  )
}
