import styled from '@emotion/styled'

export const StatsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 4px;
`

export const Stat = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  gap: 8px;
  color: #9196bb;
  font-family: 'Red Hat Display', sans-serif;
  font-style: normal;
  line-height: 110%;
`

export const StatMetric = styled('div')``

export const StatIcon = styled('div')``
