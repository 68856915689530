import { CriticalErrorScreen } from './CriticalErrorScreen'

export const UnauthorizedErrorScreen = () => {
  return (
    <CriticalErrorScreen
      message={`
        U heeft geen toegang tot deze pagina.
      `}
    />
  )
}
