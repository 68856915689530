import React from 'react'

// @ts-ignore
export default props => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M23.7245 3.06937H21.8499V1.69752C21.8499 0.761507 21.0884 0 20.1524 0H11.8472C10.9112 0 10.1497 0.761507 10.1497 1.69752V3.06937H8.27516C6.87114 3.06937 5.72888 4.21163 5.72888 5.61564V29.4537C5.72888 30.8577 6.87114 32 8.27516 32H23.7245C25.1286 32 26.2708 30.8577 26.2708 29.4537V5.61564C26.2708 4.21163 25.1286 3.06937 23.7245 3.06937ZM8.27516 29.4537V5.61564H23.7245L23.7248 29.4537H8.27516Z" />
      <path d="M21.5953 22.6191H10.4044V27.6831H21.5953V22.6191Z" />
      <path d="M21.5953 14.9912H10.4044V20.0551H21.5953V14.9912Z" />
      <path d="M21.5953 7.36426H10.4044V12.4282H21.5953V7.36426Z" />
    </svg>
  )
}
