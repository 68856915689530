//@ts-nocheck
import React, { SyntheticEvent, useEffect, useState } from 'react'
import Card from '../base/Card'
import styled from '@emotion/styled'
import { ResponsiveLine } from '@nivo/line'
import theme from '../../theme'
import ChevronDownIcon from '../../icons/ChevronDownIcon'
import ChevronUpIcon from '../../icons/ChevronUpIcon'
import ChargingStationIndicatorIcon from '../../icons/ChargingStationIndicatorIcon'
import PowerDonutChart from '../charts/PowerDonutChart'
import { gql, useMutation } from '@apollo/client'
import { format, formatISO, parseISO } from 'date-fns'
import { SET_CHARGE_STRATEGY_MUTATION, SET_CHARGE_TARGET } from './query'

const StyledCard = styled(Card)``

const ConnectorDetailsHeading = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const InfoContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.coldGrey};
  margin-left: 16px;
  padding: 8px 12px;
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 110%;
  text-transform: uppercase;
  color: #ffffff;
  :first-child {
    margin: 0;
  }
`

const ChartContainer = styled.div`
  margin-top: 32px;
`

const StrategySelectionContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const StrategySelectionButton = styled.button<{ active: boolean }>`
  background-color: ${({ active, theme }) => (active ? theme.colors.blueEnergetic : theme.colors.warm)};
  color: ${({ active }) => (active ? '#FFFFFF' : '#000000')};
  font-size: 1.1rem;
  width: 100%;
  padding: 8px 0;
  margin-left: 2px;
  :first-child {
    margin: 0;
  }
`

const StrategyDescriptionText = styled.p`
  margin: 8px 0 16px;
`

const EnergySelectContainer = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const EnergySetPoint = styled.div`
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  padding: 10px 0px;
`

const SelectionButton = styled.button`
  background-color: ${({ theme }) => theme.colors.blueEnergetic};
  color: #ffffff;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  vertical-align: middle;

  :focus {
    background-color: ${({ theme }) => theme.colors.blueEnergetic};
  }
`

const DepartureContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
`

const DepartureTimeLabel = styled.div`
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
`

const DepartureTimeInput = styled.input`
  border: 0;
`

export default ({ station, socket, chargingClient }) => {
  const [energySetpoint, setEnergySetpoint] = useState(socket.energyChargeTarget)
  const [departureTime, setDepartureTime] = useState<null | string>(null)

  const [setChargeStrategy] = useMutation(SET_CHARGE_STRATEGY_MUTATION)
  const [setChargeTarget] = useMutation(SET_CHARGE_TARGET)

  useEffect(() => {
    if (socket.departureTime) {
      const time = format(parseISO(socket.departureTime), 'HH:mm')
      setDepartureTime(time)
    }
  }, [])

  const updateEnergySetpoint = (diff: number) => {
    if (energySetpoint != 0 || diff > 0) {
      const newSetpoint = energySetpoint + diff
      setEnergySetpoint(newSetpoint)
    }
  }

  const updateDepartureTime = (time: SyntheticEvent) => {
    if (time?.currentTarget?.value && typeof time.currentTarget.value === 'string') {
      setDepartureTime(time.currentTarget.value)
    }
  }

  useEffect(() => {
    if (energySetpoint !== null) {
      const time = departureTime?.split(':')
      if (!Array.isArray(time) || time.length !== 2) return

      const newDepartureTime = formatISO(new Date().setHours(time[0], time[1], 0))

      setChargeTarget({
        client: chargingClient,
        variables: {
          chargeStationId: station.id,
          socketId: socket.id,
          energyTarget: energySetpoint,
          departureTime: newDepartureTime
        },
        refetchQueries: ['GetChargingStationsForCustomer']
      })
    }
  }, [departureTime, energySetpoint])

  const updateChargeStrategy = (strategy: string) => {
    if (strategy) {
      setChargeStrategy({
        client: chargingClient,
        variables: {
          chargeStationId: station.id,
          socketId: socket.id,
          strategy
        },
        refetchQueries: ['GetChargingStationsForCustomer']
      })
    }
  }

  return (
    <StyledCard>
      <ConnectorDetailsHeading>
        <InfoContainer>{station.label}</InfoContainer>
        {socket.label && <InfoContainer>{socket.label}</InfoContainer>}
      </ConnectorDetailsHeading>

      <ChartContainer>
        <PowerDonutChart capacity={socket.capacity} power={socket.activePower} divideKW={true} name={socketStatusToText(socket.sessionStatus)} />
      </ChartContainer>

      <StrategySelectionContainer>
        <StrategySelectionButton active={socket.activeStrategy === 'MAX_POWER'} onClick={() => updateChargeStrategy('MAX_POWER')}>
          Direct
        </StrategySelectionButton>
        <StrategySelectionButton active={socket.activeStrategy === 'CHARGE_TARGET'} onClick={() => updateChargeStrategy('CHARGE_TARGET')}>
          Slim
        </StrategySelectionButton>
        <StrategySelectionButton active={socket.activeStrategy === 'AVAILABLE_POWER'} onClick={() => updateChargeStrategy('AVAILABLE_POWER')}>
          Zon
        </StrategySelectionButton>
      </StrategySelectionContainer>

      {socket.activeStrategy === 'CHARGE_TARGET' && (
        <>
          <EnergySelectContainer>
            <SelectionButton onClick={() => updateEnergySetpoint(-1)}>-</SelectionButton>
            <EnergySetPoint>{energySetpoint} kWh</EnergySetPoint>
            <SelectionButton onClick={() => updateEnergySetpoint(1)}>+</SelectionButton>
          </EnergySelectContainer>

          <DepartureContainer>
            <DepartureTimeLabel>Vertrektijd</DepartureTimeLabel>
            <DepartureTimeInput type="time" defaultValue={departureTime} onChange={value => updateDepartureTime(value)} />
          </DepartureContainer>
        </>
      )}
    </StyledCard>
  )
}

export const socketStatusToText = (status: string) => {
  switch (status) {
    case 'Available':
      return 'Beschikbaar'
    case 'Pending':
      return 'Wachten'
    case 'Charging':
      return 'Laden'
    case 'ThrottledEV':
      return 'Beperkt'
    case 'SuspendedEV':
    case 'SuspendedEVSE':
      return 'Gepauzeerd'
    default:
      return 'Laden'
  }
}

//const testData = [
//         {
//             id: "Links",
//             color: theme.colors.blueEnergetic,
//             data: [
//                 {
//                     x: '08:00',
//                     y: 3.6
//                 },
//                 {
//                     x: '09:00',
//                     y: 0
//                 },
//                 {
//                     x: '10:00',
//                     y: 11
//                 },
//                 {
//                     x: '12:00',
//                     y: 7.2
//                 },
//                 {
//                     x: '13:00',
//                     y: 5.3
//                 },
//                 {
//                     x: '14:00',
//                     y: 4.2
//                 },
//                 {
//                     x: '15:00',
//                     y: 8.0
//                 },
//                 {
//                     x: '16:00',
//                     y: 11
//                 },
//                 {
//                     x: '17:00',
//                     y: 3.0
//                 }
//             ]
//         }
//     ]
