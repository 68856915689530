import styled from '@emotion/styled'
import SideMenuBuildingSelector from './SideMenuBuildingSelector'
import SideIconBar from './SideIconBar'
import SideSubMenu, { SubMenuItem } from './SideSubMenu'
import { Suspense } from 'react'

const Sidebar = styled.div`
  background-color: ${({ theme }) => theme.colors.subMenuBackground};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 320px;
  height: 100%;
  overflow: hidden;
`

export enum SideMenus {
  DASHBOARDS = 'Dashboards',
  BUILDING = 'Building',
  CHARGING = 'Charging',
  CATEGORIES = 'Categories',
  GRID = 'Grid'
}

export type SideMenu = {
  key: string
  title: string
  menuItems: SubMenuItem[]
  icon: React.ReactNode
  baseUrl?: string
}

const SideMenu = () => {
  return (
    <Sidebar>
      <SideMenuBuildingSelector />
      <div className="flex flex-row h-full">
        <Suspense>
          <SideIconBar />
        </Suspense>
        <Suspense>
          <SideSubMenu />
        </Suspense>
      </div>
    </Sidebar>
  )
}

export default SideMenu
