import styled from '@emotion/styled'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 24px;
`

const StyledExclamationTriangleIcon = styled(ExclamationTriangleIcon)`
  width: 75px;
  height: 75px;
  color: ${({ theme }) => theme.colors.alert};
`

const ErrorMessage = styled('p')`
  text-align: center;
`

interface CriticalErrorScreenProps {
  message: string
}

export const CriticalErrorScreen = ({ message }: CriticalErrorScreenProps) => {
  return (
    <Wrapper>
      <StyledExclamationTriangleIcon />
      <ErrorMessage>{message}</ErrorMessage>
    </Wrapper>
  )
}
