import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import TimeframeSelectButton from './TimeframeSelectButton'
import { addDays, addMonths, addWeeks, addYears, endOfDay, isSameDay, subDays, subMonths, subWeeks, subYears } from 'date-fns'
import ActiveDate from '../ActiveDate'
import { useRecoilState } from 'recoil'
import { timeframe, TimeframeWindow } from '../../store'

const Container = styled.div`
  height: 32px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.timeFrameSelectionMenuBg};
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: fit-content;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledChevronLeftIcon = styled(ChevronLeftIcon)`
  width: 40px;
  cursor: pointer;
`

const StyledChevronRightIcon = styled(ChevronRightIcon)`
  width: 40px;
  cursor: pointer;
`

export default () => {
  const [activeFrame, setActiveFrame] = useRecoilState(timeframe)

  const isLastDay = useMemo(() => {
    return isSameDay(activeFrame.start, new Date())
  }, [activeFrame.start])

  const updateStartDate = (direction: 'prev' | 'next') => {
    if (direction === 'next' && isLastDay) return

    let newStart: Date
    let newEnd: Date
    switch (activeFrame.window) {
      case TimeframeWindow.H24:
        newStart = direction === 'prev' ? subDays(activeFrame.start, 1) : addDays(activeFrame.start, 1)
        newEnd = direction === 'prev' ? subDays(activeFrame.end, 1) : addDays(activeFrame.end, 1)
        break
      case TimeframeWindow.WEEK:
        newStart = direction === 'prev' ? subWeeks(activeFrame.start, 1) : addWeeks(activeFrame.start, 1)
        newEnd = direction === 'prev' ? subWeeks(activeFrame.end, 1) : addWeeks(activeFrame.end, 1)
        break
      case TimeframeWindow.MONTH:
        newStart = direction === 'prev' ? subMonths(activeFrame.start, 1) : addMonths(activeFrame.start, 1)
        newEnd = direction === 'prev' ? subMonths(activeFrame.end, 1) : addMonths(activeFrame.end, 1)
        break
      case TimeframeWindow.YEAR:
        newStart = direction === 'prev' ? subYears(activeFrame.start, 1) : addYears(activeFrame.start, 1)
        newEnd = direction === 'prev' ? subYears(activeFrame.end, 1) : addYears(activeFrame.end, 1)
        break
      default:
        newStart = new Date()
        newEnd = endOfDay(newStart)
        break
    }

    setActiveFrame({
      window: activeFrame.window,
      start: newStart,
      end: newEnd
    })
  }

  const isCustomTimeframe = activeFrame.window === TimeframeWindow.CUSTOM

  return (
    <Wrapper>
      <Container>
        {!isCustomTimeframe && <StyledChevronLeftIcon onClick={() => updateStartDate('prev')} />}
        <ActiveDate />
        <TimeframeSelectButton label={'dag'} window={TimeframeWindow.H24} />
        <TimeframeSelectButton label={'week'} window={TimeframeWindow.WEEK} />
        <TimeframeSelectButton label={'maand'} window={TimeframeWindow.MONTH} />
        <TimeframeSelectButton label={'jaar'} window={TimeframeWindow.YEAR} />
        {!isCustomTimeframe && <StyledChevronRightIcon onClick={() => updateStartDate('next')} />}
      </Container>
    </Wrapper>
  )
}
