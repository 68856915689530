export const threePhaseCurrentMeasurements = [
  {
    key: 'currentL1',
    label: 'L1'
  },
  {
    key: 'currentL2',
    label: 'L2'
  },
  {
    key: 'currentL3',
    label: 'L3'
  }
]

export const singlePhaseCurrentMeasurements = [
  {
    key: 'current',
    label: 'L'
  }
]
