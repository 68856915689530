import TimeframeSelectionMenu from '../../components/TimeframeSelectionMenu/TimeframeSelectionMenu'
import { Suspense, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useQuery, useSuspenseQuery } from '@apollo/client'
import { GetGridForPowerOverviewQuery } from '../queries/gridForPowerOverview'
import { PowerOverviewEnergyTable } from './PowerOverviewComponents/PowerOverviewEnergyTable'
import { GridSummaryGraphic } from '../../energy/components/GridSummaryGraphic'
import { useRecoilValue } from 'recoil'
import { building } from '../../store'
import Centered from '../../components/layout/Centered'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import HorizontalLoader from '../../components/base/HorizontalLoader'
import { PowerOverviewEnergyDetails } from './PowerOverviewComponents/PowerOverviewEnergyDetails'

const StyledExclamationTriangleIcon = styled(ExclamationTriangleIcon)`
  width: 75px;
  height: 75px;
  color: ${({ theme }) => theme.colors.alert};
`

const Container = styled('div')`
  width: 100%;
  height: fit-content;
`

const TimeFrameContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const DetailContainer = styled('div')`
  height: fit-content;
  margin: 50px auto 0;
  display: flex;
  align-items: center;
  width: fit-content;
  max-width: 75vw;
`

const PowerOverviewDashboard = () => {
  const { activeBuildingId } = useRecoilValue(building)

  const { data: gridLayoutData } = useSuspenseQuery(GetGridForPowerOverviewQuery, {
    variables: {
      buildingId: activeBuildingId
    },
    skip: !Boolean(activeBuildingId)
  })

  const hasGrid = Boolean(gridLayoutData?.getBuilding?.grid?.main && gridLayoutData?.getBuilding?.grid?.main.length > 0)

  return (
    <Container>
      {!hasGrid && (
        <Centered>
          <StyledExclamationTriangleIcon />
          De indeling van het elektriciteitsnetwerk is niet bekend.
        </Centered>
      )}

      {hasGrid && (
        <>
          <TimeFrameContainer>
            <TimeframeSelectionMenu />
          </TimeFrameContainer>

          <Suspense
            fallback={
              <Centered>
                <HorizontalLoader />
              </Centered>
            }>
            <GridSummaryGraphic />

            <DetailContainer>
              <PowerOverviewEnergyDetails />
            </DetailContainer>
          </Suspense>
        </>
      )}
    </Container>
  )
}

export default PowerOverviewDashboard
