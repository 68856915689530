import React, { useMemo } from 'react'
import EnergyContainer from '../containers/EnergyContainer'
import { EnergyDevice } from '../types/devices'
import EnergyDeviceSinglePhaseCurrentCard from '../../components/Energy/EnergyDeviceSinglePhaseCurrentCard'
import EnergyLineChart from '../../components/Energy/EnergyLineChart'
import { isThreePhaseDevice } from '../../utils/isThreePhaseDevice'
import EnergyDeviceThreePhaseCurrentCard from '../../components/Energy/EnergyDeviceThreePhaseCurrentCard'

interface Props {
  devices: EnergyDevice[]
}

export default ({ devices }: Props) => {
  const charts = useMemo(() => {
    return [
      <EnergyLineChart title={'Fase 1'} devices={devices} measurement={'currentL1'} unit={'A'} />,
      <EnergyLineChart title={'Fase 2'} devices={devices} measurement={'currentL2'} unit={'A'} />,
      <EnergyLineChart title={'Fase 3'} devices={devices} measurement={'currentL3'} unit={'A'} />
    ]
  }, [devices])

  const deviceCards = useMemo(
    () =>
      devices.map(device => {
        const isThreePhase = isThreePhaseDevice(device.type)
        if (isThreePhase) {
          return <EnergyDeviceThreePhaseCurrentCard key={`dv-a-${device.deviceId}`} device={device} />
        } else {
          return <EnergyDeviceSinglePhaseCurrentCard key={`dv-a-${device.deviceId}`} device={device} />
        }
      }),
    [devices]
  )

  return <EnergyContainer charts={charts} deviceCards={deviceCards} circuitsTitle={'Apparaten en groepen'} />
}
