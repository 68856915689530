import { StrictMode, Suspense } from 'react'
import './index.css'
import Router from './routes'
import { css, Global, ThemeProvider } from '@emotion/react'
import theme from './theme'
import Apollo from './Apollo'
import AppProvider from './utils/Init'
import { RecoilRoot } from 'recoil'
import { createRoot } from 'react-dom/client'
import { LoadingScreen } from './screens/LoadingScreen'

const root = createRoot(document.getElementById('root') as HTMLElement)

//@todo: Radix ui implementation

root.render(
  <StrictMode>
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <Global
          styles={css`
            .border-top {
              border-top: 1px solid ${theme.colors.border};
            }
          `}
        />
        <Apollo>
          <Suspense fallback={<LoadingScreen />}>
            <AppProvider>
              <Router />
            </AppProvider>
          </Suspense>
        </Apollo>
      </ThemeProvider>
    </RecoilRoot>
  </StrictMode>
)
