import React, { useMemo } from 'react'
import GridMaxDonutChart, { SIZE } from '../../components/grid/GridMaxDonutChart'
import GridCircuitMinMax from '../../components/grid/GridCircuitMinMax'
import MultiMeasurementsLineChart from '../../components/Energy/MultiMeasurementsLineChart'
import EnergyContainer from '../containers/EnergyContainer'
import { isThreePhaseDevice } from '../../utils/isThreePhaseDevice'
import { singlePhaseVoltageMeasurements, threePhaseVoltageMeasurements } from '../constants/voltageMeasurements'
import { singlePhaseHarmonicDistortionMeasurements, threePhaseHarmonicDistortionMeasurements } from '../constants/harmonicDistortionMeasurements'
import { singlePhasePowerFactorMeasurements, threePhasePowerFactorMeasurements } from '../constants/powerFactorMeasurements'

interface Props {
  distributionBox?: any
}

export default ({ distributionBox }: Props) => {
  const mainMeterId = distributionBox?.device?.deviceId
  const isThreePhase = isThreePhaseDevice(distributionBox?.device?.type)

  const voltageMeasurements = isThreePhase ? threePhaseVoltageMeasurements : singlePhaseVoltageMeasurements
  const harmonicDistortionMeasurements = isThreePhase ? threePhaseHarmonicDistortionMeasurements : singlePhaseHarmonicDistortionMeasurements
  const powerFactorMeasurements = isThreePhase ? threePhasePowerFactorMeasurements : singlePhasePowerFactorMeasurements

  const totals = useMemo(() => {
    if (mainMeterId) {
      return [
        <GridMaxDonutChart deviceId={mainMeterId} measurement={'powerFactor'} label="Power factor" unit=" " capacity={1} size={SIZE.small} />,
        <GridMaxDonutChart
          deviceId={mainMeterId}
          measurement={'totalHarmonicCurrentDistortion'}
          label={
            <>
              <tspan x="50%" dy="-1em">
                Harmonische
              </tspan>
              <tspan x="50%" dy="1.2em">
                vervorming
              </tspan>
            </>
          }
          unit=" "
          capacity={100}
          size={SIZE.small}
        />,
        <GridMaxDonutChart deviceId={mainMeterId} measurement={'voltageL1'} label="Spanning L1" unit="V" capacity={253} size={SIZE.small} />,
        <GridMaxDonutChart deviceId={mainMeterId} measurement={'voltageL2'} label="Spanning L2" unit="V" capacity={253} size={SIZE.small} />,
        <GridMaxDonutChart deviceId={mainMeterId} measurement={'voltageL3'} label="Spanning L3" unit="V" capacity={253} size={SIZE.small} />
      ]
    }
  }, [mainMeterId])

  const charts = useMemo(() => {
    if (mainMeterId) {
      return [
        <MultiMeasurementsLineChart title={'Power factor'} deviceId={mainMeterId} measurements={powerFactorMeasurements} unit={'PF'} />,
        <MultiMeasurementsLineChart title={'Harmonische vervorming'} deviceId={mainMeterId} measurements={harmonicDistortionMeasurements} unit={'THD'} />,
        <MultiMeasurementsLineChart title={'Spanning'} deviceId={mainMeterId} measurements={voltageMeasurements} unit={'V'} />
      ]
    }
  }, [mainMeterId])

  const deviceCards = useMemo(() => {
    //@ts-ignore
    return distributionBox?.circuits?.map(circuit => <GridCircuitMinMax circuit={circuit} measurement={'powerFactor'} unit={'pf'} />) ?? []
  }, [distributionBox?.circuits])

  return <EnergyContainer circuitsTitle={'Groepen'} charts={charts} totals={totals} deviceCards={deviceCards} />
}
