import React from 'react'
import Card, { MeasurementFraction, MeasurementIntegral, MeasurementUnit } from '../base/Card'
import styled from '@emotion/styled'
import Big from 'big.js'

const CardHeading = styled.div`
  font-family: 'Red Hat Display', serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const StyledMeasurementIntegral = styled(MeasurementIntegral)`
  font-size: 64px;
`

interface Props {
  title: string
  value: number
  unit: string
}
export default ({ title, value, unit }: Props) => {
  const integral = Big(value).round(0, Big.roundDown)
  const fraction = Big(value).minus(integral).round(2)

  return (
    <Card>
      <CardHeading>{title}</CardHeading>
      <div className="mt-2">
        <StyledMeasurementIntegral>{integral.toString()}</StyledMeasurementIntegral>
        <MeasurementFraction>,{fraction.toString().split('.')[1] || 0}</MeasurementFraction>
        <MeasurementUnit>{unit}</MeasurementUnit>
      </div>
    </Card>
  )
}
