import { useQuery, useReactiveVar } from '@apollo/client'
import { GetMinMaxForPeriodQuery } from '../../energy/queries/metrics'
import { EnergyDevice } from '../../energy/types/devices'
import { CircleLoader } from 'react-spinners'
import EnergyDeviceCard, { Measurement, MeasurementContainer, MeasurementFraction, MeasurementLabel } from '../base/EnergyDeviceCard'
import { useMemo } from 'react'
import Big from 'big.js'
import { getTimeframe } from '../../utils/getTimeframe'
import { useRecoilValue } from 'recoil'
import { timeframe } from '../../store'

interface Props {
  device: EnergyDevice
}

const EnergyDeviceSinglePhaseCurrentCard = ({ device }: Props) => {
  const selectedTimeframe = useRecoilValue(timeframe)
  const { start, end, window } = getTimeframe(selectedTimeframe)
  const { data, loading } = useQuery(GetMinMaxForPeriodQuery, {
    variables: {
      input: {
        deviceId: device.deviceId,
        start,
        end,
        measurement: 'current'
      }
    },
    skip: !device?.deviceId
  })

  const { minIntegral, maxIntegral, minFraction, maxFraction } = useMemo(() => {
    const min = Big(data?.getMinMaxForPeriod?.min?.value ?? 0)
    const max = Big(data?.getMinMaxForPeriod?.max?.value ?? 0)

    const minIntegral = min.round(0, Big.roundDown)
    const minFraction = min.minus(minIntegral).round(2).toString().split('.')[1]

    const maxIntegral = max.round(0, Big.roundDown)
    const maxFraction = max.minus(maxIntegral).round(2).toString().split('.')[1]

    return {
      minIntegral: minIntegral.toString(),
      minFraction,
      maxIntegral: maxIntegral.toString(),
      maxFraction
    }
  }, [data?.getMinMaxForPeriod])

  if (!device?.deviceId) return null
  if (!data?.getMinMaxForPeriod && !loading) return null

  return (
    <EnergyDeviceCard name={device.name}>
      {loading && <CircleLoader color="#0D70EE" loading={loading} />}

      {!loading && (
        <>
          <MeasurementContainer>
            <MeasurementLabel>Min.</MeasurementLabel>
            <Measurement>
              {minIntegral}
              <MeasurementFraction> {minFraction && `,${minFraction}`} A</MeasurementFraction>
            </Measurement>
          </MeasurementContainer>

          <MeasurementContainer>
            <MeasurementLabel>Max.</MeasurementLabel>
            <Measurement>
              {maxIntegral}
              <MeasurementFraction> {maxFraction && `,${maxFraction}`} A</MeasurementFraction>
            </Measurement>
          </MeasurementContainer>
        </>
      )}
    </EnergyDeviceCard>
  )
}

export default EnergyDeviceSinglePhaseCurrentCard
