import { gql } from '../../__generated'

export const GridDistributionCircuit_Fragment = gql(/* GraphQL */ `
  fragment GridDistributionCircuitFragment on GridDistributionCircuit {
    circuitId
    name
    description
    label
    device {
      deviceId
      type
      data {
        key
        value
      }
      name
      status
      source
    }
  }
`)

export const GET_GRID_DISTRIBUTION_BOX_QUERY = gql(/* GraphQL */ `
  query GetGridDistributionBox($distributionBoxId: ID!) {
    getGridDistributionBox(distributionBoxId: $distributionBoxId) {
      name
      description
      distributionBoxId
      parent {
        name
      }
      building {
        name
      }
      device {
        deviceId
        type
      }
      maxPower
      maxCurrent
      maxPowerPerPhase
      maxCurrentPerPhase
      circuits {
        ...GridDistributionCircuitFragment
        parent {
          ...GridDistributionCircuitFragment
          parent {
            ...GridDistributionCircuitFragment
            parent {
              ...GridDistributionCircuitFragment
            }
          }
        }
      }
    }
  }
`)

/*
Uit GetGridDistributionBox:
mainCircuit {
          circuitId
          name
          device {
            deviceId
            name
            type
            status
            source
          }
        }
 */
