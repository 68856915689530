import React, { ReactNode } from 'react'
import styled from '@emotion/styled'

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

interface CenteredProps {
  children: ReactNode
}

const Centered = ({ children, ...props }: CenteredProps) => {
  return <Center {...props}>{children}</Center>
}

export default Centered
