export const threePhaseVoltageMeasurements = [
  {
    key: 'voltageL1',
    label: 'L1'
  },
  {
    key: 'voltageL2',
    label: 'L2'
  },
  {
    key: 'voltageL3',
    label: 'L3'
  }
]

export const singlePhaseVoltageMeasurements = [
  {
    key: 'voltage',
    label: 'L'
  }
]
