import { gql } from '../../__generated'

export const GET_BUILDINGS_FOR_USER = gql(/* GraphQL */ `
  query GetBuildingsForUser {
    getMyCustomerDetails {
      name
      customerId
      buildings {
        name
        buildingId
        preferences {
          hasGrid
          hasLayout
          hasGrid
          hasSmartCharging
        }
      }
    }
  }
`)
