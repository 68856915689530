import { EnergyTableColumnData } from '../../../../components/Energy/EnergyTable/EnergyTableColumn'
import { EnergyTableSingleCategoryColumn } from '../../../../components/Energy/EnergyTable/EnergyTableSingleCategoryColumn'
import { EnergyTypes } from '../../../../components/Energy/EnergyTable/EnergyTableResult'

interface PowerOverviewConsumptionColumnProps {
  total: number
  max: number
  values: EnergyTableColumnData[]
}

export const PowerOverviewConsumptionColumn = ({ max, total, values }: PowerOverviewConsumptionColumnProps) => {
  return <EnergyTableSingleCategoryColumn title={'Verbruik'} type={EnergyTypes.Usage} total={total} resultMax={max} values={values} />
}
